import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';
import Utilities from './Utilities';

const useStyles = makeStyles({
    textContainer: {
        paddingTop: 50,
        fontFamily: 'RedHatText',
        color: Colors.textColor,
        fontSize: 20,
        fontWeight: 500,
        fontStyle: 'regular',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-line',
        overflow: 'auto', // Ensures that the content can still scroll

        '&::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
    },
    highlight: {
        color: Colors.link,
        fontStyle: 'Italic'
    },
    authorText: {
        textAlign: 'right',
        alignSelf: 'flex-end',
        marginTop: 10,
        fontFamily: 'RedHatText',
        fontSize: 18,
        fontWeight: 500,
        color: Colors.textColor,
    },
});

const Text = ({ item }) => {

    const classes = useStyles();

    const highlightText = (text, highlights) => {
        const regex = new RegExp(`(${highlights.join('|')})`, 'gi');
        return text.split(regex).map((part, index) => {
            const key = `${part}-${index}`;
            if (highlights.includes(part.toLowerCase())) {
                return <span key={key} className={classes.highlight}>{part}</span>;
            } else {
                return part;
            }
        });
    };

    return (
        <div className={classes.textContainer}>
            {item.highlights && item.highlights.length > 0
                ? highlightText(item.text, item.highlights)
                : Utilities.processQuotedText(item.text)
            }
            <div className={classes.authorText}>
                {item.source}
            </div>
        </div>
    );
};

export default Text;
