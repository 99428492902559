import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';

const useStyles = makeStyles({
    textContainer: {
        color: Colors.textColor,
        fontSize: 22,
        fontWeight: 500,
        fontFamily: 'RedHatText',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    imageStyle: {
        cursor: 'pointer',
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '60vh',
        marginBottom: 20,
    },
    linkStyle: {
        cursor: 'pointer',
        color: Colors.link,
        marginBottom: '10px',
        textDecoration: 'underline'
    }
});

const LinkGallery = ({ item, onFullScreenClick }) => {
    const classes = useStyles();
    

    const handleClick = (index) => {
        if (onFullScreenClick) {
            onFullScreenClick(index);
        }
    };

    return (
        <div className={classes.textContainer}>
            {item.text}
            {item.links && item.links.map((link, index) => (
                <div 
                    key={index} 
                    className={classes.linkStyle}
                    onClick={() => handleClick(index)}
                >
                    {link}
                </div>
            ))}
        </div>
    );
};

export default LinkGallery;
