import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../Colors';
import letterIcon from '../../Assets/letterIcon.png';

const Letters = ({ item, onFullScreenClick }) => {
    const classes = useStyles();

    const getImagePath = () => {
        try {
            return require(`../../${item.path}${item.image}`);
        } catch (e) {
            console.error("Error loading image:", e);
            return "";
        }
    };

    return (
        <div className={classes.textContainer}>
            <img
                src={getImagePath()}
                alt="Gallery"
                className={classes.imageStyle}
                onClick={() => onFullScreenClick(0)}
            />
            <div className={classes.titleText}>{item.title}</div>
            <div className={classes.yearText}>{item.years}</div>

            <div className={classes.button} onClick={() => onFullScreenClick(0)}>
                <div className={`${classes.line} ${classes.topLine}`} />
                <div className={classes.buttonContainer} onClick={() => onFullScreenClick(0)}>
                    <img src={letterIcon} alt="letter icon" className={classes.icon} />
                    <span className={classes.buttonText}>Skatīt vēstules</span>
                </div>
                <div className={`${classes.line} ${classes.bottomLine}`} />
            </div>
            <div>{item.text}</div>
        </div>
    );
};

const useStyles = makeStyles({
    textContainer: {
        color: Colors.textColor,
        fontSize: 18,
        fontWeight: 500,
        fontFamily: 'RedHatText',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    titleText: {
        color: Colors.textColor,
        fontSize: 26,
        fontWeight: 700,
        fontFamily: 'RedHatText',
    },
    yearText: {
        color: Colors.textColor,
        fontSize: 16,
        fontWeight: 500,
        fontFamily: 'RedHatText',
        marginBottom: 10
    },
    imageStyle: {
        cursor: 'pointer',
        maxWidth: '40%',
        height: 'auto',
        marginBottom: 20,
        borderRadius: 10
    },
    line: {
        marginTop: 20,
        marginBottom: 20,
        height: '1px',
        width: '100%',
        background: 'linear-gradient(to right, transparent, ' + Colors.lineColor + ', transparent)',
        margin: 'auto',
        alignSelf: 'stretch',
    },
    button: {
        background: 'none',
        color: Colors.textColor,
        padding: 1,
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    buttonText: {
        color: Colors.textColor,
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'RedHatText',
    },
    icon: {
        marginRight: 15,
        width: 24,
        height: 18
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default Letters;
