import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { motion, AnimatePresence } from 'framer-motion';
import Colors from './Colors';

const useStyles = makeStyles({
    quoteContainer: {
        color: Colors.textColor,
        textAlign: 'right',
        maxWidth: '600px',
        margin: 'auto',
        padding: '20px',
    },
    quoteText: {
        color: Colors.textColor,
        fontSize: 40,
        font: 'RedHatText',
        fontWeight: 500,
        fontStyle: 'Italic',
        whiteSpace: 'pre-line'
    },
    author: {
        color: Colors.textColor,
        fontSize: 30,
        marginTop: 50,
        fontFamily: 'RedHatText',
        fontWeight: 700,
    },
    date: {
        color: Colors.textColor,
        fontSize: 20,
        fontFamily: 'RedHatText',
        fontWeight: 500,
    },
});

const Quote = ({ quotes }) => {
    const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0);
    const quote = quotes[currentQuoteIndex];
    const classes = useStyles();
    const [fontSize, setFontSize] = useState(40);

    const getFontSize = (text) => {
        const length = text.length;
        const screenWidth = window.innerWidth;
        if (length < 200) 
        {
            if (screenWidth > 600) {
                return 40
            } else {
                return 22
            }
        }
        return 20
    };

    useEffect(() => {
        setFontSize(getFontSize(quotes[currentQuoteIndex].text))
    }, [quotes]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentQuoteIndex((currentQuoteIndex + 1) % quotes.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [currentQuoteIndex, quotes.length]);

    return (
        <AnimatePresence mode='wait'>
            <motion.div
                key={quote.index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                className={classes.quoteContainer}
            >
                <div
                    className={classes.quoteText}
                    style={{ fontSize: fontSize }}
                >{quote.text}</div>
                <div className={classes.author}>{quote.author}</div>
                <div className={classes.date}>{quote.date}</div>
            </motion.div>
        </AnimatePresence>
    );
};

export default Quote;
