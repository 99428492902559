import React, { useState, useEffect, useRef } from 'react';
import Home from './Components/Home';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import logo from './Content/images/logo.png'
import background from './Assets/Background.png'
import Section from './Components/Section';

import { AnimatePresence, animate, motion } from 'framer-motion';
import Colors from './Design/Colors';
import Config from './Config';

const SectionEnum = {
  Home: 0,
  Intro: 1,
  Section1: 2,
  Section2: 3,
  Section3: 4,
  Section4: 5,
  Section5: 6,
  Section6: 7
};

const DirectionEnum = {
  Up: 0,
  Down: 1
};


function MainPage() {
  const [activeSection, setActiveSection] = useState({
    index: SectionEnum.Home,
    activeIndex: 0,
    content: []
  });

  const [lastInitialItem, setLastInitialItem] = useState(false);

  const [animationDirection, setAnimationDirection] = useState(DirectionEnum.Up);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const [screenHeight, setScreenHeight] = useState(0);

  const variants = {
    initial: {
      //y: 0,
      opacity: 1
    },
    animateUp: {
      //y: 0,
      opacity: 1
    },
    animateDown: {
      //y: 0,
      opacity: 1
    },
    exitUp: {
      //y: -screenHeight,
      opacity: 0
    },
    exitDown: {
      //y: screenHeight,
      opacity: 0
    }
  }

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateHeight);
    updateHeight();

    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  const changeActiveSection = (newActiveSection) => {
    if (newActiveSection > activeSection) {
      setAnimationDirection(DirectionEnum.Up)
    } else {
      setAnimationDirection(DirectionEnum.Down)
    }
    setTimeout(() => {
      setActiveSection(newActiveSection);
    }, 10);
  }

  const renderSection = () => {
    return (
      <AnimatePresence>
        <motion.div
          key={activeSection.index}
          variants={variants}
          exit={animationDirection == DirectionEnum.Up ? "exitUp" : "exitDown"}
          transition={{ duration: 0.3 }}
        >
          {sectionContent()}
        </motion.div>
      </AnimatePresence>
    );
  };

  const onPreviousSectionClick = () => {
    const previousSectionIndex = getAdjacentSection(DirectionEnum.Up);
    const content = contentForIndex(previousSectionIndex);
    if (content == null) {
      return
    }
    const initialIndex = content.length - 1;
    changeActiveSection({
      index: previousSectionIndex,
      content: content,
      initialIndex: initialIndex
    })
  }

  const onNextSectionClick = () => {
    const nextSectionIndex = getAdjacentSection(DirectionEnum.Down);
    const content = contentForIndex(nextSectionIndex);
    if (content == null) {
      return;
    }
    const initialIndex = 0;
    changeActiveSection({
      index: nextSectionIndex,
      content: content,
      initialIndex: initialIndex
    })
  }

  const getAdjacentSection = (direction) => {
    const sectionValues = Object.values(SectionEnum);
    const currentIndex = sectionValues.indexOf(activeSection.index);
    let newIndex = currentIndex + (direction == DirectionEnum.Down ? 1 : -1);

    if (newIndex < 0) {
      newIndex = sectionValues.length - 1;
    } else if (newIndex >= sectionValues.length) {
      newIndex = 0;
    }

    return sectionValues[newIndex];
  }

  const sectionContent = () => {
    if (activeSection.index == SectionEnum.Home) {
      return <Home onStartClick={() => handleSectionChange(SectionEnum.Intro)} />;
    }

    return <Section
      content={activeSection.content}
      onPreviousSectionClick={onPreviousSectionClick}
      onNextSectionClick={onNextSectionClick}
      initialIndex={activeSection.initialIndex}
    />
  };

  const contentForIndex = (index) => {
    switch (index) {
      case SectionEnum.Intro:
        return Config.INTRO
      case SectionEnum.Section1:
        return Config.SECTION1
      case SectionEnum.Section2:
        return Config.SECTION2
      case SectionEnum.Section3:
        return Config.SECTION3
      case SectionEnum.Section4:
        return Config.SECTION4
      case SectionEnum.Section5:
        return Config.SECTION5
      case SectionEnum.Section6:
        return Config.SECTION6
      default:
        return Config.INTRO
    }
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleSectionChange = (index, initialIndex = 0) => {
    const content = contentForIndex(index);

    changeActiveSection({
      index,
      content,
      initialIndex
    })
    handleDrawerClose();
  };

  const subItemsForSection = (sectionIndex) => {
    return (
      <List component="div" disablePadding>
        {contentForIndex(sectionIndex).map((item, index) => {
          if (item.type == 'title' && item.text && !Config.TITLES.includes(item.text)) {
            return (
              <React.Fragment key={index}>
                <ListItem button class={classes.subItemStyle} onClick={() => handleSectionChange(sectionIndex, index)}>
                  <div class={classes.subItemContainer}>
                    <div class={classes.dotStyle}></div>
                    <ListItemText primary={
                      <Typography className={classes.subListItemText}>
                        {item.text}
                      </Typography>
                    } />
                  </div>
                </ListItem>
              </React.Fragment>
            )
          }
        })}
      </List>
    )
  }

  return (
    <div className={classes.content}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        classes={{ paper: classes.drawerPaper }}
      >
        <List className={classes.drawer}>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Home)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Home]}
              </Typography>
            } />
          </ListItem>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Intro)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Intro]}
              </Typography>
            } />
          </ListItem>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Section1)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Section1]}
              </Typography>
            } />
          </ListItem>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Section2)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Section2]}
              </Typography>
            } />
          </ListItem>
          <Collapse in={true} timeout="auto" unmountOnExit>
            {subItemsForSection(SectionEnum.Section2)}
          </Collapse>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Section3)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Section3]}
              </Typography>
            } />
          </ListItem>
          <Collapse in={true} timeout="auto" unmountOnExit>
            {subItemsForSection(SectionEnum.Section3)}
          </Collapse>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Section4)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Section4]}
              </Typography>
            } />
          </ListItem>
          <Collapse in={true} timeout="auto" unmountOnExit>
            {subItemsForSection(SectionEnum.Section4)}
          </Collapse>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Section5)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Section5]}
              </Typography>
            } />
          </ListItem>
          <ListItem button className={classes.listItem} onClick={() => handleSectionChange(SectionEnum.Section6)}>
            <ListItemText primary={
              <Typography className={classes.listItemText}>
                {Config.TITLES[SectionEnum.Section6]}
              </Typography>
            } />
          </ListItem>
        </List>
      </Drawer >
      {!drawerOpen && (
        <IconButton onClick={handleDrawerOpen} className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
      )
      }
      {renderSection()}
    </div >
  );
}

const useStyles = makeStyles({
  content: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  drawer: {
    width: 300,
    color: Colors.textColor,
    '@media (max-width: 600px)': {
      width: 200
    }
  },
  drawerPaper: {
    width: 300,
    '@media (max-width: 600px)': {
      width: 250
    },
    backgroundColor: Colors.sideBarBackgroundColor
  },
  menuButton: {
    position: 'fixed',
    top: 20,
    right: 30,
    zIndex: 1300,
    color: Colors.textColor,
    '&:hover': {
      opacity: 0.7
    }
  },
  logo: {
    position: 'absolute',
    top: 30,
    left: 30,
    zIndex: 2,
    width: '80px',
    height: '80px',
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  listItem: {
    '&:active': {
      transform: 'scale(0.9)'
    },
    '&:hover': {
      opacity: 0.7
    },
  },
  listItemText: {
    color: Colors.textColor,
    fontSize: 19,
    font: 'RedHatText',
    fontFamily: 'RedHatText',
    fontWeight: 700,
  },
  subListItemText: {
    color: Colors.textColor,
    fontSize: 14,
    font: 'RedHatText',
    fontFamily: 'RedHatText',
    fontWeight: 500,
  },
  subItemStyle: {
    paddingLeft: '50px',
    position: 'relative',
    cursor: 'pointer',
    '&:active': {
      transform: 'scale(0.9)'
    },
    '&:hover': {
      opacity: 0.7
    }
  },
  dotStyle: {
    height: '8px',
    width: '8px',
    backgroundColor: Colors.textColor,
    borderRadius: '50%',
    position: 'absolute',
    left: '30px',
    top: '50%',
    transform: 'translateY(-50%)',
  }

});


export default MainPage;
