import React, { useState } from "react";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';

import leftArrow from '../Assets/left_arrow.png';
import rightArrow from '../Assets/right_arrow.png';
import Colors from "./Colors";
import LinkedTitle from "./LinkedTitle";

const Gallery = ({ item, onFullScreenClick, onLinkClick }) => {

  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    const nextIndex = currentIndex < item.images.length - 1 ? currentIndex + 1 : 0;
    setCurrentIndex(nextIndex);
  };

  const handlePrevious = () => {
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : item.images.length - 1;
    setCurrentIndex(prevIndex);
  };

  const getImagePath = (imageName) => {
    try {
      return require(`../${item.path}${imageName}`);
    } catch (e) {
      console.error("Error loading image:", e);
      return "";
    }
  };

  const openFullscreen = (index) => {
    onFullScreenClick(index)
  };

  return (
    <div>
      <div className={classes.galleryContainer}>
        <SwipeableViews index={currentIndex} onChangeIndex={(index) => setCurrentIndex(index)}>
          {item.images.map((img, idx) => (
            <div className={classes.swipeAbleContainer} key={idx} onClick={() => openFullscreen(idx)}>
              <img
                src={getImagePath(img)}
                alt={`Slide ${idx + 1}`}
                style={{ width: "100%" }}
              />
            </div>
          ))}
        </SwipeableViews>
        <button className={`${classes.button} ${classes.leftButton}`} onClick={handlePrevious}>
          <img src={leftArrow} alt="Left" className={classes.buttonImage} />
        </button>
        <button className={`${classes.button} ${classes.rightButton}`} onClick={handleNext}>
          <img src={rightArrow} alt="Right" className={classes.buttonImage} />
        </button>
      </div>
      <div className={classes.title}>
        {item.link ? (
          <LinkedTitle
            text={item.text}
            linkedWord={item.link}
            onLinkClick={onLinkClick}
          />
        ) : (
          item.text
        )}
      </div>
    </div>
  );
};


const useStyles = makeStyles({
  galleryContainer: {
    width: '100%',
    minHeight: '30vh',
    margin: "20px auto",
    margin: "0 auto",
    position: "relative",
  },
  swipeAbleContainer: {
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
    marginTop: '10px',
    color: Colors.textColor,
    fontSize: 30,
    fontFamily: 'RedHatText',
    fontWeight: 700,
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: '40%',
    '&:active': {
      transform: 'scale(0.9)'
    },
    '&:hover': {
      opacity: 0.7
    }
  },
  leftButton: {
    left: 0,
    zIndex: 1000
  },
  rightButton: {
    right: 0,
    zIndex: 1000
  },
  buttonImage: {
    width: '50px',
    height: '50px',
  },
});

export default Gallery;
