import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';

const useStyles = makeStyles({
  link: {
    color: Colors.link,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:active': {
      transform: 'scale(0.9)'
    },
    '&:hover': {
      opacity: 0.7
    }
  },
  
  // Add more styles here if needed
});

const LinkedTitle = ({ text, linkedWord, onLinkClick }) => {
  const classes = useStyles(); // Use the styles

  const linkify = (text, word, renderComponent) => {
    const escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(\\b${escapedWord}\\b)`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => 
      part.match(regex) ? renderComponent(part, index) : part
    );
  };

  const renderLink = (word, key) => (
    <span key={key} className={classes.link} onClick={() => onLinkClick(word)}>
      {word}
    </span>
  );

  const linkedText = linkify(text, linkedWord, renderLink);

  return (
    <div>
      {linkedText.map((part, index) => <React.Fragment key={index}>{part}</React.Fragment>)}
    </div>
  );
};

export default LinkedTitle;
