import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';
import Utilities from './Utilities';

const useStyles = makeStyles({
    textContainer: {
        fontFamily: 'RedHatText',
        color: Colors.textColor,
        fontSize: 20,
        fontWeight: 500,
        fontStyle: 'regular',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-line',
        overflow: 'auto',

        // Hide scrollbar for Webkit and Blink browsers like Safari, Chrome, and Opera
        '&::-webkit-scrollbar': {
            display: 'none'
        },

        // Hide scrollbar for IE, Edge, and Firefox
        msOverflowStyle: 'none', // IE and Edge
        scrollbarWidth: 'none', // Firefox
    },
    title: {
        fontFamily: 'RedHatText',
        color: Colors.title,
        fontSize: 50,
        fontWeight: 500,
        fontStyle: 'regular',
        fontWeight: 'bold',
        marginBottom: 30,
        textAlign: 'center',
      },
      itemTitle: {
        color: Colors.subTitleColor,
        fontSize: 18,
        fontWeight: 400,
        fontStyle: 'regular',
        fontWeight: 'light',
        textAlign: 'center',
      },
      itemText: {
        color: Colors.textColor,
        fontSize: 18,
        fontWeight: 500,
        fontStyle: 'regular',
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: 'center',
      },
});

const Creators = ({ item }) => {
    const classes = useStyles();
  
    return (
      <div className={classes.container}>
        <div className={classes.title}>{item.title}</div>
        {item.items.map((creator, index) => (
          <div key={index} className={classes.item}>
            <div className={classes.itemTitle}>{creator.title}</div>
            <div className={classes.itemText}>{creator.text}</div>
          </div>
        ))}
      </div>
    );
  };

export default Creators;
