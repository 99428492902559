import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  button: {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(30, 30, 30, 0.3)',
    border: '1px solid white',
    color: 'white',
    padding: theme.spacing(1, 3),
    borderRadius: theme.shape.borderRadius,
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    '&:active': {
      transform: 'scale(0.98)',
    },
  },
  text: {
    // If you need additional text styles, define them here
  },
}));

const BlurredButton = ({ onClick, title, style, textStyle }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={classes.button}
      style={style}
    >
      <span className={classes.text} style={textStyle}>
        {title}
      </span>
    </Button>
  );
};

export default BlurredButton;
