import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';
import Utilities from './Utilities';
import soundIcon from '../Assets/soundwave.png';

const DocumentGallery = ({ item, onFullScreenClick }) => {
    const classes = useStyles();
    const [imagesLoaded, setImagesLoaded] = useState(Array(item.images.length).fill(false));
    const [justifyContent, setJustifyContent] = useState('center');
    const thumbnailContainerRef = useRef(null);
    const thumbnailsInnerContainerRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const hasAudio = item.audio != null

    useEffect(() => {
        if (imagesLoaded.length > 1) {
            setJustifyContent('flex-start');
        } else {
            setJustifyContent('center');
        }
    }, [imagesLoaded]);

    const handleImageLoad = (index) => {
        setImagesLoaded((prevLoaded) => {
            const newLoaded = [...prevLoaded];
            newLoaded[index] = true;
            return newLoaded;
        });
    };

    const getImagePath = (image) => {
        try {
            return require(`../${item.path}${image}`);
        } catch (e) {
            console.error("Error loading image:", e);
            return "";
        }
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.thumbnailContainer} ref={thumbnailContainerRef}>
                    <div className={classes.thumbnailsInnerContainer} style={{ justifyContent }} ref={thumbnailsInnerContainerRef}>
                        {item.images.map((image, index) => (
                            <img
                                key={index}
                                src={getImagePath(image)}
                                alt={`Thumbnail ${index + 1}`}
                                className={classes.thumbnail}
                                onClick={() => onFullScreenClick(index, false)}
                                onLoad={() => handleImageLoad(index)}
                            />
                        ))}
                    </div>
                </div>
                {hasAudio && <div className={classes.button}
                    onClick={(e) => { e.stopPropagation(); onFullScreenClick(0, true) }}
                    onMouseEnter={() => setIsHovered(false)}
                    onMouseLeave={() => setIsHovered(true)}
                >
                    <div className={classes.buttonContainer}>
                        <img src={soundIcon} alt="sound icon" className={classes.soundIcon} />
                        <span className={classes.buttonText}>{"Atskaņot"}</span>
                    </div>
                </div>}
            </div>
            <div className={classes.textContainer}>
                <div>{Utilities.processQuotedText(item.text)}</div>
                <div className={classes.authorText}>
                    {item.source}
                </div>
            </div>
        </>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        maxWidth: '100vw',
        overflowX: 'hidden',
    },
    textContainer: {
        color: Colors.textColor,
        fontSize: 22,
        fontWeight: 500,
        fontFamily: 'RedHatText',
        whiteSpace: 'pre-line',
    },
    authorText: {
        textAlign: 'right',
        alignSelf: 'flex-end',
        marginTop: 10,
        fontFamily: 'RedHatText',
        fontSize: 18,
        fontWeight: 500,
        color: Colors.textColor,
    },
    imageStyle: {
        cursor: 'pointer',
        maxWidth: '100%',
        height: 'auto',
        marginBottom: 20,
    },
    thumbnailContainer: {
        display: 'flex',
        justifyContent: 'center',
        overflowX: 'auto',
        width: '100%',
        marginBottom: 20,
    },
    thumbnailsInnerContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        minWidth: 'min-content',
    },
    thumbnail: {
        width: '50%',
        '@media (max-width: 600px)': {
            width: '100%'
        },
        height: 'auto',
        marginRight: 10,
        cursor: 'pointer',
    },
    button: {
        background: 'none',
        color: Colors.textColor,
        padding: 10,
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        height: '30px',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    soundIcon: {
        marginRight: 5,
        width: 24,
        height: 24
    },
    buttonText: {
        color: Colors.textColor,
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'RedHatText',
    },
});

export default DocumentGallery;
