
class Utilities {
    static processQuotedText = (text) => {
        if (text == undefined || text.length == 0 || text == '') {
            return '';
        }
    
        // Regular expression to match text within regular and smart quotes, excluding those preceded by a backslash
        const regex = /(?<!\\)(“([^”]*?)”|\"([^"]*?)\")/g;
    
        let parts = [];
        let lastIndex = 0;
    
        text.replace(regex, (match, smartQuoteMatch, smartQuoteContent, straightQuoteContent, index) => {
            // Add non-quoted text with escaped quotes replaced
            parts.push(text.slice(lastIndex, index).replace(/\\(“|”|")/g, '$1'));
    
            // Determine the quoted text (either straight or smart quotes)
            const quotedText = smartQuoteContent || straightQuoteContent;
    
            // Add quoted text with italic style
            parts.push(
                <span key={index} style={{ fontStyle: 'italic' }}>
                    {smartQuoteMatch ? `“${quotedText}”` : `"${quotedText}"`}
                </span>
            );
    
            lastIndex = index + match.length;
        });
    
        // Add any remaining text after the last quote, also replacing escaped quotes
        parts.push(text.slice(lastIndex).replace(/\\(“|”|")/g, '$1'));
    
        return parts;
    };
    
    
}

export default Utilities