// Config.js

const Config = {
    HOME_COMPANY_NAME: 'Latvijas Kara Muzejs',
    HOME_EXHIBITION_NAME: 'ZAUDĒTAIS SAPNIS PAR BRĪVĪBU',
    HOME_EXHIBITION_SUBTITLE: ' Zviedrijas valdības Padomju Savienībai izdoto latviešu karavīru stāsts',
    HOME_START_BUTTON_TITLE: 'Sākums',
    BACK_TITLE: 'Atpakaļ',
    FORWARD_TITLE: 'Nākamais',

    TITLES: [
        'Sākums',
        'Ievads',
        'Nonākšana Zviedrijā',
        'Patvērumā',
        'Pretī nezināmam liktenim',
        'Dzīve notikušā ēnā',
        'Nobeigums',
        'Par izstādi',
    ],
    INTRO: [
        {
            index: 0,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `"Ir cilvēki, kas jebšu līdz kaklam ūdenī vēl dzer vīnu. 
                    Tā ja. 
                    Mēs vēl daudz runāsim, jeb par mums daudz runās."`,
                    author: 'Uldis Bērzs, kareivis',
                    date: '1946. gada 16. janvāris'
                }
            ],
        },
        {
            index: 1,
            type: 'text',
            text: `Otrais pasaules karš un tā iznākums ietekmēja simtiem tūkstošu pretējās frontes pusēs cīnījušos karavīru likteņus. Pēc sešiem gariem kara gadiem uzvārētājvalstu karavīriem tas atnesa mieru, uzvaras prieku un atkalredzēšanos ar tuviniekiem Arī latviešu karavīriem, kuri četrus gadus bija cīnījušies totalitārās Padomju Savienības armijā. Citādi bija ar tiem latviešu karavīriem, kuri bija spiesti cīnīties nacistiskās Vācijas militārajās vienībās. Viņiem kara beigas Eiropā 1945. gada 8. maijā atnesa neziņu par nākotni un tuviniekiem, daudziem zaudētu Latviju, mājas un ģimeni, dzīvi karagūstekņu nometnēs, padomju drošības orgānu arestus un daudzus gadus ieslodzījumā padomju soda nometnēs. 
            Pēc Vācijas bruņoto spēku kapitulācijas 25 400 latviešu karavīru nonāca rietumu sabiedroto gūstā, 36 000 padomju gūstā. 147 latviešu karavīri nokļuva Zviedrijā, lai dotos tālāk pasaulē un nekad neatgrieztos Padomju Savienībā. Tomēr realitātē viņu izsapņotais sapnis būt brīviem pamazām zuda. 
            Virtuālā izstāde stāsta par latviešu karavīru ceļu: internēšanu Zviedrijā, izdošanu Padomju Savienībai 1945. gada 25. janvārī, dzīvi pēc izdošanas un atgriešanos Zviedrijā pēc Latvijas neatkarības atjaunošanas`
        },
        {
            index: 2,
            type: 'gallery',
            text: `No Zviedrijas PSRS izdoto latviešu karavīru kartotēka, sastādījusi Līvija Vītoliņa`,
            link: 'Līvija Vītoliņa',
            links: [
                'Līvija Vītoliņa (Trēde, 1911 – 1994), publiciste un bibliotekāre,  dzimusi Valkas apriņķa Ilzenes pagasta Dumpju dzirnavās. Latviešu leģionāra, kapteiņa Ādolfa Vītoliņa dzīvesbiedre. 1944. gada oktobrī nonākusi Zviedrijā, kur dzīvojusi Gēteborgā. L. Vītoliņa aktīvi sarakstījās ar Zviedrijā internētajiem latviešu karavīriem gan pirms izdošanas, gan pēc tās, kā arī veidoja izdoto karavīru kartotēku.',
            ],
            path: 'Content/images/kart/',
            images: [
                '1kart.jpeg',
                '2kart.jpeg',
                '3kart.jpeg',
                '4kart.jpeg',
                '5kart.jpeg',
                '6kart.jpeg',
                '7kart.jpeg',
                '8kart.jpeg',
                '9kart.jpeg',
                '10kart.jpeg',
                '11kart.jpeg',
                '12kart.jpeg',
                '13kart.jpeg',
                '14kart.jpeg',
                '15kart.jpeg',
                '16kart.jpeg',
                '17kart.jpeg',
                '18kart.jpeg',
                '19kart.jpeg',
                '20kart.jpeg',
                '21kart.jpeg',
                '22kart.jpeg',
                '23kart.jpeg',
                '24kart.jpeg',
                '25kart.jpeg',
                '26kart.jpeg',
                '27kart.jpeg',
                '28kart.jpeg',
                '29kart.jpeg',
                '30kart.jpeg',
                '31kart.jpeg',
                '32kart.jpeg',
                '33kart.jpeg',
                '34kart.jpeg',
                '35kart.jpeg',
                '36kart.jpeg',
                '37kart.jpeg',
                '38kart.jpeg',
                '39kart.jpeg',
                '40kart.jpeg',
                '41kart.jpeg',
                '42kart.jpeg',
                '43kart.jpeg',
                '44kart.jpeg',
                '45kart.jpeg',
                '46kart.jpeg',
                '47kart.jpeg',
                '48kart.jpeg',
                '49kart.jpeg',
                '50kart.jpeg',
                '51kart.jpeg',
                '52kart.jpeg',
                '53kart.jpeg',
                '54kart.jpeg',
                '55kart.jpeg',
                '56kart.jpeg',
                '57kart.jpeg',
                '58kart.jpeg',
                '59kart.jpeg',
                '60kart.jpeg',
                '61kart.jpeg',
                '62kart.jpeg',
                '63kart.jpeg',
                '64kart.jpeg',
                '65kart.jpeg',
                '66kart.jpeg',
                '67kart.jpeg',
                '68kart.jpeg',
                '69kart.jpeg',
                '70kart.jpeg',
                '71kart.jpeg',
                '72kart.jpeg',
                '73kart.jpeg',
                '74kart.jpeg',
                '75kart.jpeg',
                '76kart.jpeg',
                '77kart.jpeg',
                '78kart.jpeg',
                '79kart.jpeg',
                '80kart.jpeg',
                '81kart.jpeg',
                '82kart.jpeg',
                '83kart.jpeg',
                '84kart.jpeg',
                '85kart.jpeg',
                '86kart.jpeg',
                '87kart.jpeg',
                '88kart.jpeg',
                '89kart.jpeg',
                '90kart.jpeg',
                '91kart.jpeg',
                '92kart.jpeg',
                '93kart.jpeg',
                '94kart.jpeg',
                '95kart.jpeg',
                '96kart.jpeg',
                '97kart.jpeg',
                '98kart.jpeg',
                '99kart.jpeg',
                '100kart.jpeg',
                '101kart.jpeg',
                '102kart.jpeg',
                '103kart.jpeg',
                '104kart.jpeg',
                '105kart.jpeg',
                '106kart.jpeg',
                '107kart.jpeg',
                '108kart.jpeg',
                '109kart.jpeg',
                '110kart.jpeg',
                '111kart.jpeg',
                '112kart.jpeg',
                '113kart.jpeg',
                '114kart.jpeg',
                '115kart.jpeg',
                '116kart.jpeg',
                '117kart.jpeg',
                '118kart.jpeg',
                '119kart.jpeg',
                '120kart.jpeg',
                '121kart.jpeg',
                '122kart.jpeg',
                '123kart.jpeg',
                '124kart.jpeg',
                '125kart.jpeg',
                '126kart.jpeg',
                '127kart.jpeg',
                '128kart.jpeg',
                '129kart.jpeg',
                '130kart.jpeg',
                '131kart.jpeg',
                '132kart.jpeg',
                '133kart.jpeg',
                '134kart.jpeg',
                '135kart.jpeg'
            ]
        }
    ],
    SECTION1: [
        {
            index: 0,
            type: 'title',
            text: 'Nonākšana Zviedrijā'
        },
        {
            index: 1,
            type: 'textgallery',
            text: `Otrā pasaules kara izskaņā 1945. gada pavasarī neitrālā Zviedrija bija internējusi 167 baltiešu karavīrus, no tiem 149 latviešus. 
            Latviešu SS brīvprātīgo leģiona (turpmāk – Latviešu leģiona) 15. artilērijas pulka III diviziona 119 karavīri evakuējās no Sarkanās armijas apdraudētās Dancigas apkārtnes (tagad Gdaņska Polijā) un 1945. gada 8. maijā sasniedza Īstades (Ystad) ostu Zviedrijas dienvidos, pa ceļam vairāk nekā mēnesi uzturoties vācu kontrolētajā Bornholmas (Bornholm) salā. 
            Bēgot no Sarkanās armijas ieņemtās Kurzemes, 40 latviešu karavīri, galvenokārt no Latviešu leģiona 19. ieroču SS grenadieru divīzijas, 1945. gada 9. maijā vācu desantlaivās devās pāri Baltijas jūrai un 12. maijā sasniedza Zviedrijas krastus Gotlandes salā`,
            image: '1.jpg',
            path: 'Content/maps/',
        },
        {
            index: 2,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“Vācu ērglis sāka paplukt. Mēs atkāpāmies. Danciga. (..)
                    Loks vilkās arvien ciešāk. Un tad atceļa vairs nebija. Priekšā gulēja jūra. 
                    Laime negrieza muguru. Tas lepnais kuģis “Potrimps”, kas kādreiz bija šlepējis tirgus sievas, tantiņas un spekulantus no Rīgas uz Jelgavu un atpakaļ, ietilpināja mūs savās rūmēs. Divos pēcpusdienā iedrebējās skrūve. Pie apvāršņa palika dūmu mētelī tinusies degoša pilsēta. Jūras brauciens neizpalika bez dramatiskiem momentiem. Cēlās lieli viļņi, un sāka trūkt dzeramā un kurināmā. Sāka lauzt kastes un iekārtu. Un, kad jau bija jāšaubas par pasākuma izdošanos, mūsu priekšā, kā daždien romānos, pēdējā mirklī, iznira insula Bornholm, un dāņu ķēniņa karogs plivinājās gaisos. Tas notikās zaļajā ceturtdienā. Dāņu zeme deva lielisku iespēju atkopties. Pēc vācu ķēķa, kur kulināro mākslu bagātīgi pārstāvēja zupa a lā Zilā Donava, Suņa prieks jeb Zaļais valdnieks, vēders tagad bija izbrīnā par pienu, krējumu, sviestu, šķiņķīšiem, desiņām, zivīm, saldumiem, ķūķulēm un saldiem krikumiem, kas pārpilnībā, bieži pat pārmērībā ieplūda tajā. 
                    (..) Vakarā pār pilsētu drāžas krievu iznīcinātāji un izmet lapeles ar kāda augsta aviācijas virsnieka pašrocīgu parakstu. Uzaicina kapitulēt. Plkst. 24.00. “Potrimps”, kas brīnumainā kārtā tikai nedaudz apskādēts, izslīd jūrā. Atpaliek kritušie, lētticīgie un smagi ievainotie. Agrā rīta stundā “Potrimps” jau plunčājas zviedru ūdeņos. 8. maijā, Ostas pilsētā Īstadē, es izkāpu zviedru zemes krastmalā. [..]” `,
                    author: 'Uldis Bērzs, kareivis',
                    date: '1945. gada 17. jūlijs'
                }
            ],
        },
        {
            index: 3,
            type: 'textgallery',
            text: `Latviešu leģiona 15. artilērijas pulka III diviziona karavīru transports ceļā uz Zviedriju – upes tvaikonis “Potrimps” un velkonis “Alnis”. Svaneke (Svaneke), Bornholma, 1945. gada 29. marts`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '2.jpg',
            path: 'Content/images/'
        },
        {
            index: 4,
            type: 'textgallery',
            text: `Latviešu leģiona 15. artilērijas pulka III diviziona karavīru uz velkoņa “Alnis” Svanekes ostā. Bornholma, 1945. gada 30. marts
            No kreisās - virsnieka vietnieks Juris Kamerilovs, virsnieka vietnieks Ervīns Čikste, dižkareivis Arnolds Šmits-Pētersons, kapteinis Ernests Ķeselis un vadītājs Pētersons uz kuģīša`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '3.jpg',
            path: 'Content/images/'
        },
        {
            index: 5,
            type: 'textgallery',
            text: `Ārsts leitnants Jānis Slaidiņš uz velkoņa “Alnis”. Baltijas jūra, 1945. gada 27. marts 
            Lai latviešu karavīri varētu tikt prom no Dancigas apkārtnes, Jānis Slaidiņš izrakstīja tiem fiktīvas slimības zīmes`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '4.jpeg',
            path: 'Content/images/'
        },
        {
            index: 6,
            type: 'textgallery',
            text: `Latviešu virsnieki Bornholmas pilsētā Rennē (Rønne). Renne, Bornholma, 1945. gada aprīlis 
            No kreisās: virsnieka vietnieks Ervīns Čikste, kapteinis Vilis Ziemelis, leitnants Edvīns Alksnis, leitnants Jānis Presņikovs, leitnants Valdis Knoks, virsleitnants Pauls Lielkājs, leitnants Arnolds Līcis`,
            image: '5.jpg',
            source: 'Avots: Latvijas Kara muzejs',
            path: 'Content/images/'
        },
        {
            index: 7,
            type: 'textgallery',
            text: `Vācijas bruņoto spēku karavīri ceļā no Kurzemes uz Gotlandi. Baltijas jūra, 1945. gada 8. – 9. maijs
            Priekšplānā sēž 1. no labās – Vācijas gaisa spēku izpalīgs Oļģerts Ābrams`,
            image: '6.jpg',
            source: 'Avots: Latvijas Kara muzejs',
            path: 'Content/images/'
        }
    ],
    SECTION2: [
        {
            index: 0,
            type: 'title',
            subText: 'Dzīve internēto karavīru nometnēs Zviedrijā',
            text: 'Patvērumā'
        },
        {
            index: 1,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“Pirmais brauciens no krasta uz internēto nometni “Lager Lingen” Havdhemā likās kā neticamā pasakā - no Kurzemes un kara briesmām gaišā pārtikušā pasaulē”`,
                    author: 'Valentīns Silamiķelis, kareivis'
                },
                {
                    index: 1,
                    text: `“Beidzot esam zemē, kur daudz mūsu tautiešu atraduši patvērumu no boļševiku pātagas. Arī mēs ilgojamies pēc šī patvēruma”`,
                    author: 'Ojārs Caune, kareivis'
                },
                {
                    index: 2,
                    text: `“Ar nepacietību gaidām dienu, kad mūs atbrīvos. Par to runājam rītos un vakaros. Galvenā doma, kas mums prātā – brīvība” `,
                    author: 'Oskars Daugulis, kareivis'
                }
            ],
        },
        {
            index: 2,
            type: 'text',
            text: `Nonākuši Zviedrijā, bijušie virsnieki, instruktori un kareivji jutās izglābušies. Daļa no viņiem uzdevās par civilistiem, daļu internēja kā militārpersonas un līdz 1945. gada novembrim izvietoja Bekebergas (Bökeberg), Ekšes -Rennesletes (Eksjö - Ränneslätt) un Havdhemas (Havdhem, Gotland) internēto* karavīru nometnēs.\n 
            Atrazdamies aiz dzeloņdrātu žoga, karavīri gaidīja drīzu atbrīvošanu un civilās dzīves sākumu kara neskartajā Zviedrijā
            \n
            * internēšana – neitrālas valsts veikta karojošas valsts militārpersonu ieslodzīšana uz laiku`
        },
        {
            index: 3,
            type: 'textgallery',
            text: 'Ēkšes - Rennesletes internēto karavīru nometne 1945. gada vasarā',
            image: '7.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 4,
            type: 'textgallery',
            text: 'Internēto karavīru nometnes',
            image: '2.jpg',
            path: 'Content/maps/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 5,
            type: 'title',
            text: 'Bekebergas karantīnas nometne',
        },
        {
            index: 6,
            type: 'text',
            text: `Bekebergas karantīnas nometne, kurā līdz maija beigām atradās 126 latviešu leģiona 15. artilērijas pulka III diviziona karavīri, bija iekārtota kādā muižā blakus ezeram. Tajā leģionārus novietoja atsevišķā dzeloņdrātu nodalījumā blakus vāciešiem. Virsnieki dzīvoja muižas ēkā, kareivji – auduma teltīs. Nometnē karavīri ieturēja maltītes, sauļojās, spēlēja volejbolu, klausījās ziņas, ik vakaru noturēja vakara jundu, nodziedot “Dievs, svētī Latviju”`
        },
        {
            index: 7,
            type: 'textgallery',
            text: `Latviešu leģiona virsnieki Bekebergas karantīnas nometnē 1945. gada maijā 
            Priekšplānā 1. no kreisās sēž virsleitnants Pauls Lielkājs, 2. – stāv virsleitnants Arturs Plūme. 2. rindā 1. no kreisās leitnants Edvīns Alksnis, 2. virsnieka vietnieks Ervins Čikste, 3. kapteinis Ernests Ķeselis, 4. virsleitnants Ernests Kalsons, 5. virsleitnants Voldemārs Eltermanis, 6. kapteinis Vilis Ziemelis, 7. leitnants Jānis Presņikovs`,
            image: '8.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 8,
            type: 'textgallery',
            text: `Latviešu leģiona virsnieki sarunās ar zviedru komandantu Bekebergas karantīnas nometnē Zviedrijā. Bekeberga, 1945. gada maijs 
            No kreisās: 2. kapteinis Ernests Ķeselis, 3. kapteinis Vilis Ziemelis, 4. zviedru komandants`,
            image: '9.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 9,
            type: 'title',
            text: 'Havdhemas internēto karavīru nometne'
        },
        {
            index: 10,
            type: 'text',
            text: `40 latviešu karavīri (galvenokārt no Latviešu leģiona 19. divīzijas) 1945. gada maija pirmajā pusē nonāca Havdhemas internēto nometnē Gotlandē. 
            Nometnes teritoriju ieskāva dzeloņdrātu žogs. Internētajiem neļāva strādāt ārpus nometnes, izņemot baltiešus, kuriem bija dota atļauja strādāt kūdras purvā. Par darbu viņi saņēma algu – kareivji vienu kronu, instruktori – divas, virsnieki – četras līdz septiņas kronas dienā.
            Latviešu karavīri Havdhemas nometnē atradās līdz 1945. gada oktobrim `
        },
        {
            index: 11,
            type: 'textgallery',
            text: 'Havdhemas nometnē nonākušie latviešu karavīri 1945. gada maijā',
            image: '10.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 12,
            type: 'textgallery',
            text: `Internētie baltiešu karavīri ceļā uz baznīcu. Havdhemas nometnes apkārtne, Gotlande, 1945. gada 24. jūnijs 
            1. rindā no kreisās – pulkvedis-leitnants Kārlis Gailītis, lietuviešu majors Prans Ambražjūns, ārsts Elmārs Eihfūss-Atvars`,
            image: '11.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 13,
            type: 'textgallery',
            text: `Ēdienreize Havdhemas nometnē. 1945. gada vasara`,
            image: '12.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 14,
            type: 'textgallery',
            text: `Dižkareivja Pētera Legzdiņa lietotā Zviedrijas bruņoto spēku karote ar trīs kroņu attēlu. Zviedrija, 20. gs. 40. gadi`,
            image: '12-1.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 15,
            type: 'title',
            text: 'Ēkšes – Rennesletes internēto karavīru nometne'
        },
        {
            index: 16,
            type: 'text',
            text: `1945. gada 31. maijā internētos latviešu karavīrus no Bekebergas karantīnas nometnes pārvietoja uz 300 km attālo Rennesletes nometni pie Ēkšes pilsētas Zviedrijas vidienē. Nometnē latviešu karavīrus sagaidīja 800 vācu kareivju un 70 virsnieku. Nometnes iekšējā kārtībā latviešu karavīrus pakļāva vācu nometnes komandantam.\n
            Priežu mežā, zviedru armijas apmācību laukumā iekārtoto nometni ieskāva divas augstas dzeloņdrātu sētas, ko no ārpuses apsargāja zviedru karavīri.
            Internētie dzīvoja nometnes stūrī novietotās astoņstūrainās, apkurināmās audumā teltīs pa 20 vīru katrā. Katram karavīram bija paredzēts skaidu matracis ar gultas veļu. Ēdienu no zviedru armijas atvestajiem produktiem nometnes virtuvē gatavoja vācu karavīri`
        },
        {
            index: 17,
            type: 'textgallery',
            text: `Skats uz Ēkšes - Rennesletes internēto karavīru nometni 1945. gada vasarā`,
            image: '13.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 18,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“Ko domāju Zviedrijā iesākt – nezinu. Jāaprok vieglprātība un jāuzmanas lai uz pasaules parketa nekluptu. Esmu viens. Ziņas no vecākiem pārtrūka pag. gada oktobrī. Tomēr visādā ziņā jūtos labi, esmu tāds pat, kādu, varbūt, madame mani atceras. Nav vērts gausties. Pietiekoši jau vaimanā latviešu drukātais vārds. Vispār dīvainas lietas. Divi latvieši, trīs partijas. Grābj viena otrai acīs. Tas tik tā starp citu. Katrā gadījumā pasaule pietiekoši plaša un es vēl pietiekoši jauns. Nosūtīju vēstules britu un Brazīlijas sūtnim, lai gūtu informāciju par dažādām eksistences iespējām. 
                    Iekams ziepju gabaliņš būs galīgi sadilis, ceru pastaigāties ārpus dzeloņdrātīm.”`,
                    author: 'Uldis Bērzs, kareivis',
                    date: '1945. gada 17. jūlijs'
                }
            ],
        },
        {
            index: 19,
            type: 'textgallery',
            text: `Zviedrijā internēto latviešu karavīru kopbilde pēc latviešu mākslinieku koncerta. Ēkšes – Rennesletes nometne, 1945. gada 2. jūlijs`,
            image: '14.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 20,
            type: 'textgallery',
            text: `Internētie latviešu leģionāri pie pašdarināta rotājuma – latviešu leģiona vairodziņa. Ēkšes – Rennesletes nometne, 1945. gada vasara
            Vairodziņš izgatavots no zemes, akmeņiem un zāles, izvietots kādas telts priekšā`,
            image: '15.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 21,
            type: 'textgallery',
            text: `Viens no Zviedrijā internētajiem latviešu karavīriem stāv atspiedies pret nometnes sētu. Ēkšes - Rennesletes nometne, 1945. gada vasara`,
            image: '16.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 22,
            type: 'text',
            text: `Internētie karavīri ik dienas, izņemot svētdienas, devās ārpus nometnes dažādos darbos: raka meliorācijas grāvjus vietējiem lauksaimniekiem, taisīja jaunus ceļus un sporta laukumu Ēkšes pilsētai, palīdzēja vietējam garnizonam izbūvēt šaujamlaukumu u. c.`
        },
        {
            index: 23,
            type: 'textgallery',
            text: `Internētie latviešu leģionāri ceļā uz darbu. Ēkšes – Rennesletes nometne, 1945. gada vasara
            Priekšplānā virsleitnants Voldemārs Eltermanis, aiz viņa virsleitnants Arturs Plūme`,
            image: '17.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 24,
            type: 'textgallery',
            text: `Zviedrijā internētie karavīri gatavo mēbeles. Ēkšes – Rennesletes nometne, 1945. gada vasara`,
            image: '18.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 25,
            type: 'text',
            text: `Brīvajā laikā internētie karavīri nodarbojās ar sportu, uz pašu celtās skatuves reizi mēnesī notika kopīga karavīru cirka izrāde,  abonēja latviešu un zviedru laikrakstus. Laikrakstus latviešu valodā “Latvju Vārds” un “Latviju Ziņas” piegādāja arī nometnes viesi. Karavīri izgatavoja arī sienas avīzi. Sākotnēji tā bija vienīgais informācijas avots, jo tajā tika tulkoti raksti un radioraidījumi no karavīriem nepazīstamās zviedru valodas un daudziem nepazīstamajām angļu, krievu un vācu valodām`
        },
        {
            index: 26,
            type: 'textgallery',
            text: `Latviešu karavīri vēro futbola spēli. Ēkšes – Rennesletes nometne, 1945. gada vasara 
            Priekšplānā 1. rindā 1. no labās leitnants Edvīns Alksnis`,
            image: '19.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 27,
            type: 'textgallery',
            text: `Trīs latviešu virsleitnanti (no labās): Vilis Krūmiņš, Vilis Ziemelis (tur rokās zviedru laikrakstu “Stockholms-Tidningen”) un Pauls Lielkājs. Ēkšes – Rennesletes nometne, 1945. gada 23. jūnijs`,
            image: '20.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 28,
            type: 'text',
            text: `Nometnēs karavīri svinēja Jāņus, atzīmēja Lāčplēša dienu 11. novembrī. 18. novembrī Ekšes - Rennesletes nometnē notika internēto latviešu karavīru dievkalpojums par godu Latvijas valsts svētkiem`
        },
        {
            index: 29,
            type: 'textgallery',
            text: `Latviešu karavīri Jāņos. Ēkšes - Rennesletes nometne, 1945. gada 23. jūnijs
            Telts priekšā stāv  latviešu virsnieki: no kreisās 1. leitnants Bernhards Celms, 2. virsleitnants Pauls Lielkājs, 3. kapteinis Vilis Ziemelis, 4. ārsts leitnants Jānis Slaidiņš, 5. leitnants Edvīns Alksnis, 6. virsleitnants Voldemārs Eltermanis, 7. virsleitnants Arturs Plūme, 8. virsleitnants Ernests Kalsons, 9. virsleitnants Jēkabs Raiskums (teltī)`,
            image: '21.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 30,
            type: 'textgallery',
            text: `Karavīri svin Jāņus. Ēkšes – Rennesletes nometne, 1945. gada 23. jūnijs`,
            image: '22.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 31,
            type: 'textgallery',
            text: `Latviešu mākslinieku koncerta laikā. Ēkšes - Rennesletes nometne, 1945. gada 2. jūlijs 
            1. rindā 1. no kreisās virsleitnants Artūrs Plūme, 3. rindā 1. no kreisās pianiste Veronika Pabērze, 2. virsleitnants Pauls Lielkājs, 3. kapteinis Vilis Ziemelis`,
            image: '23.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 32,
            type: 'textgallery',
            text: `Dievkalpojums latviešu karavīriem. Ēkšes - Rennesletes nometne, 1945. gada 18. novembris`,
            image: '24.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 33,
            type: 'textgallery',
            text: `Dievkalpojuma par godu Latvijas valsts svētkiem programma. Ēkšes - Rennesletes nometne, 1945. gada 18. novembris`,
            image: '25.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 34,
            type: 'text',
            text: `Septembra sākumā tika pieņemts lēmums internētos karavīrus atdalīt no vācu karavīriem un novietot divus kilometrus tuvāk Ēkšes pilsētai.  Karavīri cerēja, ka viņu dzīve būs labāka un mierīgāka, nekā esot kopā ar vāciešiem.
            Oktobra sākumā nometnē ieradās karavīri, kuri no maija vidus bija internēti Havdhemas nometnē Gotlandē`
        },
        {
            index: 35,
            type: 'textgallery',
            text: `Internētie karavīri Ēkšes - Rennesletes nometnē. 1945. gada rudens`,
            image: '26.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 36,
            type: 'textgallery',
            text: `Internētie karavīri aiz dzeloņdrātu žoga. Ēkšes - Rennesletes nometne, 1945. gada rudens`,
            image: '27.jpg',
            path: 'Content/images/',
            source: 'Avots: Latvijas Kara muzejs',
        },
        {
            index: 37,
            type: 'title',
            text: 'Karavīri raksta'
        },
        {
            index: 38,
            type: 'text',
            text: `Pašu karavīru stāstu par dzīvi internēto nometnēs patvēruma laikā atklāj karavīru vēstules, ko viņi rakstīja tautiešiem ārpasaulē. Vēstules raksturo karavīru ikdienas dzīvi nometnēs, liecina par cerībām uz drīzu atbrīvošanu un brīvību internēšanas pirmajos mēnešos, kā arī atklāj pakāpeniski pieaugošas bažas par nākotni `
        },
        {
            index: 39,
            type: 'letter',
            image: '28.jpg',
            path: 'Content/images/',
            text: `Leitnants, dzimis Katvaru pagastā, miris Londonā. Latvijas Kara skolas 13. izlaiduma absolvents. 1944. gada 15. augustā ieskaitīts latviešu leģiona 15. artilērijas pulkā. No Zviedrijas neizdeva, jo 1945. gada 13. decembrī izdarīja pašnāvības mēģinājumu. 1946. gada rudenī ar laivu nonāca Anglijā, kur nodzīvoja visu atlikušo mūžu. Latvijā palika sieva un divas meitas, trimdā apprecējās otrreiz`,
            title: 'Edvīns Alksnis',
            years: '(1912 - 1995)',
            letterIndex: 32,
            letters: [
                '32-1.jpg',
                '32-2.jpg',
                '32-3.jpg',
                '32-4.jpg',
                '33-1.jpg',
                '33-2.jpg',
                '33-3.jpg',
                '34-1.jpg',
                '34-2.jpg',
            ],
            transcripts: [
                `Labā Vītoliņa kundze!\n \nVispirms ļoti pateicos par sveicieniem ko saņēmu ar Jūsu rakstīto vēstuli Ziemeļa kgam. Pēc tam sekoja mana lielā, laimīgā diena – saņēmu pats pirmo vēstuli šeit, kur domāju, ka tādas nekad nesaņemšu, jo man šeit nava laikam neviena radinieka vai tuvāka drauga. Lielo prieku par vēstuli drusku apēnoja reizē ar to saņemtais naudas pārvedums. – Kādēļ to vajadzētu darīt? – Jūs ļaujiet savai labai sirdij par daudz vaļu, atraujiet sev un mazai meitiņai naudu, kad grūti pelnīta. Lūdzu, lūdzu nekad to turpmāk nedariet bez manas ziņas, ja tiešām man klāsies tik ļauni un vajadzēs palīdzību, tad lūgšu to.
                Mēs, leitnanti šeit saņemam Kr. 2. – dienā, ēdiens pietiekošs, telts mežā par brīvu, tādēļ patreizējā stāvoklī nauda cigaretēm pietiek. Ja tiksim kādreiz arī no internēto nometnes, tad esmu vēl pietiekoši jauns un vesels, lai darbā, kaut vienkāršā, nopelnītu sev iztiku. Lai cik labi būtu internēto dzīves apstākļi, tad dzeloņa drātis visapkārt nomāc dvēseli un rada sajūtas, ka laikam nekad nebūsi tāds cilvēks, kā tie, kas ārpus šī žoga
                `,
                `Esmu laimīgs, ka Jūs esiet atradusi darbu, pajumti un ikdienas variet redzēt savu meitiņu veselu un skaistu augam. Man šī iespēja gan laikam vairs nekad nebūs, jo mana sieva L.S.Kr. žēlsirdīgā māsa ar 9 g.v. meitu un 5 g.v. dēlu palika pazīstamā mežziņa ģimenē pie Ventspils, Popes pag. un attiecās braukt, baidīdamās jūras ceļa uz Zviedriju vai Vāciju. Šīs mazās bailes nu jāsamaksā ar dārgāko – dzīvību. Mani vecāki, māsas – lauksaimnieki Valmieras apr., sakarā ar krievu straujo iebrukumu nespēja nekur evakuēties, un tā nu stāvu viens šajā zemē. Lielais likteņa lēmējs tā ir gribējis, bet vai tur ir kāda jēga, to es patreiz neesmu spējīgs saprast. 
                Divi nedēļas esam tagadējā jaunā lāģerī Eksjö tuvumā, kopā ar latviešiem nepatīkamiem kaimiņiem – vāciešiem, un tas lūk arī nospiež sirdis, ka netiekam pat šajā brīvajā, viesmīlīgajā zemē vaļā no tiem, kuri mūsu tautai tik daudz ciešanas deva, kaut kopā ar tiem bijām spiesti cīnīties pret mūsu tautas iznīcinātājiem – boļševikiem. Vācietis nebūtu vācietis, ja viņš nevarētu spīdēt ar savu tik pazīstamo prūšu nekaunību. Visi atceramies mūsu pirmo, jauko nometni Bökebergā, kuras komandants`
                , `komandants – zviedru kpt., brīnišķīgs sirds cilvēks, bija pieejams un tuvs latviešu karavīru bēdām. Neaizmirstami ir viņa vārdi, ko tas teica pie mūsu atvadu ugunskura Bökebergā, ka ugunskuri pakalnēs zviedru un latviešu tautām saules svētkos ir brīvības zimbols un ka latviešu karavīriem ir nākotnes uzdevums -  brīvu darīt savu zemi, iededzināt tās pakalnos šo brīvības zīmi. Tāpat nav aizmirstami tie brīnišķie ēdieni, ko zviedru lotas gatavoja. Šeit ēdienu gatavo vācu pavāri. Tā bija, bet arī šeit varam dzīvot labi un plānot, ko darīt, ja mūs atbrīvo. Daži izlasījuši laikrakstā ziņu, ka Baltijas bēgļiem būs iespēja doties darbā uz Abesiniju, taisās šo iespēju izmantot, jo kamēr “Vaņkas” mūsu zemē, neviens tur labprātīgi neatgriezīsies, labāk tad nāve tūlīt, nekā ļauties krievu zvēriskām mocībām un beigās tomēr saņemt lodi pakausī. Sāpīgi bija dzirdēt, ka ar latv. kuģi “Lāčplēsis” no Liepājas kopā ar vāciešiem braucošais mūsu tautietis leitn. Zālītis pie Zviedrijas krastiem ielēcis jūrā vāciešu muļķīgās runas dēļ. Pēdējie izteikušies, ka zviedri nelaiž kuģi krastā un tādēļ dosies atpakaļ pie krieviem. Viņš bija mūsu pulka virsnieks. 
                `,
                `Sirsnīgi pateicos par Jūsu labo domu palīdzēt man ar Ādolfa drēbēm, bet esiet prātīga un glabājiet vien pagaidām, varbūt viņš tomēr kaut kā izglābies no Dancigas elles un atrodas angļu okupētās Vācijas daļā drošībā. Angļi mūsu tautai palīdzēja agrāk un cerēsim, ka mūs neaizmirsīs arī tagad. Par ltn. Milleru Otto zinu tikai, ka tas bija Tornā kā adjutants plkv.ltn. Veckalniņa darba bataljonā, bet, kas notika pēc Tornas krišanas, to nezinu. Par Vītolu Mārtiņu nekā nezinu, viņš tiešām ir bijis draugs vārda patiesīgumā! Par Upīti un Indzeru arī es nekā negribu zināt, jo tie ir cilvēki, kas labas atmiņas nedod.
                Ir grūti visu vēstulē rakstīt un pateikt, būtu bezgala liels prieks jūs redzēt, bet nebrauciet un naudu netērējiet, jo kontrolētu satikšanos, 10 min., dod tikai vācu valodā. Cerēsim, ka būs mums iespēja tikties kā brīviem cilvēkiem un uz Abesiniju jau tik ātri nebrauksim. Vai Jūsu meitiņa aug zviedru ģimenē, kāds viņai ir vārdiņš, vai viņa jau runā latviski un prot kādu vārdiņu zviedriski. Variet droši rakstīt latviešu valodā, jo vācu valodā vēstules nekādu priekšrocību nebauda. Sveicieni no Ziemeļa un Krūmiņa kgiem. Pielieku 2 gab. smēķējamas kartiņas, kuras varbūt variet izlietot, man atliek. Ar sirsnīgu sveicienu Jūsu E. Alksnis
                `,
                `Labā Vītoliņa kundze!\n
                Saņēmu Jūsu otro vēstuli un šodien arī Jūsu dāvanu – paciņu. Sirsnīgi pateicos par abām, lai gan pēdējo nevajadzēja sūtīt, esam šeit paēduši labi un tā kā šnabi nekādā veidā nav iespējams dabūt, tad tik jauka “zakuska” neatrod savu īsto godinājumu. Ļoti labi zinu, ka Jūs būdama viena, gaida ikdienas pastnieku, lai saņemtu kādu vēstuli no citurienes, mums tas ir savādāki, mēs šeit esam kopā daudz dvēseļu, ir ar ko izrunāties latviešu mēlēs, i ar ko izlamāties ar krievu vārdu iespraudumiem – pušķojumiem, kaut esam drūmo savās nākotnes domās, tad tomēr paliek t.s. karātavu humors, bez tā būtu grūti vadīt dienas.
                Prieku un pārmaiņas mūsu vidū ienesa ciemiņi no Stokholmas – dziedonis Vētra, čellists Ozoliņš un pianists Pabērzs. Bija arī līdzi mācītājs Slokenbergs. Pēc dievkalpojuma bija koncerts un kopējās mūsu vakariņas, pavadījām pāris stundas tiešām labi. Viņi visi ir pilni lielas
                `,
                `un skaistas ticības nākotnei. Lai Dievs dod viņu domu piepildījumiem palīdzīgu roku!
                Jā, piemirsu vēl pastāstīt, kā sagaidījām un pavadījām Jāņu dienu. Bija mums ugunskurs uz zemes un kārts galā. Visiem Jāņiem bija kroņi galvā, līgojam vēlu pāri pusnaktij, bet saulītes uzlecot neredzējām, nebija ar ko stiprināties, lai redzētu, kā saulīte rotājas, kādēļ aizgājām gulēt. Jāņu dienā Jāņi uzstājās ar torti un kafiju – bija tīri smalki!
                Citādi dieniņas iet savu gājumu un mūsu rītdiena nav gudrāka par vakardienu! Mūsu Stockholmieši raksta, ka viņi pieliekot visas pūles, lai mūs atšķirtu no vāciešiem, labāk gan būtu, ka gādātu mūsu atbrīvošanu no šī gūsta. Pēc ziņām igauņu laikrakstā, baltiešu leģionāri angļu – amerikāņu okupētā sonā gandrīz visi jau atbrīvoti un daļa no tiem jau pieņemti dienestā – kārtības uzturēšanai vācu pilsētās. Pēdējais gan nava pateicīgs darbs. Kāds prieks gan ar svešām tautām plēsties – kaut tie ar būtu vācieši. Pie mums aptauja par mājās braukšanu nav bijusi un nava šeit arī ko jautāt, tiklīdz tur 
                `,
                `nebūs vaņkas, tad kaut peldus pāri jūrai, citādi, lai šauj tepat uz vietas! 
                Par savu ģimeni esmu klusās asaras jau izraudājis un vakarā, kad atgūlies gultā, visādi izdomājies, līdzēt nekā nevar, bet ja iedomājas cik daudz tavām bēdām līdzīgu ir nesis šis nevajadzīgais karš, tad beidzot var arī uz rīta pusi iemigt. Jaunākiem gados šīs bēdas ir vieglāki pārlaist, tiem kuriem nav bērnu, sievu un vīru dabūt nav nekāda māksla, bet kā lai bērns atrod māti vai tēvu, ja tie zuduši!
                Manā iepriekšējā vēstulē minētais ltn. Zālītis ir bijis pagājušajā gadā Vaiņodē – artilērijas pulkā. 
                Citu jaunumu pie mums nava, izņemot to, ka pēdējā laikā dažādos laikos un locījumos tiek spriests par sievietēm, kuras gan redzamas avīzēs un uz puskilometra attāla ceļa dažureizi, bet par to nevajaga ļaunoties, ja nava darba, tad dara muļķības.
                Sveiciniet mīļo Mārīti un rakstiet lūdzu ja dzirdams, kas jauns – Jūs esiet apskaužama brīvībā un darbā!\n
                Jūsu Edvīns Alksnis
                `,
                `God. Vītoliņa kundze!\n
                Atkal jau viena nedēļa aizskrējusi no mūsu mazās dzīvītes. Nekādas pārmaiņas, vakardiena tāda pati kā šodiena. Pagājušā nedēļā gan bija mums ciemiņi. Dzied. Vintere ar māc. Tēriņu, kuriem vēl līdzi mūs apciemot bija atbraukuši doc. Bubenko un Imkas pr-ks inž. Poriņš.
                Bija koncerts un pēc tam kino izrāde, kas deva tiešām vienmuļībā prieku. Svētdienā atkal bija ciemiņi, proti baptistu māc. Lamberts ar savas ģimenes dāmām, kuras dievkalpojumu kuplināja ar dziesmām. Kopīgās vakariņās pēc ilga laika atkal dabūjām no sirds ar sieviešiem paamizēties, bet, kad tas viss garām, tad nākošā diena vēl drūmāka liekas, jo šie vēji no ārienes saviļņo mūsu “aizmirsto ciemu” un aizejot mūsos paliek vēl lielāka sāpe pēc brīvības.
                Par ārā tikšanu pagaidām nav ko sapņot, mēs paliekam šeit uz ziemas guļu, bet viens labums gan liekās būs tas, ka tiksim prom no vāciešiem kādā vasarnīcā šejienes pilsētas nomalē. Gaidām arī pie
                `,
                `mums pārbraucam Gotlandiešus, tad būsim lopā visi šejienes karavīri. Starp viņiem ir plkvltn. Gailītis, kpt. Apkalns. Priecājos, ka esiet atraduši savu brāli. Tas Upsalas Tīdemanis gan laikam nebūs no armijas, bet gan kāds vācu laikā izceptais policijas “načaļņiks”, jo tādu neviens nepazīst un Grāveļa adjutants jau palika Kurzemē, tā stāsta mūsu leitn., kas 8. maijā izlidoja lidmašīnā kā ievainotais no Ventspils slimnīcas. 
                Gribu lūgt no savas puses palīdzēt kaut ko nosūtīt mūsu ltn. Presņikova sievai uz Lībeku, jo viņai šī mēneša sākumā bija priecīgais notikums – mantinieks, tikai vēl nezinam vai zēns vai meitene. Viņa ir viena tur bez paziņām, bet kpt. Z. bērni jau ir lielāki un madama atrodās ģen. Izp. ģimeņu vidū, un kopā ar saviem ģimenes piederīgiem. Ltn. Presņikovs lūdza Pal. Kom., bet no turienes nekādas atbildes nav, izņemot ka ziņoja, esot aizsūtījusi viņa vēstuli. Viņš iekrājis no savas sausās karavīru devas un būtu ļoti priecīgs, ja varētu tos nosūtīt Jums un Jūs tad tālāki. Kā sieviete un māte nākiet ar padomu, vai nevajadzētu kaut ko no bērna drēbītēm – flaneļa. Viņš varētu operēt apm. 20 kr. apmērā. Lūdzu rakstiet kā tehniski tas būtu kārtojams.
                Ar sveicieniem Jums un Mārītei\n
                Jūsu Alksnis.
                `
            ],
            quotes: [
                `“Lai cik labi būtu internēto dzīves apstākļi lāģerī, tad dzeloņa drātis visapkārt nomāc dvēseli un rada sajūtas, ka laikam nekad nebūsi tāds cilvēks, kā tie, kas ārpus žoga”

            Zviedrijā internētā leitnanta Edvīna Alkšņa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 15. jūnijs
            
            Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
                `“Bija mums ugunskurs uz zemes un kārts galā. Visiem Jāņiem bija kroņi galvā, līgojām vēlu pāri pusnaktij, bet saulītes uzlecot neredzējām, nebija, ar ko stiprināties, lai redzētu, kā saulīte rotājās, kā dēļ aizgājām gulēt. Jāņu dienā Jāņi uzstājās ar torti un kafiju  - bija tīri smalki!”

                Zviedrijā internētā leitnanta Edvīna Alkšņa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 7. jūlijs 
                
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
                `“Par ārā tikšanu pagaidām nav ko sapņot, mēs paliekam šeit uz ziemas guļu, bet viens labums gan liekās būs tas, ka tiksim prom no vāciešiem, kādā vasarnīcā šejienes pilsētas nomalē. Gaidam arī pie mums pārbraucam Gotlandiešus, tad būsim kopā visi šejienes karavīri”

                Zviedrijā internētā leitnanta Edvīna Alkšņa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 3. oktobris
                
                Avots: Latvijas Kara muzejs`,
                ``,
                `“Dzīve lēģerī stāv zem dzeloņdrāšu zīmes, trūkst vēl tikai jakts suņi. Esam gan no ģermāņiem atdalīti un dzīvojam atsevišķi. Nav jau slikti, bet putns nonīkst arī  zelta būrītī. [..] Kas mums trūkst? Brīvība, maigums, privātas drēbes un zāles pret pārdrošiem sapņiem” 

                Zviedrijā internētā karavīra Ulda Bērza vēstule Martai Zīvertei. Ēkses - Rennesletes nometne, 1945. gada 9. novembris 
                
                Avots: Latvijas Kara muzejs`,
                ``,
            ],
            audio: [
                'Alksnis1.mp3',
                'Alksnis1.mp3',
                'Alksnis1.mp3',
                'Alksnis1.mp3',
                'Alksnis2.mp3',
                'Alksnis2.mp3',
                'Alksnis2.mp3',
                'Alksnis3.mp3',
                'Alksnis3.mp3',
            ]
        },
        {
            index: 40,
            type: 'letter',
            image: '29.jpg',
            path: 'Content/images/',
            text: `Kareivis, dzimis Rīgā. 1943. gadā latviešu jaunatnes pretestības organizācijas “Nacionālā sardze” darbinieks. Pēc izdošanas strādājis par dizaineri rūpnīcā “Sarkanā zvaigzne” un Dailes teātrī, studējis arhitektūru `,
            title: 'Uldis Bērzs',
            years: '(1925 - 1981)',
            letterIndex: 35,
            letters: [
                '35-1.jpg',
                '35-2.jpg'
            ],
            transcripts: [
                `Ja Zviedrijā esmu kaut ko mācījies, tad tā ir gaidīšana. Vispār gaidu jau pusgadu, un pusi no šī laika uz Jūsu atbildi manai vēstulei. Var jau būt, ka bargie cenzori neļāva tai sasniegt mērķi. Var jau būt, ka mana vēstule noklīda adreses maiņas dēļ. Jeb varbūt antipatija pret leģionāriem pieņēmusi tik masveidīgu raksturu, ka, varbūt, zināmas aprindas baidās savus pirmdzimtos ar leģionāra vārdu.
                Un tomēr tik labprāt saņemtu kādu vēstuli, jo, hm, jātop sentimentālam, nav taču neviena, kas man rakstītu. 
                Dzīve lēģerī stāv zem dzeloņdrāšu zīmes, trūkst vēl tikai jakts suņi. Esam gan no ģermāņiem atdalīti un dzīvojam atsevišķi. Nav jau slikti, bet putns nonīkst arī zelta būrītī.
                Pats sirdīgi nodarbojos ar zīmēšanu un tā lēnām kaļos franču mēlē. Šo to rakstu par labiem un sliktiem laikiem. Vajag kādu nodarbību ar ko klusināt tukšumu, citādi var galīgi sanīkt`,
                `Kas mums trūkst?
                Brīvība, maigums, privātas drēbes un zāles pret pārdrošiem sapņiem. Ko gan lai mēs sakām, ja jau kāds vīrs no ārienes mums teica: paēduši un apģērbušies jau esam liku likām, bet nav tā kā vajag. Arī viņam cilvēkam grūti.
                Tāda īstena note, varbūt pie gadījuma varat līdzēt, ir pēc franču mācības grāmatas, sevišķi gramtikas. Pagaidām mans mācības līdzeklis ir burtnīcu romāneļi no serijas Le livre de demain.
                Tā ir lēģerī.
                Atrakstiet, kā izskatās tur ārā, ko domā ārpus dzeloņdrātīm. Izsakiet arī savas personīgās domas. Katrā gadījumā atrakstiet, jo galu galā es taču vienmēr aizvēru logu, lai Jūs nesaaukstētos.\nAr patiesu cieņu\nVotre elève\nUldis Bērzs.\nInterneringslägret Ränneslätt – Staby\növ. Forsvarstaben avd. It.\nStockholm 90.\nUB.
                `
            ],
            quotes: [
                `“Dzīve lēģerī stāv zem dzeloņdrāšu zīmes, trūkst vēl tikai jakts suņi. Esam gan no ģermāņiem atdalīti un dzīvojam atsevišķi. Nav jau slikti, bet putns nonīkst arī  zelta būrītī. [..] Kas mums trūkst? Brīvība, maigums, privātas drēbes un zāles pret pārdrošiem sapņiem” 

                Zviedrijā internētā karavīra Ulda Bērza vēstule Martai Zīvertei. Ēkses - Rennesletes nometne, 1945. gada 9. novembris 
                
                Avots: Latvijas Kara muzejs`,
                ``
            ],
            audio: [
                'Berzs1.mp3',
                'Berzs1.mp3'
            ]
        },
        {
            index: 41,
            type: 'letter',
            image: '30.jpg',
            path: 'Content/images/',
            text: `Dzimis Petrogradā (Krievijā), leitnants. 1944. gada 18. septembrī ieskaitīts Latviešu leģionā. Pēc izdošanas dzīvoja Rīgā, 1949. gadā beidza Valsts konservatoriju, pianists, sāka strādāt Cēsu mūzikas skolā, 50. gados strādāja par mūzikas skolotāju Emīla Dārziņa mūzikas skolā Rīgā. Vēlāk dzīvoja Strenčos un strādāja par pedagogu mūzikas skolā. Viņa ģimene palika Dānijā`,
            title: 'Jānis Presņikovs',
            years: '(1917-1989)',
            letterIndex: 36,
            letters: [
                '36-1.jpg',
                '36-2.jpg',
                '36-3.jpg',
                '36-4.jpg',
                '37-1.jpg',
                '37-2.jpg',
                '37-3.jpg',
                '37-4.jpg',
                '38-1.jpg',
                '38-2.jpg',
                '38-3.jpg',
                '38-4.jpg'
            ],
            transcripts: [
                `Cienītā Vītoliņa kundze!\n
                Piedodiet, ka esmu iedrošinājies Jūs traucēt. Šodien Alkšņa kgs saņēma Jūsu vēstuli un izteica Jūsu laipnību palīdzēt manai ģimenei.
                Oktobra sākumā saņēmu vēstuli (rakstīta sept.) no sievas, ka 6. aug. laimīgi dzimusi meitiņa. Viņa raksta, ka ar līdzekļiem kaut kā pagaidām varot iztikt, tikai grūtāk būšot ziemā. Pats varu šo to atlicināt no pārtikas produktiem, tāpat arī drusku ir nauda, par ko varētu šo to nopirkt un nosūtīt ģimenei,
                `,
                `jo labi zinu, ka sievas rīcībā ir maz līdzekļu un daudz kas meitiņai derētu.
                Vītoliņa kundze, man jāatzīstas, ka ļoti maz zinu, kas pašlaik būtu ģimenei derīgāks kā no produktiem, tā no drēbītēm pūriņam. Jūs jau to ļoti labi ziniet, kas pašlaik būtu visvajadzīgākais. Ja Jums tas neprasītu pārāk pūļu, būtu pateicīgs, ja Jūs sagatavotu paciņu un nosūtītu sievai, jo šeit nometnē kaut ko sagādāt nav iespējams. Sūtīšanai kāda nozīmīga lietiņa man pašlaik nava. Tos dažus produktus, ko esmu iekrājis, aizsūtīšu Jums vēlāk, kad Pal. Komiteja būs devusi atbildi, tāpat arī naudu. Domāju, ka labvēlīgas atbildes gadījumā varēs regulāri sūtīt.
                `,
                `Iesniedzu 12. okt. sievai vīzas lūgumu. Kāds varētu būt rezultāts, to pašlaik nezinu. Kad Bubenko kgs bija nometnē, tad viņš teica, lai nekavējoties un iesniedzot. Esmu pārliecināts, ja sieva varētu šeit nokļūt, tad dzīves apstākļi viņai būtu noteikti labāki un vieglāki.
                Septembrī, kad parādījās sludinājums avīzē par paciņu sūtīšanu, tad tūlīt aizrakstīju Pal. Kom., vai viņi nevarētu kaut ko nosūtīt par maniem līdzekļiem. Drīz būs mēnesis, bet atbildes vēl šobrīd nav un domāju, ka arī nebūs.
                Vītoliņa kundze, zinu, ka sagādāju Jums daudz pūļu. Būšu Jums ļoti pateicīgs, jo ģimene Vācijā būs laimīga kaut ko saņemot.
                Eksjö, 1945. g. 16. okt.\n Jūs cienot\nJānis Presņikovs`,
                `
                P.S. Alkšņa kgs sveicina Jūs un rakstīs vēstuli.\n
                Sievas adrese Vācijā ir:\n
                    Havetoftloit über Schleswig\n
                bei H. Hans Jessen\n
                    Elza Presņikovs.\n
                Tā kā mēs pāriesim tikai latvieši uz nometni kādu kilometru no šīs nometnes, tad labāk mums rakstot uz vēstules būtu atzīmēt lettisk. Adrese paliek vecā.
                `,
                `Cienītā Vītoliņa kdze!\n 
                Sirsnīgs paldies par Jūsu pūlēm. Esmu ļoti priecīgs, ka Jums ir izdevies nosūtīt paciņas. Zinu, ka ģimene būs pateicīga saņemot šos sūtījumus. Nosūtīto paciņu saturs būs ļoti noderīgs. Esmu sievai rakstījis, lai raksta, kas būtu visvajadzīgākais, bet pašlaik vēstules neesmu saņēmis. Tāpēc gribu lūgt, ja būs kāda izdevība sūtīt, tad lūdzu sūtiet to, ko ir iespējams. Ja sieva tagad atrakstīs, tad jau Jums aizrakstīšu, ko viņa visvairāk vēlās. 
                No Pal. Komitejas atbildi vēl neesmu saņēmis un tagad, kad jau vairāk kā`,
                `mēnesis pagājis, neceru kādu atbildi saņemt. 
                Zivju konservus, domāju, būtu labi sūtīt, jo viņi taču ir eļļā gatavoti. Tāpat ziepes pašlaik ir vajadzīgas. Nosūtu Jums vienu pārtikas kartiņu un nosūtīšu dažus ziepju gabalus un gaļas konservus, kas ir trekni un saturīgi, kurus mums deva sausajā uztura devā.
                Vītoliņa kdze, segu un drēbītes vēl nevajadzētu sūtīt, iekams neesmu saņēmis vīzas lūguma atbildi. Nezinu, kāda varētu būt atbilde, bet ja izdotos dabūt vīzu, tad jau viss būtu sagatavots.
                Lūdzu pasakiet man visus izdevumus, jo pašlaik esmu šo to sev sagādājis un savu mēneša algu man nav nekāda vajadzība pašam notērēt.
                `,
                `Lūdzu pateicaties Gaišais kgam un pasakiet, ka esmu ļoti pateicīgs un negribu viņa labo sirdi atraidīt, bet no otras puses negribu viņa labo sirdi atraidīt, bet no otras puses negribu arī sagādāt viņam liekus izdevumus.
                Esam tagad atsevišķā nometnē atdalīti no vāciešiem. Dzīvojam savu parasti nometnes dzīvi un šeit tā būs labāka un mierīgāka, nekā kad bijām kopā. Par mūsu stāvokli esam daudz ko dzirdējuši un vēl daudz ko dzirdēsim. Daudz palīdzības ir solījuši mācītāji, un jebkurš, kas parādās šeit nometnē, bet tie paliekt tikai solījumi.
                Esam arī jau ar visu samierinājušies, jo galu galā cilvēkiem ir vēl lielākas rūpes.
                Ar Jūsu vīra kgu pēdējo reizi tikos pie Dancigas un tāpat kā Jūs tagad,
                `,
                ` arī es domāju par savu brāli, bet pēkšņi nupat saņēmu ziņu, ka viņš laimīgi ticis cauri līdz angļu zonai. Pieci mēn. vēl nav pārāk ilgs laiks, lai sazinātos, un vienmēr vēl pienāk ziņas par tuviniekiem. 
                Vēlreiz sirsnīgs paldies un cerēsim, ka paciņas arī nonāks pareizās rokās!\n
                Eksjö, 29. okt. 1945.\n
                Jūs cienot\n
                J. Presņikovs\n\n
                
                Alkšņa kgs sūta sveicienus.\n\n
                
                Mūsu adrese tagad: Interner. lägret\n
                Ränneslätt – Staby\n
                över Forsvst. avd. It.\n
                                Sthlm 90.\n
                `,
                `Cienītā Vītoliņa kundze!\n\n
                Atļaujiet apsveikt Jūs mūsu valsts dibināšanas 27. gada dienā. 
                Paldies par licenču veidlapām. Tās iedevu Ziemeļa kgm un vēl dažiem kolēģiem, kā arī pats izpildīju un nosūtīju. Ka pasta satiksme ar Vāciju ir pasliktinājusies, to jau agrāk dzirdēju un ja nu tā tagad noritēs caur Sarkano Krustu, tad sarakstīšanās pagaidām būs garāka.
                Kas attiecas uz apstākļiem nometnē, tad droši vien Jūs domājiet mūsu savstarpējās attiecības. Varu teikt, ka bija maza pašu veļas mazgāšana, par ko vēstulē negribētu rakstīt, bet to varētu pie izdevības tiekoties pastāstīt.
                `,
                `Un galu galā tie jau arī tikai ir sīkumi, kad prātu nodarbina daudz lielākas lietas. Vispār mēs latvieši esam aizgājuši tālu prom no realitātes. Tā tas ir ar dažiem Stockholmas kgiem, tā, cik noprotu, ir ar dažiem Burosā, par kuriem Jūs rakstiet. Bet vai tad nu personīgās ķildas šeit nebūtu jāatmet? Un to taisni daži kgi negrib saprast, kam pamatā ir nevis gudrība, bet gan nesaprātība.
                Par savām tālākām gaitām vienmēr ar interesi gaidām ziņas, bet kā jau Jūs rakstiet, tad par to lems sabiedrotie. 
                Par manu paciņas pieprasījumu kundze lūdzu neinteresējaties, jo 2 pac. Ir aizsūtītas un ar licencēm arī būs jau vēl kāda izdevība aizsūtīt. 
                `,
                `Gribētu Jūs lūgt, ja rodas izdevība, apvaicāties par vīzu sievai. Saņēmu Pal. Komitejas atbildi 26. X., ka manu vīzas lūgumu papildinājuši un iesniegšot, jo dažus punktus es atstāju neizpildītus. Bet tam jau laika vēl diezgan, jo atbildes varot ilgt no 1 – 2 mēn. Nevaru arī pašlaik iedomāties, kādu atbildi varētu saņemt.
                Gatavojamies svinēt nometnē 18. nov. Esam sagatavojuši mazu vakara programmu. Avīzē lasījām, ka apciemos mūs māc. Tēriņš. Kā jau pēdējos gados, tā arī šogad vienkāršos apstākļos nosvinēsim šo dienu.
                Esam visi pie labas veselības. Arī Krūmiņa kgs. jūtas samērā labi. Šogad rudens nav patīkams, un tas sevišķi atsaucas slikti bērnu veselībai.
                `,
                `Sirsnīgi sveicieni no mums visiem!\n\n

                Eksjö, 1945. g. 16. nov.\n
                Jūs cienot\n
                J. Presņikovs\n
                `
            ],
            quotes: [
                `“Oktobra sākumā saņēmu vēstuli (rakstīta sept.) no sievas, ka 6. aug. laimīgi dzimusi meitiņa. Viņa raksta, ka ar līdzekļiem kaut kā pagaidām varot iztikt, tikai grūtāk būšot ziemā. Pats varu šo to atlicināt no pārtikas produktiem, tāpat arī drusku ir nauda, par ko varētu šo to nopirkt un nosūtīt ģimenei” 

                Zviedrijā internētā leitnanta Jāņa Presņikova vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 16. oktobris 
                
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
                `“Esam tagad atsevišķā nometnē atdalīti no vāciešiem. Dzīvojam savu parasto nometnes dzīvi un šeit tā būs labāka un mierīgāka, nekā kad bijām kopā. Par mūsu stāvokli esam daudz ko dzirdējuši un vēl daudz ko dzirdēsim. (..) Esam jau ar visu samierinājušies, jo galu galā cilvēkiem ir vēl lielākas rūpes”

                Zviedrijā internētā leitnanta Jāņa Presņikova vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 29. oktobris 
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
                `“Gatavojamies svinēt nometnē 18. nov. Esam sagatavojuši mazu vakara programmu.  Avīzē lasījām, ka apciemos mūs māc. Tēriņš. Kā jau pēdējos gados, tā arī šogad vienkāršos apstākļos nosvinēsim šo dienu.
                Esam visi pie labas veselības” 
                
                Zviedrijā internētā leitnanta Jāņa Presņikova vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 16. novembris 
                
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
            ],
            audio: [
                'Presnikovs1.mp3',
                'Presnikovs1.mp3',
                'Presnikovs1.mp3',
                'Presnikovs1.mp3',
                'Presnikovs2.mp3',
                'Presnikovs2.mp3',
                'Presnikovs2.mp3',
                'Presnikovs2.mp3',
                'Presnikovs3.mp3',
                'Presnikovs3.mp3',
                'Presnikovs3.mp3',
                'Presnikovs3.mp3',
            ]
        },
        {
            index: 42,
            type: 'letter',
            image: '31.jpg',
            path: 'Content/images/',
            text: `Dzimis Sarkanmuižas pagastā, miris Ventspilī. 1927. gadā beidza Kara skolu, 1939. gadā – Augstāko kara skolu, Latvijas armijas kapteinis. 1940. - 1941. gadā dienēja Sarkanās armijas 24. teritoriālajā strēlnieku korpusā, bija 639. artilērijas pulka I baterijas komandieris. 1943.-1945. gadā bija Latviešu SS brīvprātīgo leģiona 15. grenadieru divīzijas artilērijas pulka IV baterijas komandieris.  Apbalvots ar Dzelzs krusta II šķiru. 1945. gada maijā internēts Zviedrijā. Pēc izdošanas atbrīvots un uz dzīvi apmeties Ventspilī. 1950. gada 14. jūnijā padomju drošības orgāni V. Ziemeli arestēja un sodīja. Pēc atgriešanās no soda izciešanas labošana darbu nometnē dzīvoja un strādāja Ventspilī pilsētas rūpkombinātā par strādnieku. Viņa ģimene palika Vācijā`,
            title: 'Vilis Ziemelis',
            years: '(1903-1970)',
            letterIndex: 39,
            letters: [
                '39-1.jpg',
                '40-1.jpg',
                '40-2.jpg',
                '40-3.jpg',
                '40-4.jpg',
                '41-1.jpg',
                '41-2.jpg',
                '41-3.jpg',
                '41-4.jpg',
                '42-1.jpg',
                '42-2.jpg',
                '42-3.jpg',
            ],
            transcripts: [
                `Sirsnīgs sveiciens!\n\n
                Tikko nonācām visi jaunā nometnē, tuvāki Kalmārai un tāpēc steidzos Jums paziņot mūsu jauno adresi.
                Esmu Jums norakstījis jau 2 vēstules – ceru, ka būsiet saņēmuši.
                Citādi viss pavecam  - nekādu jaunu ziņu nav. Ar interesi sekojam presei, kā viss tas pasaulē notiek.\n
                Ar sirsnīgiem sveicieniem arī no Jūsu paziņām Krūmiņa un Alkšņa.
                Kpt. Ziemelis\n
                Interniringslägret\n
                Ränneslätt / Eksjö
                `,
                `Lg. kundze!\n\n

                Rakstu šoreiz latviski, jo nācu pie slēdziena, ka visas vēstules tāpat iet ļoti ilgi. Jūsu vēstules saņēmu tuvā secībā, tāpat arī avīzes, par ko mīļš paldies; pēc izlasīšanas atsūtīsim atpakaļ. Kas attiecas uz Adolfu un Jūsu piederīgo meklēšanu, tad tiešām, tagad, kamēr uz Vāciju nav normālas pasta satiksmes, lieta ļoti grūta.
                Par “Brösen”, cerams, ka viņš jau Dancigā nepalika, bet aizbrauca uz kādu Vācijas ostu. Daži izsakas, kā dzirdējuši baumas, it kā kāda bumba esot to Dancigas ostā trāpījusi. Neviens neko noteiktu nezina.
                Par pltn. B. mantām, es domāju, ka variet sākt
                `,
                `tās lietot, jo kas zina, vai viņam kādreiz tās vairs būs vajadzīgas – par viņa ģimeni arī nekas nav zināms!?
                Mēs te dzīvojam un esam ar visu tīri apraduši – drāšu žogs un sargi apkārt. Ārā tiekam, ja ejam darbos – meža kokus lauzt un ceļus būvēt. Mums tiešām vēl nekā netrūkst, arī kabatas naudu saņemam kārtīgi un šeit ir kantine, kur visu var nopirkt, kas nepieciešams. Ir bibliotēka. Ēdiens labs, lasām avīzes un klausāmies radio. Tomēr gaidam, kad tiksim ārā pie kārtīga privāta darba, kurš tad būs pašiem jāmeklē. Tas būs grūtāk, ja nav kāda amata. Mana ģimene – sieva un 2 bērni ir kaut kur Vācijā, bet adresi nezinu. Bet gan jau viņus kādreiz sameklēs. Esmu Jums ļoti 
                `,
                `pateicīgs par manu radu U.S.A. adresi – varbūt vēlāk noderēs! Kā rakstāt, tad jau Jums samērā daudz brīva laika un visumā nekas. Vai Jums nebūtu iespējams ar c. Morgenšteina kdzi noskaidrot attiecībā uz Brākmaņu un Kantsonu ģimenēm:
                1)	Vai noteikti zināms, kā viņi uzkāpa Ventspilī uz kuģa, lai brauktu uz Zviedriju?\n
                2)	Vai ir zināms, ka šīs ģimenes kaut kur izkāpušas malā Zviedrijā? Kur?\n
                Esmu rakstījis uz Stockholmu, bet nekādu atbildi neesmu saņēmis. Jūsu vīram tika uz rokām izmaksāta neliela daļa algas, bet ģimenei pienācās katru mēnesi RM 430.- kas vai nu vēl guļ centrālē Prāgā, vai tanī bankā, kur Adolfs bija licis iemaksāt. Šī iestāde saucās: Der Fürsorgeoffizier der Waffen SS “Ostland” un beidz. atrašanās vieta bija Prāga. Kā to naudu dabūt un kur viņu likt?
                `,
                `Paldies par tabakas kartiņu piedāvāšanu, bet mēs saņemam šīs mantas pietiekoši un bez tam esmu nesmēķētājs. Par to braukšanu vēl labi jāapsver, vai atmaksājas, jo saruna var ilgt apm. 10 min., vācu valodā, priekšniecības klātbūtnē. Jābrauc līdz stac. Eksjö, no turienes apm. 2 km kājām uz Ränneslätt. Ceļa garums apm. 200 km no Kalmāras. Ceru, ka Eksjö var pārgulēt. Kā iet vilcieni turp un atpakaļ – mēs nezinām.
                Cik zināms, cik šeit daži apmeklētāji bijuši, tad komendants tos vienmēr ir pielaidis pie runāšanas. Markas atsūtu atpakaļ, jo mēs rakstām bez markām. Nevienam Vācijā nekāds ļaunums necēlās, ja viņa ģimene bija kur citur. 
                Jā, ilgas pēc dzimtenes – pazīstu tās, visgrūtāk tās nomāc, ja esi pirmo reizi svešatnē…
                Vēlāk tās neatkārtojas tik spilgti. Mūsu vienīgais mierinājums – cerība reiz ieraudzīt dzimtenes krastus. 
                Visu labu Jums novēlot\n
                ar sveicieniem kpt.\n V. Ziemelis.
                `,
                `Ag. Vītoliņa kundze!\n\n
                Sirsnīgi pateicos par Jūsu kartiņu, kura mani darīja patiesi priecīgu, event. Jūsu  atsaucība un rūpēšanās manā lietā. Tiešām esmu saņēmis no savas ģimenes ziņas – viņa dzīvo starp Libeku un Hamburgu, ap 20 km uz Lībekas pusi – Bergedorfā. Esot apmierināti, tas gan dzīvojot latviešu nometnē, bet agrāk dzīvojuši Lībekā. Šinīs dienās gaidu vēl kādu vēstuli, tad zināšu kaut ko noteiktāku par viņu gaitām un likteni beidzamā laikā. 
                Liels paldies par kuponu piesolīšanu – ir jau par vēlu un esmu šo lietu mēģinājis citādi kārtot. Tā kā mums kuponu nebij, tad es aizsūtīju Pal. Kom. naudu un lūdzu nosūtīt manai ģimenei paciņu, kurā būtu ielikti zivju konservi un vēl šo,
                `,
                `ja viņiem paliktu pāri. Ceru, ka komit. kungi, iespējamības robežās manu lūgumu ievēros un paciņu nosūtīs.
                Labprāt Jums uzrakstīšu manas sievas adresi, varbūt viņa Jums var būt noderīga kādā citā lietā, varbūt kādas Jūsu paziņas dzīvo tanī pašā nometnē. Ja tas interesētu, tad lūdzu viņai aizrakstīt. 
                Līdz šim par savām ģimenēm Vācijā ir ziņas 3 mūsu virsniekiem un kādiem 2 kareivjiem. Tās gan nedzīvo Lībekas tuvumā. (vltn. Eltermanim – Bavārijā,ltn. Presņikovam – pie dāņu robežas.)
                Par mūsu dzīvīti Jums jau būs rakstījis ltn. Alksnis. Pagaidām nekāda jaunuma nav. Taisāmies pārcelties šeit netāļu uz jaunu latvisku nometni, kur arī būs patīkamāki ziemu pavadīt. Dzīvi sekojam konferencēm un notikumiem pasaulē, par cik tie atstās iespaidu uz latviešu lietām. Un ceram! 
                `,
                `Ja apstākļi drusku noskaidrotos, esmu domājis arī par ģimenes izprasīšanu uz šejieni, lai gan beidzamā laikā vīzas esot grūtāk dabūt. Bet mūsu stāvoklis arī vēl nav ne šāds, ne tāds, un es maz varētu sievu pabalstīt.
                Ir zināms, ka Jums tagad nākas dzīvot starp nenacionāliem latviešiem. Ko lai dara, ļautiņi mēģina savu dezertēšanu, izvairīšanos no iesaukšanas, vai citus sodāmus grēciņus kautkādi nostādīt uz “tiesiskiem” pamatiem – lai apmierinātu savu sirdsapziņu! Labi, kā mēs to zinām. Lai jau izrunājas, bet lai neaizmirst vienu, ka ikvienam un ļoti daudziem būs sava atbildība jādod! Savā laikā un vietā! Todsicher! Kaut vai dzīves dzelzs likuma dēļ…
                Vēlreiz manu sirsnīgu latvisku paldies par Jūsu gatavību izpalīdzēt. Lai Jums arvienu labi klājas!
                Ar sveicieniem
                V. Ziemelis
                
                N.B. Jūsu avīzītes nosūtīju jau jūnijā uz Kalmaru, cerams, būsiet saņēmuši.
                `,
                `Ag. Vītoliņa kundze!
                Jūsu kartiņu saņēmu un par Jūsu nodomātu rīcību event. paciņas sūtīšanā manai ģimenei ļoti pateicos, lai gan negribēju Jūs nekādā ziņā šinī lietā Jūs apgrūtināt. Ja Jums izdotos paciņu aizsūtīt, lūdzu man atrakstīt, lai es iespētu Jums atlīdzināt, jo negribu nekā par velti. Tikai liekas, jo neko par to vairs nedzird, arī avīzē neraksta. Varbūt sūtīšanas iespējas ir samazinājušās.
                Mēs tagad esam jaunā latviešu nometnē, kādu 1,5 km no vecās nometnes, kur bijām kopā ar vāciešiem. Tagad nākuši daži latvieši
                `,
                `klāt un esam novietoti atsevišķi. Nometnes vecākais tagad ir pltn. Gailītis. (jurists)
                Tas interesantākais ir, ka vakar saņēmām sūtījumu latvju karavīriem no Boras latviešiem – 3 lielas pakas (papirosus, šokolādes, zeķes u.t.t) Sadalījām katram karavīram pa lozei, kuras uz 11. nov. izlozēsim. Cerēsim, ka katram šis tas labi noderēs, rakstīsim pateicību. 
                Gandrīz nevaram saprast, it kā runā vienu, bet dara ko citu. Vai Gaišais kgs. nav Zaļums? Un kas ir Jaunkalns, vai bij. artil. kpt.?
                Par Tenes kga draudzību jāsaka, ka tāds ar tādu satiekas, jo šis kungs pie mums ir svaidīgs un sevišķi netop cienīts, lai gan, liekās, par viņa nacionālo nostāju šaubu nebūs. Bet visam tam būs pavisam cits iemesls…
                `,
                `Šis tas mūsu nometnē ir uzlabojies – arī pārtika un dzīvošanas apstākļi.\n
                No savas ģimenes beidzamā laikā neesmu vēstules saņēmis. Gaidu katru dienu.\n
                Ir dažas pazīmes lielā politikā, kas neko labu nestāsta.\n
                Ir jāiet uz stāšanos. Tādēļ, pagaidām, paliekiet sveicināta.\n
                V. Ziemelis.
                `
            ],
            quotes: [
                `“Tikko nonācām visi jaunā nometnē”

                Zviedrijā internētā kapteiņa Viļa Ziemeļa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 1. jūnijs 
                
                Avots: Latvijas Kara muzejs`,
                `“Mūsu vienīgais mierinājums – cerība reiz ieraudzīt Dzimtenes krastus”

                Zviedrijā internētā kapteiņa Viļa Ziemeļa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 14. jūnijs
                
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
                `“Par mūsu dzīvīti jau būs rakstījis ltn. Alksnis. Pagaidām nekādu jaunumu nav. Taisāmies pārcelties šeit netāļu uz jaunu latvisku nometni, kur arī būs patīkamāki ziemu pavadīt. Dzīvi sekojam konferencēm un notikumiem pasaulē, par cik tie atstās iespaidu uz latviešu lietām. Un ceram!”

                Zviedrijā internētā kapteiņa Viļa Ziemeļa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 28. septembris
                
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
                ``,
                `“Mēs tagad esam jaunā latviešu nometnē, kādi 1,5 km no vecās nometnes, kur bijām kopā ar vāciešiem. Tagad nākuši daži latvieši klāt un esam ievietoti atsevišķi. Nometnes vecākais tagad ir pltn. Gailītis. (jurists)”

                Zviedrijā internētā kapteiņa Viļa Ziemeļa vēstule Līvijai Vītoliņai. Ēkšes - Rennesletes nometne, 1945. gada 25. oktobris
                
                Avots: Latvijas Kara muzejs`,
                ``,
                ``,
            ],
            audio: [
                'Ziemelis1.mp3',
                'Ziemelis2.mp3',
                'Ziemelis2.mp3',
                'Ziemelis2.mp3',
                'Ziemelis2.mp3',
                'Ziemelis3.mp3',
                'Ziemelis3.mp3',
                'Ziemelis3.mp3',
                'Ziemelis3.mp3',
                'Ziemelis4.mp3',
                'Ziemelis4.mp3',
                'Ziemelis4.mp3',
            ]
        }
    ],
    SECTION3: [
        {
            index: 0,
            type: 'title',
            subText: 'Lēmums par izdošanu un izdošana',
            text: 'Pretī nezināmam liktenim'
        },
        {
            index: 1,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“Politikai ir tikai galva, bet nav sirds“`,
                    author: 'Uldis Bērzs, kareivis'
                },
                {
                    index: 1,
                    text: `“Gan izvēles nav, un nākotne liekas maziespējama , tagad tiešām ir – būt vai nebūt! Labi, ja sirds vēl cerības lolo! Varbūt!”`,
                    author: 'Vilis Ziemelis, kapteinis'
                },
                {
                    index: 2,
                    text: `“Palieciet mīļi sveicināti un turiet par mums īkšķus”`,
                    author: 'Jēkabs Balodis, kareivi'
                }
            ],
        },
        {
            index: 2,
            type: 'title',
            text: 'Zviedrijas valdības pieņemtais lēmums par izdošanu'
        },
        {
            index: 3,
            type: 'text',
            text: `PSRS sūtniecība Stokholmā 1945. gada 2. jūnijā verbālnotā pieprasīja Zviedrijas Ārlietu ministrijas nostāju attiecībā uz valstī internētajiem bijušajiem Vācijas bruņoto spēku karavīriem. Lēmumu par karavīru izdošanu Zviedrijas sociāldemokrāta Pēra Albina Hansona  (Per Albin Hansson) vadītā Zviedrijas valdība pieņēma 15. jūnijā. Nākamajā dienā Zviedrija nosūtīja atbildi PSRS, kurā apņēmās izdot bijušos vācu armijas karavīrus, tostarp, latviešus. Tas bija pretrunā starptautiskajām tiesībām un daudzu zviedru gribai. 
            Lēmums par izdošanu palika slepens līdz novembra otrajai pusei, kad par to uzzināja Zviedrijas sabiedrība un internētie karavīri`
        },
        {
            index: 4,
            type: 'text',
            text: `Zviedrijas valdības atbilde uz PSRS 1945. gada 2. jūnija verbālnotu. Stokholma, 1945. gada 16. jūnijs

            “1945. gada 2. jūnija verbālnotā Padomju Sociālistisko Republiku Savienības sūtniecība saskaņā ar norādījumiem izteikusi priekšlikumu izdot Padomju Savienībai visus vācu (un tādus, kas bijuši vācu kontrolē) karavīrus, virsniekus un citas militārpersonas, kas bēgušas no padomju – vācu frontes uz Zviedriju pēc Vācijas kapitulācijas akta parakstīšanas.
            Atbildot uz to, Karaliskā Ārlietu ministrija paziņo, ka zviedru valdība ir gatava atļaut vācu vai vāciešu kontrolē bijušajām militārpersonām, kuras bēgušas uz Zviedriju, atstāt mūsu zemi un aizsardzības štābs saņēmis uzdevumu stāties sakaros ar sūtniecību jautājumos, kas skar šo personu aizbraukšanu un nodošanu padomju militārajām iestādēm. Turklāt jāpiebilst, ka starp šīm personām ir tādas, kas ieradušās Zviedrijā pirms kapitulācijas akta parakstīšanas.
            Šis paziņojums skaidri sevī ietver arī to – kas sūtniecības notā īpaši jautāts -, ka zviedru valdība negatavojas šīm personām Zviedrijā dot patvērumu.”`
        },
        {
            index: 5,
            type: 'title',
            text: 'Internēto karavīru stāvoklis un reakcija'
        },
        {
            index: 6,
            type: 'text',
            text: `Ēkšes - Rennesletes nometnē internētie baltiešu karavīri par gaidāmo izdošanu uzzināja 21. novembrī. Pēc ziņas saņemšanas viņi uzsāka cīņu pret piespiedu atgriešanu Padomju Savienībā - ar vēstuļu un telegrammu palīdzību sāka meklēt palīdzību ārpasaulē: rakstīja iesniegumus, lūgumus sabiedroto valstu sūtniecībām, sabiedriskām organizācijām, Zviedrijas parlamentam, karalim un tautiešiem Zviedrijā`
        },
        {
            index: 7,
            type: 'title',
            subText: 'Badastreiks'
        },
        {
            index: 8,
            type: 'text',
            text: `Reaģējot uz Zviedrijas valdības lēmumu par bijušo baltiešu karavīru izdošanu Padomju Savienībai, 22. novembrī internētie karavīri sāka beztermiņa badastreiku. 
            Badastreika noteikumi paredzēja:
            Badošanās, kas uzsākama 22. novembrī pulksten 7.00;
            Sevis piesaistīšana pie gultas, iepriekš sadedzinot visas drēbes;
            Pašnāvība.
            Novembra beigās badastreika novājinātos karavīrus sadalīja vairākās grupās un pārveda uz civilajām slimnīcām. Daļa badojās līdz 11. decembrim, daļa badastreiku turpināja līdz pat 1946. gada janvārim. Janvāra sākumā lielāko daļu karavīru no slimnīcām pārvietoja uz internēto nometnēm Geltoftā (Gälltofta) un Rinkabijā (Rinkaby)`
        },
        {
            index: 9,
            type: 'textgallery',
            text: `Badastreika komitejas sēde. Ēkše - Renneslete, 1945. gada 22. novembris 
            Otrais no labās – ārsts – leitnants Elmārs Eihfuss-Atvars`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '43.jpg',
            path: 'Content/images/'
        },
        {
            index: 10,
            type: 'textgallery',
            text: `Internēto baltiešu karavīru badastreika komitejas priekšsēdētājs ārsts – leitnants Elmārs Eihfūss-Atvars. Zviedrija, 1946. gada janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '44.jpg',
            path: 'Content/images/'
        },
        {
            index: 11,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Mūs izdod krievam – pasīva pretestība sākas – badastreiks”

            Kareivja Ulda Bērza vēstule Martai Zīvertei. 1945. gada 22. novembris. Vēstule nosūtīta ar privātpersonu`,
            path: 'Content/images/',
            images: [
                '45.jpg'
            ],
            transcripts: [
                `Lūdzu aizdodiet maķenīt naudas ātri 			
                Mūs izdod krievam – pasīva pretestība sākas.
                Badastreiks.
                    Daudz nebēdājaties.
                                            Uldis Bērzs.
                
                Staby 22.XI.45.
                `,
            ],
            audio: [
                'Berzs2-1.mp3'
            ]
        },
        {
            index: 12,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Cīnos par dzīvību”

            Kareivja Ulda Bērza vēstule Martai Zīvertei. Ēkšes – Rennesletes nometne,  1945. gada 25. novembris`,
            path: 'Content/images/',
            images: [
                '46.jpg'
            ],
            transcripts: [
                `Staby,
                25.11.45.
                
                Cīnos par dzīvību.
                Jūs esat manas mātes māsa.
                Pie gadījuma galvojiet par mani, esmu nepilngadīgs dz. 1925. 2. maijā. Rīgā
                Māte Paulīna dz. 1896. g. febr. šuvēja un tēvs Jānis dz. 1889. dekorators Dailes teātrī palika dzimtenē. Unijas 54-6.
                Varbūt līdz.
                Neļaunojaties.
                
                
                Uldis.
                
                Ja nē – tad līdz galam!`,
            ],
            audio: [
                'Berzs2-2.mp3'
            ]
        },
        {
            index: 13,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Esiet mierīgi, viss darāmais darīts”

            Kareivja Ulda Bērza vēstule Martai Zīvertei. Ēkšes – Rennesletes nometne,  1945. gada 28. novembris`,
            path: 'Content/images/',
            images: [
                '47.jpg'
            ],
            transcripts: [
                `28.11.


                Esiet mierīgi. Viss darāmais darīts.
                Paldies. Galvenais – čeka mūs nedabūs.
                Joprojām raugos vēsi.
                
                Domājiet par mums.
                
                Daudz labdienu un – ceriet tāpat kā mēs.
                
                                                                                           Jūsu
                                                                                                        Uldis
                `,
            ],
            audio: [
                'Berzs3-3.mp3'
            ]
        },
        {
            index: 14,
            type: 'textgallery',
            text: `Virsseržants Augusts Kaņeps bada streika laikā kādā no Zviedrijas slimnīcām. 1945. gada decembris`,
            author: 'Zīmējuma autors: Zviedrijā internētais seržants Elmārs Heics',
            source: 'Avots: Latvijas Kara muzejs',
            image: '48.jpg',
            path: 'Content/images/'
        },
        {
            index: 15,
            type: 'textgallery',
            text: `Latviešu karavīri bada streika  laikā. Ulricehamnas (Ulricehamn)  slimnīca, 1945. gada decembris
            1. no kreisās kareivis Oļģerts Ābrams`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '49.jpg',
            path: 'Content/images/'
        },
        {
            index: 16,
            type: 'textgallery',
            text: `Latviešu karavīri slimnīcā pēc bada streika. Zviedrija, 1945. gada decembris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '50.jpg',
            path: 'Content/images/'
        },
        {
            index: 17,
            type: 'textgallery',
            text: `Latviešu karavīri Enebū lazaretē pēc bada streika. 1946. gada 10. janvāris
            2. rindā no kreisās: ārsts leitnants Jānis Slaidiņš, medmāsa Aina Eriksena, priekšā sanitāre Marija Karlsone, kaprālis Elmārs Heics, kapteinis Ēvalds Grīva, virsnieka vietnieks Teodors Zēbergs`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '51.jpg',
            path: 'Content/images/'
        },
        {
            index: 18,
            type: 'textgallery',
            text: `Internētie latviešu karavīri Ulricehamnas slimnīcā. Zviedrija, 1945. gada decembris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '52.jpg',
            path: 'Content/images/'
        },
        {
            index: 19,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Slimnīcā. Nevaru koncentrēties. Lai tik iegūtais nebūtu lieks. Lai Jūsu palīdzība nebūtu veltīga. Laiks rādīs”

            Kareivja Ulda Bērza vēstule Martai Zīvertei. Kalmara, 1945. gada 1. decembris`,
            path: 'Content/images/',
            images: [
                '53.jpg'
            ],
            transcripts: [
                `Kalmar,
                1.12.45.
                
                Slimnīcā. Nevaru koncentrēties. 
                Lai tik iegūtais nebūtu lieks. 
                Lai Jūsu palīdzība nebūtu veltīga. 
                Laiks rādīs. Nostāja mūsu negrozas. 
                Kas notiek ārā? Rakstiet.
                Viss liekas nožēlojami sīks.
                Kas būs?
                Ko vēl lai saku? Trūkst spraiguma. 
                Viss tukši vārdi.
                Mīļš sveiciens visiem rakstiet.
                                                               Uldis
                `,
            ],
            audio: [
                'Berzs2-4.mp3'
            ]
        },
        {
            index: 20,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Rakstu Jums no slimnīcas, gultā gulēdams. Esam jau ar divu dienu pārtraukumu veselas četras nedēļas sevi vājinājuši, bet gars vēl iekšā turas” 
            
            Kapteiņa Viļa Ziemeļa vēstule Līvijai Vītoliņai Dalsjoforā. Vekše (Växjö), 1945. gada 19. decembris`,
            path: 'Content/images/',
            images: [
                '54-1.jpg',
                '54-2.jpg'
            ],
            transcripts: [
                `19.12.45.
                Slimnīcā.
                
                Ļoti godātā Vītoliņa kundze!
                
                
                Rakstu Jums no slimnīcas, gultā gulēdams. Esam jau ar divu dienu pārtraukumu veselas veselas četras nedēļas sevi vājinājuši, bet gars vēl iekšā turas. Šeit esam ap divdesmit cilvēku, arī Keselis. Krūmiņš un Presņikovs palika uz vietas tanī slimnīcā, kur mēs visu laiku dzīvojām. Kur Alksnis palika, lāgā nezinu, bet laikam netālu no Jums. Esmu kādus 100 kilometrus uz dienvidiem, kur es garāk biju un rakstīju Jums pēdējo vēstuli ar ardievām no dzīves. Šī vēstule būtu jāmēģina (varbūt norakstu) sūtīt tikai tad, kad tiešām kas ļauns ar mani atgadītos, bet ne agrāki. Jūsu vēstuli saņēmu – paldies! Žēl Adolfa, jācer, ka dzīvs viņš vēl ir, jo tad jau karš bija beidzies, kad viņus saņēma…
                To vietu, kur esmu, nevaru paziņot, vēstules jāadresē kā
                `,
                `adresē, tad viņi sadala pa slimnīcām un mēs saņemsim. Cita prieka jau mums nav, esam pilnīgi nogriezti no pasaules, ne avīžu, ne radio, ne apmeklētāju. Neziņa pilnīga bendē nervus. Esam tomēr izsalkuši dzīves par katru cenu, bet melnā nāves ēna negrib zust ap mūsu galvām. Mēs vēl ticam un ceram, un gribam redzēt savus mīļajos un brīvu dzimteni. Vai latviešu tautai vēl būtu jānes arī mūsu asins upuri? Mēs gribam ticēt taisnības un cilvēcības uzvarai, jeb mūsu Dievs mūs būtu atstājis?!? Gan izvēles nav, un izeja liekas maziespējama, tagad tiešām ir – būt vai nebūt. Labi, ja sirds vēl cerības lolo! Varbūt!
                Tagad nāk Ziemassvētki – bēdīgākie manā mūžā. Pagājušos vēl biju savas ģimenes vidū, kas to varēja zināt tad, ko liktenis slēpa, kāds rūgts biķeris būs jādzer šogad. Un visiem latviešiem jau nebūs priecīgi šie svētki un Jaunais gads. 
                No sirds novēlu Jums priecīgā garā svinēt
                Baltos Ziemas svētkus,
                Jūsu V. Ziemelis.
                Civil. försv. 
                Styrelsen
                Stockholm 7.
                f.v.b.
                `
            ],
            audio: [
                'Ziemelis2-1.mp3',
                'Ziemelis2-1.mp3'
            ]
        },
        {
            index: 21,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“.. šādos apstākļos, gaidot savu spriedumu, arī kaut vēstuli rakstīt ir pāri cilvēka spēkiem”

            Leitnanta Jāņa Presņikova vēstule Līvijai Vītoliņai Dalsjoforā. Geltofta, 1946. gada 13. janvāris`,
            path: 'Content/images/',
            images: [
                '55.jpg'
            ],
            transcripts: [
                `Cien. kundze!
                Vēlu Jums laimīgu Jauno gadu!
                Piedodiet, ka neesmu Jums atbildējis, bet Jūs jau sapratīsiet, ka šādos apstākļos, gaidot savu spriedumu, arī kaut vēstuli rakstīt, ir pāri cilvēka spēkiem. Ja jau nu sliktākajam spriedumam jānāk, tad lūdzu aizrakstiet manai sievai (adrese ir tā pati vecā). Esmu arī pats rakstījis un minējis, ka bildītes saņēmu, bet varbūt, ka visas vēstules nesaņem un Jums civili arī tas varbūt vieglāki izdarāms. Sveicieni no kolēģiem. 
                Krūmiņa kgs sveicina.
                                        Ar sveicieniem
                                            J. Presņikovs
                Gälltoftā, 1946. g. 13. janv. 
                `
            ],
            audio: [
                'Presnikovs2-1.mp3'
            ]
        },
        {
            index: 22,
            type: 'title',
            subText: 'Pašnāvību mēģinājumi un pašnāvības'
        },
        {
            index: 23,
            type: 'text',
            text: `Leitnants Edvīns Alksnis 1945. gada 13. decembra rītā, ietriecot acs ābolā 15 cm garu zīmuli, izdarīja pašnāvības mēģinājumu. 1946. gada rudenī ar laivu devās prom no Zviedrijas uz Angliju`
        },
        {
            index: 24,
            type: 'textgallery',
            text: `Zviedrijā internētais leitnants Edvīns Alksnis un Marta Lamberte, saukta par “māsu Martu”. Orreforsa, Zviedrija, 1946. gada marts 
            Marta Lamberte bija mācītāja Jura Lamberta meita. 1944. gada rudenī viņa kopā ar ģimeni devās uz Zviedriju, kur pazīstama kā žēlsirdīgā māsa Marta`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '56.jpg',
            path: 'Content/images/'
        },
        {
            index: 25,
            type: 'textgallery',
            text: `Zviedrijā internētā leitnanta Edvīna Alkšņa vēstule ar aploksni Līvijai Vītoliņai Dalsjoforā Zviedrijā. Ekolzunde (Ekolsund), Zviedrija, 1946. gada 18. aprīlis
            Pēc pašnāvības mēģinājuma un divām bezsamaņā pavadītām nedēļām E. Alksni izglāba zviedru ārsti un mācītāja J. Lamberta meita Marta`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '57.jpg',
            path: 'Content/images/'
        },
        {
            index: 26,
            type: 'text',
            text: `Leitnants Oskars Lapa naktī uz 28. novembrī Rennesletes nometnē izdarīja pašnāvību, pārgriežot roku vēnas. Viņa mirstīgās atliekas un pirmsnāves vēstuli atrada 28. novembra rītā. Vēstulē viņš norādīja, ka lēmumu pieņēmis pats, nevienam neko nejautājot, un lūdza netiesāt viņu par izdarīto. Viņš tika kremēts un apbedīts Ādolfa Frederika (Adolf Fredrik) kapos Stokholmā. Ģimene palika Vācijā`
        },
        {
            index: 27,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `Leitnanta Oskara Lapas vēstule Imantam Sinkam. Ēkšes – Rennesletes nometne, 1945. gada 19. septembris`,
            path: 'Content/images/',
            images: [
                '58.jpg'
            ],
            transcripts: [
                `Sveicināts Imant, 						Ränneslätt 19.9.45.
                Bija prieks uzzinot, ka esi izglābies. Pašam gāja raibi. Pērn, kad šķīrāmies, nokļuvu Vācijā. Pārcēla mani no kājniekiem uz manu pamatšķiru artilēriju. Nonācu 15. div. 1. latv. artil. pulkā. Solījumu sūtīt mūs atpakaļ uz Kurzemi friči neizpildīja. Pulku atbruņoja. Dancigas ielenkumā man kādā apšaudē ielauza vairākas ribas. Kopā ar citiem slimiem nokļuvu papriekšu Bornholmā un tad Zviedrijā. Dievs stāvēja klāt, jo bieži bija par matu no Pētera. Kas tālāk būs, nezinu. Cīniņš un upuri velti. Cerības uz Latviju aug. Ģimene pazudusi kaut kur Vācijā. 
                Krievi ieņēmuši apgabalu, kur sākumā bija amerikāņi. Vai manējie aizgājuši līdz amerikāņiem, nezinu, bet ceru. Tagad veči Sibīrijā, bērni ar sievu Vācijā un pats Zviedrijā. Ko Tu zini par māti un Ilgu. Kāda viņu adrese, vai esi dabūjis ziņu? Kad esi iebraucis zviedros? Pērn Tava apņēmība palikt mežā bija satricinoša. Labi, ka laidies prom. Dzīvs Imants Latvijai der vairāk par mirušu. Jātic, ka pasaulē ir kāda taisnība. Bet mums tā būs ilgāku laiku nesaprotama. Raksti. Zviedrijā ir vēl Liniņš, Jēckalējs, Andersons. Tu, ja esi brīvībā, zini vairāk par mani. Esi vesels.
                                                    Oskars.
                `
            ],
            audio: [
                'Lapa1.mp3'
            ]
        },
        {
            index: 28,
            type: 'textgallery',
            text: `Leitnanta Oskara Lapas šķirsts Ēkšes baznīcā. 1945. gada 28. novembris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '59.jpg',
            path: 'Content/images/'
        },
        {
            index: 29,
            type: 'title',
            text: 'Sabiedrības reakcija. Protesti'
        },
        {
            index: 30,
            type: 'text',
            text: `Daļa Zviedrijas sabiedrības bija sašutusi un piedalījās protesta akcijās pret baltiešu karavīru izdošanu: demonstrācijās ar lāpu un svecīšu gājieniem, īpašos dievkalpojumos un aizlūgumos baznīcās. Zviedri valdībai un karalim sūtīja aicinājumus baltiešu izdošanu atcelt, internētajiem - atbalsta telegrammas un ziedus. Zviedrijas karalis Gustavs V aicināja Staļinu vēl gadu internētos karavīrus atstāt Zviedrijā`
        },
        {
            index: 31,
            type: 'textgallery',
            text: `Protesta demonstrācija sakarā ar Zviedrijas valdības lēmumu izdot baltiešu karavīrus PSRS. Stokholma, 1945. gada novembra beigas`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '60.jpg',
            path: 'Content/images/'
        },
        {
            index: 32,
            type: 'textgallery',
            text: `Zviedrijas iedzīvotāji ar plakātiem internēto baltiešu karavīru atbalstam. Stokholma, 1945. gada novembra beigas`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '61.jpg',
            path: 'Content/images/'
        },
        {
            index: 33,
            type: 'text',
            text: `Zviedrijas armijas 2. Gētas inženieru vienības un 12. Jenčēpingas-Kalmāras pulka pieņemtā rezolūcija, kas nosoda gaidāmo baltiešu karavīru izdošanu. 1945. gada 24. novembris


            “Gētas inženieru vienība un Jenčēpingas-Kalmāras pulka virsnieki un apakšvirsnieki sakarā ar gaidāmo baltiešu un vācu bēgļu izdošanu grib visā pazemībā darīt zināmu Jūsu Majestātei [Zviedrijas karalim Gustavam V] sekojošo: Mūsu lojalitāte pret karali un valdību ir nediskutējama un visas dotās pavēles tiks nelaužami izpildītas. Bet mūsu sirdsapziņa un karavīru gods liek mums stingri izteikt mūsu kauna sajūtu, ka mums ir jāpiedalās gaidāmajā izdošanā. Mūsu vadības grupas ir bijušas vienotas savā nicināšanā pret nacistisko režīmu Vācijā, tādēļ augšā minētais ir pilnīgi brīvs no jebkādas politiskas ietekmes. Šī vienotā pievienošanās šai rezolūcijai ir izteikta bez iespaidošanas aizklātā nobalsošanā.”
            `
        },
        {
            index: 34,
            type: 'title',
            text: 'Izdošana'
        },
        {
            index: 35,
            type: 'textgallery',
            text: `Latviešu karavīrus uzved uz kuģa “Beloostrov”. Trelleborgas osta, 1946. gada 25. janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '63.jpg',
            path: 'Content/images/'
        },
        {
            index: 36,
            type: 'text',
            highlights: [
                "146",
                "130",
                "22",
                "26",
                "82",
                "deviņus",
                "septiņi",
                "viens",
                "septiņus",
                "astoņi",
                "18",
                "divi",
                "deviņi",
                "pieci"
            ],
            text: `Internēto karavīru izdošana notika no Trelleborgas ostas piektdienā, 25. janvārī. Liels skaits zviedru karavīru un policistu baltiešu karavīrus uz kuģi “Beloostrov” nogādāja ar armijas autobusiem: vispirms no Rinkabijas nometnes, tad no Geltoftas nometnes un no Frīdheimas slimnīcas. Pēc sešiem vakarā kuģis uzsāka kursu uz Liepāju.
            Zviedrija PSRS izdeva 146 baltiešu karavīrus - 130 latviešus (22 virsnieki, 26 instruktori, 82 kareivji), deviņus lietuviešus (no tiem septiņi virsnieki, viens instruktors) un septiņus igauņus (viens instruktors, astoņi kareivji). Neizdeva 18 latviešus, no kuriem divi bija izdarījuši pašnāvību, divi sakropļoti, deviņi slimi un pieci atbrīvoti kā civilie bēgļi`
        },
        {
            index: 37,
            type: 'textgallery',
            text: `Ceļš no Trelleborgas uz Liepāju`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '3.jpg',
            path: 'Content/maps/'
        },
        {
            index: 38,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“25. janvāra rītā atvērās palātas durvis un kā pati nāve ienāca bars melnu vīru – policistu. Nostājās pa trim pie katras gultas, pārmeklēja un lika saģērbties. Vēl tagad nodrebu, to atceroties. Tad sagrāba aiz rokām un kājām un sāka stiept uz autobusu. Nospļāvāmies un teicām, ka visi iesim paši. Un gājām arī. Niknums deva spēku, kaut staigājuši nebijām sen. Bet tomēr tie trulie tēviņi mūs vēl cieši turēja aiz strēbelēm.
                    Autobusā daudzi, varbūt ne visi, bet daudzi gan zināja, ka vēl palicis dzīvot tik, cik ilgi ceļā līdz Trelleborgai. Dažas stundas.” `,
                    author: 'kareivis Valentīns Silamiķelis',
                    date: '20. gadsimta otrā puse'
                }
            ],
        },
        {
            index: 39,
            type: 'textgallery',
            text: `Trelleborgas osta no putna lidojuma. 20. gadsimta pirmā puse`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '64.jpg',
            path: 'Content/images/'
        },
        {
            index: 40,
            type: 'textgallery',
            text: `PSRS pasažieru kuģis "Beloostrov" Trelleborgas ostā. 1946. gada janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '65.jpg',
            path: 'Content/images/'
        },
        {
            index: 41,
            type: 'textgallery',
            text: `Autobusi internēto karavīru vešanai uz Trelleborgas ostu. 1946. gada 25. janvāris`,
            source: 'Avots: Latvijas Okupācijas muzejs',
            image: '66.jpg',
            path: 'Content/images/'
        },
        {
            index: 42,
            type: 'textgallery',
            text: `Slima latviešu karavīra pārvietošana uz kuģi “Beloostrov”. Trelleborgas osta, 1946. gada 25. janvāris`,
            source: 'Avots: Latvijas Okupācijas muzejs',
            image: '67.jpg',
            path: 'Content/images/'
        },
        {
            index: 43,
            type: 'textgallery',
            text: `Uzraksts ar atvadu vārdiem latviešu karavīriem, sakarā ar viņu izdošanu PSRS. Trelleborgas osta, 1946. gada 25. janvāris

            “Dir war der freiheit mehr als dein leben, daran denken auch wihr”
            
            “Brīvība jums bija vairāk nekā jūsu dzīve, mēs arī par to domājam”`,
            source: 'Avots: Latvijas Okupācijas muzejs',
            image: '68.jpg',
            path: 'Content/images/'
        },
        {
            index: 44,
            type: 'textgallery',
            text: `No Zviedrijas izdotais kareivis Mečislavs Kokins uz kuģa "Beloostrov". Trelleborgas osta, 1946. gada 25. janvāris`,
            source: 'Avots: Latvijas Okupācijas muzejs',
            image: '69.jpg',
            path: 'Content/images/'
        },
        {
            index: 45,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Mēs vairs nerunāsim”

            Kareivja Ulda Bērza vēstule Martai Zīvertei. Trelleborgas osta, 1946. gada 23. janvāris`,
            path: 'Content/images/',
            images: [
                '70.jpg',
            ],
            transcripts: [
                `23.1.46.
                7.00 rītā
                
                Mēs vairs nerunāsim.
                Paldies Teriņam par klusēšanu. 
                Spiežu roku.
                                                                                                 Uldis                
                `
            ],
            audio: [
                'Berzs3-1.mp3'
            ]
        },
        {
            index: 46,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“P.S. Visi papīri, vēstules, foto iznīcināti”

            Kareivja Ulda Bērza vēstule Martai Zīvertei. Trelleborgas osta,  1946. gada 24. janvāris`,
            path: 'Content/images/',
            images: [
                '71.jpg'
            ],
            transcripts: [
                `24.1.46.
                Galīgi pie kuģa
                
                
                
                Stunda nepaies un es pazudīšu kuģī.
                Tātad…
                Nu visu to labāko
                       Jums.
                
                
                Uldis.
                
                P.S. Visi papīri, vēstules, foto ir iznīcināti.
                UB.                                
                `
            ],
            audio: [
                'Berzs3-2.mp3'
            ]
        },
        {
            index: 47,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Palieciet mīļi sveicināti un turiet par mums īkšķus”

            Dižkareivja Jēkaba Baloža vēstule M. Kalnītei. Trelleborgas osta,  1946. gada 25. janvāris`,
            path: 'Content/images/',
            images: [
                '72.jpg'
            ],
            transcripts: [
                `25.I.1946.

                Mīļš sveiciens!
                
                
                Palieciet mīļi sveicināti
                un turiet par mums īkšķus.
                
                Ardievu!
                
                Krustdēls 
                Jasis                          
                `
            ],
            audio: [
                'Jasis1.mp3'
            ]
        },
        {
            index: 48,
            type: 'documentgallery',
            source: 'Avots: Latvijas Okupācijas muzejs',
            text: `“Esiet mierīgi – briesmas ir visapkārt!”
            
            Dzejnieka Andreja Eglīša vēstule Martai Lambertei. Gēteborga (Zviedrija), 1946. gada 25. janvāris`,
            path: 'Content/images/',
            images: [
                '73.jpg'
            ],
            transcripts: [
                `Geteborgā
                1946. g. 
                25. janv.
                
                Esiet mierīgi – briesmas ir visapkārt!
                
                Tā man gribētos Jums visiem sacīt, - aizturiet vaimanas, taupiet asaras, kritušo būs daudz.
                Topiet lepna, mēs tiešām būsim izredzēta tauta, nemetiet Dievam ar akmeņiem, ja mūsu prāts apstājas netaisnības priekšā. Reiz mēs izpratīsim upuru jēgu – vēl nav gana! Un tagad, apjožiet jaunas ticības bruņas – mēs kaili viņus uzvarēsim! Ticiet dziļāk, ticiet tālāk, ticiet drošāk! Kur ņemt spēku?
                Mūsu brāļu ciešanas lai top mūžīgais stipruma avots – tas dos mums jaunu cīņas alku, izturēt līdz galam!
                Nebaidaties! Kaut kur jābūt gaismai!
                Jūs esiet tā cīnījusies – ka es ikvienam varu sacīt: tādai jābūt latvietei! Ne brīdi nešaubaties, mūsu galvas reiz vīs tēvu zemes vainags! 
                Sveiciniet brāli. Sakiet viņam baltu paldies! Viņš staigā stipru gaitu un gavilējot cīnīsies par desmitiem! 
                Mēs esam upuru tauta un mums piederas uguns drānas. Tās novilksim savā Tēvu zemē, lai mute neatveras izmisumam.
                                                                                                         Ar Dieva palīgu
                Anšlavs Eglītis
                `
            ],
            audio: [
                'Eglitis1.mp3'
            ]
        },
        {
            index: 49,
            type: 'title',
            subText: 'Izdodamo karavīru pēdējā cīņa'
        },
        {
            index: 50,
            type: 'text',
            text: `Virsleitnants Artūrs Plūme pašnāvības mēģinājumu, iedurot sev krūtīs tuvcīņas dunci, veica Geltoftas nometnes evakuācijas laikā. Viņu nogādāja Krišanstades (Kristianstad) slimnīcā, kur veica operāciju  un viņa dzīvību izglāba. Neizdeva. 1946. gada rudenī, baidoties no izdošanas PSRS, aizbēga uz Lielbritāniju`
        },
        {
            index: 51,
            type: 'textgallery',
            text: `Priekšplānā virsleitnants Arturs Plūme. Ēkše, 1945. gada maijs`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '74.jpg',
            path: 'Content/images/'
        },
        {
            index: 52,
            type: 'text',
            text: `Valentīns Silamiķelis īsi pirms kāpšanas uz kuģa ar abām rokām ietriecās autobusa aizmugurējā logā un sagriezās. Viņu aizveda uz īpaši ierīkotu Sarkanā Krusta pārsiešanas punktu, pārsēja ievainojumu, atveda atpakaļ un nestuvēs uznesa uz kuģa kā vienu no pēdējiem`
        },
        {
            index: 53,
            type: 'textgallery',
            text: `Kareivja Valentīna Silamiķeļa izdošana PSRS. Trelleborgas osta, 1946. gada 25. janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '75.jpg',
            path: 'Content/images/'
        },
        {
            index: 54,
            type: 'text',
            text: `Leitnants Pēteris Vabulis izdošanās dienā Trelleborgas ostā īsi pirms izdošanas ar žiletes palīdzību pārgrieza kakla artērijas. Viņu nogādāja slimnīcā, kur viņš mira. P. Vabuli kremēja 30. janvārī Trelleborgas krematorijā. Vabulis apbedīts A. Frederika kapos Stokholmā. Ģimene - sieva un divi bērni palika Vācijā `
        },
        {
            index: 55,
            type: 'textgallery',
            text: `Leitnanta Pētera Vabuļa mirstīgās atliekas Trelleborgas krematorijā. Trelleborga, 1946. gada 30. janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '76.jpg',
            path: 'Content/images/'
        },
        {
            index: 56,
            type: 'textgallery',
            text: `Trelleborgas krematorija. Zviedrija, 20. gs. vidus`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '77.jpg',
            path: 'Content/images/'
        },
        {
            index: 57,
            type: 'title',
            subText: 'Atskaņas'
        },
        {
            index: 58,
            type: 'text',
            text: `Nākamajā dienā pēc karavīru izdošanas – 1946. gada 26. janvārī – lielākajos Stokholmā iznākošajos latviešu laikrakstos iespieda publikācijas par latviešu karavīru aizvešanu`
        },
        {
            index: 59,
            type: 'textgallery',
            text: `Raksts “Internētie baltieši aizvesti” laikrakstā “Latvju Ziņas”. Stokholma, 1946. gada 26. janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '78.jpg',
            path: 'Content/images/'
        },
        {
            index: 60,
            type: 'textgallery',
            text: `Raksts “Internēto liktenis izšķīries” laikrakstā “Latvju Vārds”. Stokholma, 1946. gada 26. janvāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '79.jpg',
            path: 'Content/images/'
        },
    ],
    SECTION4: [
        {
            index: 0,
            type: 'title',
            text: 'Dzīve notikušā ēnā',
            subText: 'Padomju režīma gūstā'
        },
        {
            index: 1,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“Viens vārds

                    Kad lode svilpo nāves skrējienā,
                    Viens vārds tad svētākais par visu:
                    Šis vārds vissvētākais ir Dzimtene.
                    
                    Tas katram dāvā cīņā drosmi,
                    Ar vārdu šo gulst kareiv’s smiltainē.
                    
                    Kad dzīve slēgta drūmā verdzībā,
                    Viens vārds tad svētākais par visu:
                    Šis vārds vissvētākais ir Brīvība.
                    
                    To nespēj noslēpt smagā tumsība,
                    Tas katru sauc uz māju pusi”`,
                    author: 'Imants Lērmanis, Vorkuta',
                    date: '1954. gada 21. marts'
                }
            ],
        },
        {
            index: 2,
            type: 'text',
            text: `1946. gada 27. janvāra pēcpusdienā padomju kuģis “Beloostrov” ar baltiešu karavīriem iebrauca Liepājas ostā. Atvestie karavīri tika novietoti nometnē pie Liepājas cukurfabrikas, bet vēlāk vienu daļu pārveda uz Sarkandaugavu Rīgā, otru - Tušķu – Bemberu cepli pie Jelgavas, grupu virsnieku aizveda uz vācu karagūstekņu nometni Rīgā, Mežaparkā. Sekoja filtrācija. Pēc tās jaunākā gadagājuma bijušajiem karavīriem bija jāstrādā Latvijas PSR Iekšlietu ministrijas Šoseju pārvaldē ceļu un ēku būvdarbos. Turpmākais karavīru liktenis bija atkarīgs no padomju varas iestāžu rīcības – vieniem tas agrāk vai vēlāk veda caur padomju cietumiem un labošanas darbu nometnēm, citiem izdevās izveidot spožas karjeras`
        },
        {
            index: 3,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: ``,
            path: 'Content/tables/',
            images: [
                '4-3.jpg',
                '4-4.jpg',
            ],
            transcripts: [
                ``,
                ``
            ]
        },
        {
            index: 4,
            type: 'text',
            text: `“Pienāca laiks kāpt uz kuģa. Sajūta pavisam draņķīga. Galvā šaudījās visādas domas, kā krievi ar mums izrēķināsies. Līdz trapam mūs pavadīja zviedru \"konstāpeļi\", bet pēc tam pārņēma krievi. Tūlīt tie sāka \"bičot\" naudu, pulksteņus. Naudu atdevu, bet pulksteni paturēju. (..)
            Toreiz uz kuģa uznāca kāds cilvēks, kurš runāja tīri latviešu valodā. Droši vien kāds darbinieks no toreizējās padomju vēstniecības. Viņš drošināja mūs, lai nebaidoties. Pēc īsas pārbaudes visus atlaidīšot mājās. Viņš arī teica, ka mūs vedīšot uz Latviju. Izrādījās, ka tam vīram bija taisnība, kuģis tiešām piestāja Liepājas ostā. (..) Mūs nogādāja nometnē. Neatceros, vai gājām kājām jeb mūs nogādāja ar autotransportu. Nometne bija iekārtota \"Sarkanā Metalurga\" noliktavu telpās. Tur bija vairāki korpusi, kuros jau priekšā mūs gaidīja vācieši, kuri bija pirms mums atsūtīti no Zviedrijas. (..) Tad mūs nodeva nometnes komandanta aprūpē, pēc kā sekoja pamatīga kratīšana. Atņēma visu no mūsu bagāžas, izņemot dvieli, ziepes, diega spolīti un adatiņu. (..)
            Vienu daļu no mums nosūtīja uz Svētes ķieģeļcepli, bet otru daļu uz Rīgu, uz kādu rūpnīcu. Mani nozīmēja pie ķieģeļu formēšanas. (..) Rudens pusē mūs apmeklēja ģenerālis Eglītis, kurš tolaik bija [Latvijas PSR] Iekšlietu ministrs.  Viņš mūs iepriecināja ar to, ka drīz tiksim mājām. Diez’ ko jau neticējām, bet izrādījās, ka tā bija taisnība. Mūs atlaida mājās un nozīmēja darbā IeM pakļautajā \"Ušosdor\", kur remontējām Rīgas-Bauskas šoseju. (..)”`,
            source: 'Oļģerta Ābrama atmiņas par izdošanu PSRS'
        },
        {
            index: 5,
            type: 'linkgallery',
            text: 'Krievijas Padomju Sociālistiskās Federatīvās Republikas Kriminālkodeksa (1926. gada redakcijā) panti, saskaņā ar kuriem tiesāja 1946. gada 26. janvārī no Zviedrijas PSRS izdotos latviešu karavīrus',
            links: [
                '58. panta 1. punkta “a” daļa',
                '58. panta 1. punkta “b” daļa',
                '58. panta 4. punkts',
                '58. panta 10. punkts',
                '58. panta 11. punkts',
                '121. pants',
                '182. pants'
            ],
            texts: [
                "Dzimtenes nodevība: nošaušana ar mantas konfiskāciju, vai 10 gadi ar mantas konfiskāciju",
                "Dzimtenes nodevība, ko izdarījusi karadienesta persona: nošaušana ar mantas konfiskāciju",
                "Palīdzības sniegšana starptautiskajai buržuāzijai, kas neatzīst komunistiskās sistēmas vienlīdzību, cenšoties to gāzt, kā arī šīs buržuāzijas ietekmē vai tieši organizētām sabiedriskajām grupām un organizācijām pret PSRS naidīgas darbības īstenošanā: sods analogs 58. panta 2. punktam – nošaušana vai pasludināšana par strādnieku ienaidnieku ar mantas konfiskāciju un ar sociālistiskās republikas pilsonības atņemšanu, tādējādi atņemot arī PSRS pilsonību un izraidot no PSRS uz visiem laikiem, pieļaujot vainu mīkstinošos apstākļos sodu samazināšanu ar brīvības atņemšanu uz laiku, kas nav mazāks par trīs gadiem, konfiscējot visu mantu vai tās daļu",
                "Propaganda vai aģitācija, kas satur aicinājumu gāzt, graut vai vājināt padomju varu, vai veikt atsevišķas kontrrevolucionāras darbības, kā arī tāda paša satura literatūras izplatīšana vai izgatavošana, vai glabāšana ietver brīvības atņemšanu uz laiku ne īsāku par sešiem mēnešiem",
                "Jebkura veida organizēta darbība, kas vērsta uz šajā nodaļā paredzēto kontrrevolucionāro noziegumu sagatavošanu vai izdarīšanu, ir pielīdzināma tādu izdarīšanai un tiek saukts pie kriminālatbildības kriminālkodekss pēc attiecīgiem pantiem",
                `Slepenu ziņu izpaušana, paziņošana, nodošana vai vākšana - brīvības atņemšana uz laiku līdz trim gadiem vai disciplinārsods, ko uzliek attiecīgās iestādes`,
                `Par sprāgstvielu vai šāviņu izgatavošanu, glabāšanu, pirkšanu un noietu, kā arī par šaujamieroča (ne medību) ieroča glabāšanu bez atbilstošas atļaujas —
                piespiedu darbs uz laiku līdz sešiem mēnešiem vai naudas sods līdz vienam tūkstotim rubļu, konfiscējot nosauktās vielas, šāviņus un ieročus`
            ]
        },
        {
            index: 6,
            type: 'title',
            text: 'Sodītie karavīri'
        },
        {
            index: 7,
            type: 'text',
            text: `1946. gada vasarā sākās izdoto karavīru aresti un sodīšanas. Bijušos karavīrus sodīja saskaņā ar Krievijas Padomju Sociālistiskās Federatīvās Republikas Kriminālkodeksu (1926. gada redakcijā). Notiesāti ir vismaz 24 no Zviedrijas izdotajiem latviešu karavīriem. Izdotos karavīrus notiesāja uz 10 – 25 gadiem ieslodzījuma padomju stingrā un vispārējā režīma labošanas darba nometnēs – Kolimā, Karagandā, Vorkutā, Noriļskā. Vienam no viņiem – Pēterim Ziemelim – tika izpildīts nāvessods. Trīs Zviedrijas izdotos karavīrus deportēja no Latvijas 1949. gada 25. martā. 
            1953. gada 5. martā nomira Staļins. Drīz pēc tam - 50. gadu vidū - pēc daudziem padomju soda nometnēs pavadītiem gadiem liela daļa notiesāto no Zviedrijas izdoto karavīru drīkstēja atgriezties mājās Latvijā`
        },
        {
            index: 8,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: ``,
            path: 'Content/tables/',
            images: [
                '4-45.jpg',
                '4-46.jpg',
            ],
            transcripts: [
                ``,
                ``
            ]
        },
        {
            index: 9,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: ``,
            path: 'Content/tables/',
            images: [
                '4-5.jpg',
                '4-6.jpg',
            ],
            transcripts: [
                ``,
                ``
            ]
        },
        {
            index: 10,
            type: 'linkgallery',
            text: '',
            links: [
                'Balodis, Ernests',
                'Reķis (Rēķis), Oskars Pēteris',
                'Ziemelis, Pēteris',
                'Vilders, Jānis',
                'Ivbulis (Ivbuls), Augusts',
                'Eihfus-Atvars, Elmārs'
            ],
            texts: [
                `Dzimis 1910. gadā Aizputē;
                Miris – trūkst ziņu;
                Dienesta pakāpe: kaprālis;
                1945. gada maijā no Kurzemes devās uz Zviedriju, kur atradās Havdhemas un Ēkšes - Rennesletes internēto karavīru nometnēs;
                1945. gada 25. janvārī tika izdots PSRS;
                Līdz arestam atradās karagūstekņu nometnē Jelgavā;
                Arestēts 1946. gada 20. jūlijā;
                Notiesāts 1946. gada 27. novembrī saskaņā ar 
                KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 15 gadiem, tiesību atņemšanu uz 5 gadiem, bez mantas konfiskācijas;  
                
                Ziņu par turpmāko likteni trūkst.`,
                `Dzimis 1914. gadā Daugavpilī;
                Miris: ziņu trūkst;
                Dienesta pakāpe: leitnants;
                1939. gadā beidzis Kara skolu;
                1940.-1941. gadā Sarkanās armijas 24. teritoriālajā strēlnieku korpusā;
                No 1944. gada novembra līdz 1945. gada maijam bijis Ventspils cietuma priekšnieka vietnieks;
                1945. gada maijā no Kurzemes devās uz Zviedriju, kur atradās Havdhemas un Ēkšes - Rennesletes internēto karavīru nometnēs; 
                1945. gada 25. janvārī izdots PSRS;
                Arestēts 1946. gada 18. jūlijā;
                Notiesāts 1946. gada 27. novembrī saskaņā ar 
                KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 18 gadiem, tiesību atņemšanu uz 5 gadiem, bez mantas konfiskācijas;  
                Ieslodzījumā atradās Voruktas labošanas darbu nometnē;
                
                Ziņu par turpmāko likteni trūkst.`,
                `Dzimis 1918. gadā Ilūkstes apriņķī;
                Miris 1947. gada 12. februārī;
                Dienesta pakāpe: leitnants;
                1945. gada maijā no Kurzemes devās uz Zviedriju, kur atradās Havdhemas un Rennesletes internēto karavīru nometnēs; 
                1945. gada 25. janvārī izdots PSRS;
                Arestēts 1946. gada 20. jūlijā; 
                Notiesāts 1946. gada 27. novembrī saskaņā ar 
                KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu ar augstāko soda mēru – nošaušanu;
                Spriedums izpildīts 1947. gada 12. februārī;
                Nav reabilitēts, saskaņā ar 1990. gada 3. augusta Latvijas Republikas Likuma “Par nelikumīgi represēto personu reabilitāciju” 1. panta 2. punktu: “Par darbībām, kas paredzētas KPFSR kriminālkodeksa 58.1 — 58.14 pantā, izņemot personas, kuras starptautiski tiesiskajā izpratnē vainīgas noziegumos pret mieru, genocīda noziegumos, noziegumos pret cilvēci, kara noziegumos un kriminālnoziegumos pret nekombatantiem, kā arī personas, kuras vainīgas krimināllietu viltošanā”`,
                `Dzimis 1909. gadā Lielstraupes pagastā;
                Miris ieslodzījumā labošanas darbu nometnē “Rečnoj” (krievu val. -  Речной лагерь) 1949. gada 12. jūlijā;
                Dienesta pakāpe: kareivis;
                No 1944. gada 17. februāra 1. robežapsardzības pulkā;
                1945. gada pavasarī internēts Zviedrijā;
                1946. gada 25. janvārī tika izdots PSRS;
                Arestēts 1946. gada 31. jūlijā;
                Notiesāts 1946. gada 2. decembrī
                saskaņā ar 
                KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 20 gadiem, tiesību atņemšanu uz 5 gadiem, bez mantas konfiskācijas;  
                Ieslodzījumā atradās Komi APSR, Vorkutas labošanas darbu nometnē, nometnē “Rečnoj”;
                
                Ziņu par turpmāko likteni trūkst.`,
                `Dzimis 1916. gadā Maskavā;
                Miris – 1980. gadā Rīgā;
                Dienesta pakāpe: kareivis;
                1941. gada jūnijā kā “zaļais partizāns” cīnījās ar atejošām Sarkanās armijas daļām;
                Dienēja 15. ieroču SS artilērijas pulka 3. vieglajā divizionā;
                1946. gada 25. janvārī tika izdots PSRS;
                Līdz arestam dzīvoja Daugavpils apriņķa Kalupes pagastā;
                Arestēts 1946. gada 22. oktobrī;
                Notiesāts 1947. gada 25. aprīlī
                saskaņā ar 
                KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu 
                ar brīvības atņemšanu uz 15 gadiem, tiesību atņemšanu uz 5 gadiem, bez mantas konfiskācijas;  
                Ieslodzījumā atradās Karagandas apgabalā Peščavas labošanas darbu nometnē; 
                
                Ziņu par turpmāko likteni trūkst.`,
                `Dzimis 1912. gadā Rīgā;
                Miris: 1970. gadā Tukumā;
                Dienesta pakāpe: ārsts leitnants;
                1945. gada maijā no Kurzemes devās uz Zviedriju, kur atradās Havdhemas un Ēkšes - Rennesletes internēto karavīru nometnēs;
                1945. gada 25. janvārī izdots PSRS;
                Pirms aresta dzīvoja Slokā, pārbaudes – filtrācijas nometnē Nr. 317;
                Arestēts 1946. gada 13. novembrī;
                Notiesāts 1947. gada 15. oktobrī
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 4. punktu 
                ar brīvības atņemšanu uz 10 gadiem, bez tiesību atņemšanas un  mantas konfiskācijas;  
                Ieslodzījumā atradās Mordovijas APSR, Dubravas labošanas darbu nometnē (krievu val. – Дубравный лагерь);
                Kad atbrīvots – nav datu;
                Pēc atgriešanās no labošanas darbu nometnes dzīvoja Tukumā`
            ]
        },
        {
            index: 11,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Elmāra Eihfusa-Atvara gūstekņa uzskaites kartiņa no pārbaudes-filtrācijas nometnes Nr. 317. Latvija, 1946. gada 10. jūlijs`,
            path: 'Content/images/',
            images: [
                '80-1.jpg',
                '80-2.jpg'
            ],
            transcripts: [
                ``,
                ``
            ]
        },
        {
            index: 12,
            type: 'textgallery',
            text: `Elmārs Eihfus-Atvars pēc atgriešanās Latvijā ar dzīvesbiedri un viņas piederīgajiem. Tukums, 20. gs. vidus`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '81.jpg',
            path: 'Content/images/'
        },
        {
            index: 13,
            type: 'linkgallery',
            text: '',
            links: [
                'Grīva, Ēvalds',
                'Gailītis, Kārlis'
            ],
            texts: [
                `Dzimis 1914. gadā Meirānos;
                Miris 1947. gadā Karagandā;
                Dienesta pakāpe: kaprālis;
                1945. gada pavasarī internēts Zviedrijā;
                1946. gada 25. janvārī izdots PSRS;
                Arestēts 1946. gada 17. decembrī;
                Notiesāts 1947. gada 11. jūnijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu 
                ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju`,
                `Dzimis 1896. gadā Lubānas pagastā;
                Miris 1966. gadā Ogrē;
                Dienesta pakāpe: pulkvedis leitnants;
                1941. gada vasarā bija Lubānas pagasta pašaizsardzības nodaļas vadītājs;
                No 1941. gada oktobra – Ostlandes Latvijas ģenerālapgabala Saimniec
                ības ģenerāldirekcijas Tirdzniecības departamenta Tekstiliju nodaļas vadītājs;
                1944. gada februārī mobilizēts Latviešu leģionā, bijis Sevišķās kara tiesas priekšsēdētāja vietnieks, Ģenerālinspektora štāba juridiskās nodaļas vadītāja vietnieks, novembrī piedalījies Štuthofas nometnē esošo 365 Kureļa grupas karavīru tiesāšanā;
                1945. gada maijā no Kurzemes devās uz Zviedriju, kur bija Havdhemas un Ēkšes - Rennesletes internēto karavīru nometnes baltiešu vecākais;
                1945. gada 25. janvārī izdots PSRS;
                Arestēts 1946. gada 31. decembrī;
                Notiesāts 1947. gada 9. aprīlī saskaņā ar 
                KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar augstāko soda mēru – nošaušanu;
                1947. gada 27. maijā PSRS Augstākās tiesas kara kolēģija aizstāja augstāko soda mēru ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem; 
                Sodu izcieta Karagandas apgabalā;
                Atbrīvots 1964. gadā;
                Ģimene dzīvoja Rietumos`
            ]
        },
        {
            index: 14,
            type: 'textgallery',
            text: `Apliecība par Kārļa Gailīša atbrīvošanu no pārbaudes-filtrācijas nometnes Nr. 0337. Latvija, 1946. gada 10. augusts`,
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            image: '82.jpg',
            path: 'Content/images/'
        },
        {
            index: 15,
            type: 'linkgallery',
            text: '',
            links: [
                'Liepiņš, Manfreds'
            ],
            texts: [
                `Dzimis 1914. gadā Cēsu apriņķa Lenču pagastā;
                Miris ap 1960. gadu labošanas darbu nometnē;
                Dienesta pakāpe: kareivis;
                1945. gada maijā no Kurzemes devās uz Zviedriju, kur atradās Havdhemas un Ēkšes - Rennesletes internēto karavīru nometnēs;
                1946. gada 25. janvārī tika izdots PSRS;
                Pirms aresta dzīvoja Rīgā;
                Arestēts 1946. gada 31. decembrī;
                Notiesāts 1947. gada 9. aprīlī
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu 
                ar brīvības atņemšanu uz 10 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju`,
            ]
        },
        {
            index: 16,
            type: 'textgallery',
            text: `Apliecība par Manfrēda Liepiņa atbrīvošanu no pārbaudes-filtrācijas nometnes Nr. 0337. Latvija, 1946. gada 28. jūnijs`,
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            image: '83.jpg',
            path: 'Content/images/'
        },
        {
            index: 17,
            type: 'linkgallery',
            text: '',
            links: [
                'Līcis, Arnolds'
            ],
            texts: [
                `Dzimis 1910. gadā Ukrainā;
                Miris 1991. gadā Rīgā;
                Dienesta pakāpe: leitnants;
                Dienēja 15. ieroču SS artilērijas pulka III divizionā;
                Bijis internēts Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī tika izdots PSRS;
                Līdz arestam dzīvoja Rīgā, strādāja fabrikā “Radiotehnika”;
                Arestēts 1947. gada 31. martā;
                Notiesāts 1947. gada 31. jūlijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 10 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradās Komi APSR, Intā;
                Arestā līdz 1956. gada 28. jūlijam`,
            ]
        },
        {
            index: 18,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Arnolda Līča rūpnīcas “Radiotehnika” darbinieka apliecība. Rīga, 1946. gada 25. oktobris`,
            path: 'Content/images/',
            images: [
                '84-1.jpg',
                '84-2.jpg',
                '84-3.jpg',
            ],
            transcripts: [
                ``,
                ``,
                ``
            ]
        },
        {
            index: 19,
            type: 'linkgallery',
            text: '',
            links: [
                'Ķeselis Ernests (Kessels Ernsts)'
            ],
            texts: [
                `Dzimis 1895. gadā Rīgā; 
                Miris 1967. gadā; 
                Dienesta pakāpe: kapteinis;
                1941. gada jūlijā ar latviešu partizānu vienību piedalījies kaujās pret Sarkano armiju un padomju aktīvistiem pie Limbažiem;
                1943. gada martā iesaukts Latviešu leģionā, bijis 2. brigādes Atsevišķā artilērijas diviziona 3. baterijas komandieris;
                no 1944. gada rudens – 15. artilērijas pulka 3. vieglā diviziona komandieris;
                1945. gada 27. martā kopā ar Dancigas telpā esošiem 3. vieglā diviziona karavīriem devies uz Bornholmas salu, 8. maijā – uz Zviedriju;
                Bijis internēts Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                1946. gada februārī atbrīvots, strādājis Latvijas PSR Iekšlietu ministrijas Nometņu pārvaldē par grāmatvedi; 
                Arestēts 1947. gada 14. aprīlī;
                Notiesāts 1947. gada 24. jūlijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu 
                ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju, vēlāk sods samazināts līdz 15 gadiem;
                Atradies soda nometnēs Irkutskā un Komi APSR;
                No ieslodzījuma atbrīvots 1962. gadā; 
                Dzīvoja Olainē;
                Ģimene dzīvoja Lielbritānijā`,
            ]
        },
        {
            index: 20,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Ernesta Ķeseļa Kara klausības apliecības atvērums. Apliecība glabājas E. Ķeseļa Latvijas PSR VDK krimināllietā`,
            path: 'Content/images/',
            images: [
                '85.jpg'
            ],
            transcripts: [
                ``,
            ]
        },
        {
            index: 21,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Ernesta Ķeseļa pagaidu apliecība par grāmatveža darbu Latvijas PSR Iekšlietu ministrijas nometņu pārvaldē. Latvija, 1946. gada 6. decembris`,
            path: 'Content/images/',
            images: [
                '86.jpg'
            ],
            transcripts: [
                ``,
            ]
        },
        {
            index: 22,
            type: 'textgallery',
            text: `Ernests Ķeselis izsūtījumā Komi APSR. 1960. gadu sākums`,
            source: 'Avots: Latvijas Okupācijas muzejs',
            image: '87.jpg',
            path: 'Content/images/'
        },
        {
            index: 23,
            type: 'linkgallery',
            text: '',
            links: [
                'Kaņeps, Arvīds'
            ],
            texts: [
                `Dzimis 1911. gadā Cēsu apriņķa Rāmuļu pagastā;
                Miris 1996. gadā Rīgā;
                Dienesta pakāpe: kaprālis;
                1944. gadā mobilizēts vācu armijā;
                Dienēja 15. ieroču SS artilērijas pulka 3. vieglajā divizionā;
                Bijis internēts Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Līdz arestam dzīvojis Rīgā;
                Arestēts 1947. gada 11. augustā;
                Notiesāts 1947. gada 24. novembrī
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu 
                ar brīvības atņemšanu uz 10 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradās Kirovas apgabalā;
                Atbrīvots 1954. gada 7. oktobrī;
                Pēc atgriešanās Latvijā 1957. gada 24. decembrī sāka strādāt Latvijas republikas Kultūras ministrijas Zinātniskās restaurēšanas pārvaldē, kur strādāja līdz 1990. gadam `,
            ]
        },
        {
            index: 24,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Arvīda Kaņepa Kara biļetes pagaidu apliecība (aizstājējs). Rīga, 1946. gada 8. oktobris`,
            path: 'Content/images/',
            images: [
                '88-1.jpg',
                '88-2.jpg',
                '88-3.jpg'
            ],
            transcripts: [
                ``,
            ]
        },
        {
            index: 25,
            type: 'linkgallery',
            text: '',
            links: [
                'Apkalns, Pēteris'
            ],
            texts: [
                `Dzimis 1898. gadā Kokneses pagastā;
                Miris 1970. gadā Kingstonā;
                Dienesta pakāpe: kapteinis;
                1945. gada maijā no Kurzemes devies uz Zviedriju, kur atradās Havdhemas un Ēkšes - Rennesletes internēto karavīru nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Aresta brīdī strādāja rūpnīcā “Radiotehnika” par bibliotekāru tehniskajā bibliotēkā, dzīvoja Rīgā;
                Arestēts 1947. gada 16. oktobrī;
                Notiesāts 1948. gada 26. jūnijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu 
                ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradās Mordovijas APSR, Dubravas labošanas darbu nometnē;
                Atbrīvots 1956. gada 26. jūlijā;
                No 1970. gada dzīvoja Francijā`,
            ]
        },
        {
            index: 26,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Pētera Apkalna rūpnīcas “Radiotehnika” darbinieka apliecība. Rīga, 1946. gada 15. novembris`,
            path: 'Content/images/',
            images: [
                '89-1.jpg',
                '89-2.jpg',
                '89-3.jpg'
            ],
            transcripts: [
                ``,
            ]
        },
        {
            index: 27,
            type: 'linkgallery',
            text: '',
            links: [
                'Lielkājs, Pauls'
            ],
            texts: [
                `Dzimis 1905. gadā Valkā;
                Miris 1977. gadā Rīgā;
                Dienesta pakāpe: virsleitnants;
                Dienēja 15. ieroču SS artilērijas pulka 3. vieglajā divizionā;
                Bijis internēts Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs; 1946. gada 25. janvārī izdots PSRS;
                Pirms aresta strādājis Rīgā metāllūžņu bāzē par inspektoru;
                Arestēts 1948. gada 26. aprīlī;
                Notiesāts 1948. gada 20. augustā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradās Vorkutinskas labošanas nometnē ;
                Atbrīvots – nav ziņu;
                Pēc atgriešanās no labošanas darbu nometnes dzīvoja Rīgā, strādāja 15. Autotransporta kantorī;
                Sieva ar dēliem dzīvoja Austrālijā`,
            ]
        },
        {
            index: 28,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Paula Lielkāja pirkstu nospiedumi. Rīga, 1948. gada 8. maijs`,
            path: 'Content/images/',
            images: [
                '90.jpg'
            ],
            transcripts: [
                ``,
            ]
        },
        {
            index: 29,
            type: 'linkgallery',
            text: '',
            links: [
                'Gustavs Vilks'
            ],
            texts: [
                `Dzimis 1911. gadā Mangaļos;
                Miris 1985. gadā;
                Dienesta pakāpe: kaprālis;
                Dienēja 15. ieroču SS artilērijas pulka 3. vieglajā divizionā;
                Bijis internēts Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Aresta brīdī dzīvoja Rīgā un strādāja Rīgas pilsētas 28. pamatskolā par saimniecības pārzini;
                Arestēts 1949. gada 4. maijā;
                PSRS Valsts Sevišķā apspriede notiesāja 1949. gada 29. novembrī
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu un 121. pantu
                ar brīvības atņemšanu uz 25 gadiem, bez mantas konfiskācijas;
                Ieslodzījumā atradās Vorkutā, IeTK nometnē Nr. 6; 
                No ieslodzījuma atbrīvots 1956. gada 16. augustā
                `,
            ]
        },
        {
            index: 30,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Rīgas pilsētas 28. pamatskolas saimniecības pārziņa Gustava Vilka darba apliecība. Rīga, 1948. gada 27. jūlijs`,
            path: 'Content/images/',
            images: [
                '91-1.jpg',
                '91-2.jpg'
            ],
            transcripts: [
                ``,
            ]
        },
        {
            index: 31,
            type: 'linkgallery',
            text: '',
            links: [
                'Knoks, Valdis',
                "Raiskums (Rēdlihs), Jēkabs"
            ],
            texts: [
                `Dzimis 1912. gadā Limbažos;
                Miris 1992. gadā Rīgā;
                Dienesta pakāpe: leitnants;
                1939. gada septembrī beidza Kara skolu;
                Sarkanās armijas 24. teritoriālajā strēlnieku korpusā vada komandieris;
                No 1943. gada 7. decembra 15. ieroču SS artilērijas pulka 3. vieglajā divizionā;
                1945. gada maijā internēts Zviedrijā, atradies Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Pirms aresta Rīgas pilsētas Proletāriešu rajona namu pārvaldes Nr. 564 grāmatvedis;
                Arestēts 1949. gada 4. novembrī;
                Notiesāts 1950. gada 22. martā
                saskaņā ar saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “b” daļu un 58. panta 11. punktu
                ar brīvības atņemšanu uz 25 gadiem, bez mantas konfiskācijas;
                Ieslodzījumā atradās Komi APSR, Vorkutā;
                Atbrīvots no ieslodzījuma vietas 1956. gada 11. augustā;
                Pēc atbrīvošanas dzīvoja Rīgā
                `,
                `Dzimis 1910. gadā Tukuma apriņķa Milzkalnes pagastā;
                Miris 1992. gadā Rīgā;
                Dienesta pakāpe: virsleitnants;
                1938. gadā beidzis Kara skolu;
                 1940. gada maijā mainījis uzvārdu no Redlihs uz Raiskums;
                No 1941. gada 4. septembra Rīgas 1. atsevišķā kārtības dienesta bataljona 4. rotas komandieris; 
                1942.-1943. gadā Zemgales 16. kārtības dienesta policijas bataljona virsnieks;
                No 1943. gada 19. latviešu SS brīvprātīgo divīzijas 42. grenadieru pulkā;
                1945. gada maijā internēts Zviedrijā, atradies Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Pirms aresta strādājis Ēdoles Spirta fabrikā par grāmatvedi;
                Arestēts  1950. gada 22. februārī;
                Notiesāts 1950. gada 26. augustā
                saskaņā ar saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu un 58. panta 10. punkta 1. daļu
                ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradies labošanas darbu nometnēs Omskā un [Bratskā];
                No ieslodzījuma atbrīvots 1958. gadā;
                Strādājis par akmeņkali`
            ]
        },
        {
            index: 32,
            type: 'textgallery',
            text: `Jēkabs Raiskums ar dzīvesbiedri Alīdu Jēgeri Zanderu kapos. Jelgava, 1969. gada jūnijs`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '92.jpg',
            path: 'Content/images/'
        },
        {
            index: 33,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Lai nu gan Zviedru zemē badojos, lai mani nesūta uz šejieni, tomēr tagad neko ļaunu nesaku un varbūt labāk ka esmu šeit. Varbūt viena otra lieta šeit jāpiecieš, kas Jums nē, tomēr dzimtene paliek dzimtene. Varbūt taisnība tam sakāmvārdam ka “skaidrs ūdens caurā vāzē dzimtenē garšīgāks, nekā visdārgākais vīns zelta traukā svešumā”.”

            Jēkaba Raiskuma vēstule Līvijai Vītoliņai Zviedrijā. Rīga, 1964. gada 1. novembris`,
            path: 'Content/images/',
            images: [
                '93-1.jpg',
                '93-2.jpg'
            ],
            transcripts: [
                `Rīgā,
                nov. 1964.
                
                Sveicināta!
                
                Jūsu š.g. 25.10.64. vēstuli saņēmu vakar un tūlīt arī atbildēšu, lai Jūs zinātu, ka tā nav gājusi zudumā. Tās divas vēstules, par kurām Jūs rakstāt, un arī tās fotokartiņas būs laikam gājušas zudumā. Es jau nu gan tam savam jaunajam īrniekam Siguldā piesacīju, lai visas pienākošās vēstules pāradresē uz Rīgu. Bet tagad jau vairāk par mēnesi neviena vēstule nav pienākusi, kaut gan agrāk mēneša laikā bija vairākas vēstules. Būs tīri vai kādu svētdienu jāaizbrauc pašam uz Siguldu un jāpārliecinās.
                Tagad iznāk, ka esmam darba kolēģi. Arī es patreiz strādāju slimnīcā, t.i. Jūrmalas I. sl. Bulduros, kur esmu norīkots no mūsu remontpārvaldes dažādu darbu veikšanai. Tikai Jums tas ir papildus darbs, bet mans pats galvenais.
                Mums gan patreiz ir jauks rudenis. Lietus nelīst, pa daļai mākoņains, bet šodien kā pie svētdienas jauks un saulains laiks. Būs jāizlaiž kāds līkums pa pilsētu un apkārtni. Varbūt aiziešu apciemot arī Teodoru Reiteru, kas ar tādu godu tika guldīts Meža kapu smiltājā. Bijuši tik daudz pavadītāji, ka reti redzēts Meža kapos.
                `,
                `Par to Arvidu Z. nemaz neuztraucos. Miers viņa pīšļiem un saldu dusu Zviedru zemē. Draugs jau viņš man nav bijis, bet labs paziņa gan. Šoreiz vārds “labs” nav jāsaprot kā īpašība, bet gan kā “labi pazīstams”. 
                Vai tad Jūs sava mūža otrajā pusē gribat izmācīties par provizoru, jeb profesoru, ka sākat studēt antīkās valodas? Labāk būtu atkārtojusi krievu valodu un braukusi pie mums. Tikai neuzskatat to kā aģitāciju un propagandu no manas puses.
                Lai nu gan Zviedru zemē badojos, lai mani nesūta uz šejieni, tomēr tagad neko ļaunu nesaku un varbūt labāk, ka esmu šeit. Varbūt viena otra lieta šeit jāpiecieš, kas Jums nē, tomēr dzimtene paliek dzimtene. Varbūt taisnība tam sakāmvārdam, ka “skaidrs ūdens no cauras vāzes dzimtenē garšīgāks, nekā visdārgākais vīns Zelta traukā svešumā”. 
                Izrādās, ka ar Jums Zv. zemē problēma ar dzīvokļiem. Es domāju, ka tas tikai pie mums, kur tik liela dzimstība un liels pieaugums arī no iebraucējiem. Mūsējie ir krietnāki un nekur prom nebrauc. Vienīgā vieta uz kurieni pārmaina dzīvokli, t.i. dabīgais nobirums – kapsēta. 
                Dzīves apstākļi man patreiz ir labi, bet darba  - varēja būt labāki, jo ziemā ārpus telpām darbi nav visai patīkami, kaut arī kurortā.
                Visu labu!
                Jūsu J. Raiskums. 
                `
            ],
            audio: [
                'Raiskums1.mp3',
                'Raiskums1.mp3'
            ]
        },
        {
            index: 34,
            type: 'linkgallery',
            text: '',
            links: [
                'Kiršteins, Atvars'
            ],
            texts: [
                `Dzimis 1916. gadā Rīgā;
                Miris – 2001. gadā Rīgā;
                Dienesta pakāpe: leitnants;
                No 1945. gada 15. janvāra Dienēja 15. ieroču SS artilērijas pulka 3. vieglajā divizionā;
                1945. gada maijā internēts Zviedrijā, atradies Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Pirms aresta dzīvoja Rīgā, strādāja Rīgas 2. alus darītavā;
                Arestēts 1950. gada 28. februārī;
                Notiesāts 1950. gada 22. maijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu un 58. panta 11. punktu
                ar brīvības atņemšanu uz 25 gadiem, tiesību atņemšanu uz 5 gadiem, ar mantas konfiskāciju;
                Ieslodzījumā atradās Karagandas apgabalā;
                Atbrīvots no ieslodzījuma vietas 1955. gada 28. oktobrī;
                Pēc atgriešanās no ieslodzījuma vietas dzīvoja Rīgā un strādāja rūpnīcā “Alus un vīna darītava "Rīga"”
                `,
            ]
        },
        {
            index: 35,
            type: 'textgallery',
            text: `Atvars Kiršteins pēc atgriešanās no labošanas darbu nometnes. Rīga, 1955. gada rudens`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '94.jpg',
            path: 'Content/images/'
        },
        {
            index: 36,
            type: 'linkgallery',
            text: '',
            links: [
                'Šmits-Pētersons, Arnolds',
                'Kamerilovs, Juris',
                'Ziemelis, Vilis'
            ],
            texts: [
                `Dzimis 1922. gadā Jelgavā;
                Miris pēc 1992. gada;
                Dienesta pakāpe: dižkareivis;
                1945. gada pavasarī internēts Zviedrijā;
                1946. gada 25. janvārī izdots PSRS;
                Pirms aresta strādāja par ķīmijas un fizikas skolotāju Rīgā 49. nepilna laika vidusskolā, no 1947. līdz 1950. gadam mācījās Latvijas Valsts universitātē;
                Arestēts 1950. gada 24. martā;
                Notiesāts 1950. gada 17. maijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu un 58. panta 10. punkta 1. daļu 
                ar brīvības atņemšanu uz 25 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradies Magadanā;
                Atbrīvots 1955. gada 6. augustā;
                Strādājis Rīgas pilsētas dezinfekcijas stacijā
                `,
                `Dzimis 1912. gadā Balvos;
                Miris 1974. gadā Rīgā;
                Dienesta pakāpe: virsnieka vietnieks;
                No 1944. gada Latviešu leģiona 15. divīzijas 15. artilērijas pulka 3. vieglajā divizionā;
                Apbalvots ar Vācijas Dzelzs Krusta II šķiru; 
                1945. gada maijā internēts Zviedrijā, atradies Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 15. janvārī izdots PSRS;
                Līdz arestam strādāja fabrikā; 
                Arestēts 1950. gada 22. maijā;
                Notiesāts 1950. gada 14. jūlijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 25 gadiem, tiesību atņemšanu uz 5 gadiem, ar mantas konfiskāciju;
                Atbrīvots 1955. gada 14. oktobrī;
                Dzīvoja Rīgā, strādāja centrālajā konstruktoru tehniskajā birojā (1968. gadā) `,
                `Dzimis 1902. gadā Ventspils apriņķa Ventas pagastā;
                Miris 1970. gadā Ventspilī;
                Dienesta pakāpe: kapteinis;
                1927. gadā beidza Kara skolu;
                1939. gadā – Augstāko kara skolu;
                1940. - 1941. gadā – Sarkanās armijas 24. teritoriālajā strēlnieku korpusā, no kurienes 1941. gada 17. februārī atvaļināts;
                1943.-1945. gadā Latviešu leģiona 15. divīzijas Artilērijas pulka 4. baterijas komandieris;
                1945. gada maijā internēts Zviedrijā, atradies Bekebergas, Ēkšes - Rennesletes un Rinkabijas nometnēs;
                1946. gada 25. janvārī izdots PSRS;
                Līdz arestam dzīvoja Ventspilī, strādāja Ventspils mūzikas vidusskolā par saimniecības daļas vadītāju;
                Arestēts 1950. gada 9. jūnijā;
                PSRS Valsts drošības ministrijas sevišķā apspriede 1950. gada 2. decembrī notiesāja 
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu, 58. panta 10. punktu un 182. panta 1. daļu
                ar brīvības atņemšanu uz 25 gadiem, bez mantas konfiskācijas;
                Ieslodzījumā atradās Komi APSR, Vorkutā; Mordovijas APSR;
                No ieslodzījuma atbrīvots 1959. gada 9. novembrī;
                Dzīvoja Ventspilī un strādāja Ventspils pilsētas rūpkombinātā par strādnieku;
                Ģimene palika Vācijā`
            ]
        },
        {
            index: 37,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Ventspils mūzikas vidusskolas saimniecības daļas vadītājam Vilim Ziemelim izsniegta izziņa par darbu. Ventspils, 1950. gada 9. marts`,
            path: 'Content/images/',
            images: [
                '95.jpg'
            ],
            transcripts: [
                ``
            ]
        },
        {
            index: 38,
            type: 'textgallery',
            text: `Vilis Ziemelis pēc atgriešanās no Vorkutas labošanas darbu nometnes. Ventspils, 1962. gada februāris`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '96.jpg',
            path: 'Content/images/'
        },
        {
            index: 39,
            type: 'documentgallery',
            source: 'Avots: Latvijas Kara muzejs',
            text: `“Es dzīvoju visumā vecās sliedēs. Nekādi notikumi mani neskar. Klusi un vientuļi – tikai dažreiz uz kino. Citur gan nekur, izņ.[emot] arī baznīcā, jo labi dzied koris. Garastāvoklis viduvējs. Veselība turās apmierinoši, jo kopš pavasara slimnīcā neesmu gulējis. Dzīvoju godīgi, nedzeru un nesmēķēju. Nejūtu nekādas vajadzības, bez tam arī ārsts noliedzis. Ceru uz pavasara sauli, tad būs labāki ”

            Viļa Ziemeļa vēstule Līvijai Vītoliņai Zviedrijā. Ventspils, 1964. gada 15. februāris   `,
            path: 'Content/images/',
            images: [
                '97-1.jpg',
                '97-2.jpg',
                '97-3.jpg'
            ],
            transcripts: [
                `15. febr. 64. g.

                Sveicināta, tantiņa!
                
                Novēlot jaunu laimi Jaunā gadā, ļoti pateicos par plašo vēstuli, tagad atkal zinu, kā Jums klājas un kā vispāri dzīvojiet tāļā svešumā. Arī divas kazas vienā autiņā nonākušas laimīgi galā. Apskatījos uz kartes, kur tas ir. Vispārīgi vēroju, ka izskatās labi, apmierināta un labi ģērbta, tā jau iespējams ilgi nodzīvot, ja nebūtu citu rūpju tanī dzīvē.
                Tika mēģināts laist ziņu arī caur Pēteri, lai ietu cauri. Varbūt der arī nedaudz rakstīt. Tāpat mīļš paldies par saņēmumu, tas bija ļoti noderīgs, kaut arī netieši, jo vajadzība jau bija. Bez tam pilnīgi negaidīti, kā pārsteigums. Ko lai dara – no tiem 3 gaidīt, kā viņi atmodīsies – diez’ kas iznāks? Pag. gadā apsveicu Her. ar 50 gadu dzimšanas dienu, tāpat nosūtīju Jaunā gada sveicienus, bet atbildes līdz šim nekādas – ne ziņas, ne miņas. Un viņiem`,
                `taisni varēja palīdzēt, vai arī atsūtīt negatīvu atbildi. Nesaprotami, ar ko īsti es būtu tādu izturēšanos pelnījis. Iznāk tāda ignorēšana. Vai tad cilvēki var uz reizi tik lepni palikt; jābūt kādam iemeslam, ko es nezinu.
                Un pēc kariem pēdējās vēstules taču bija tik jaukas un labas. Un kas bērniem varētu rasties par naidu? Ko es varu vairāk darīt šeit? 
                Tad jau Jānim laika un veselības ir, ja var domāt par vēstures materiāliem.
                Lai labi izdodas šis pasākums.
                Vai Alfrēds būtu Lin. Varētu arī apgaismot, ja braukšu uz to pusi. Ja V. Tik slims, tad jau viņš ilgi neizturēs, jo pats sev dara pāri, tā paklausot madāmiņai. Lai jau puika pats sev sapelna vāģi, ko pabraukāties. 
                Nupat mums uzkrita sniegs un arī stingri piesalst. Tagad sēžu viens savā istabā un tik kurinu krāsni. Temp. turās līdz 15-16 C. Ja darbā iznāk bieža braukšana, tad var jau prasīt tos tramvaja kuponus rokā.
                `,
                `Ja Mārai patīk par skolmeist. – nav jau tik slikti, jo vasara būs brīva. Un iekšā tur viņa tikusi nevis uz laimes, bet gan uz gudrības pamata, jo matematiski iznāk, kā uz iestājas eksāmeniem viņa bijusi labāk par citām.
                Kā gan citādi? Klases draudzene izkrita, jo tanī momentā viņa nemācēja pietiekoši labi atbildēt. (Lai gan vispāri varbūt gudra meitene).
                Vakaros Jums kartoteka un vientulība - draugs. Lai šinī gadā justos laimīgāka, varbūt vajadzētu vienu patiesu draugu, ar kuru parunāt, laiku pavadīt… Dzīve nebūtu tik vienmuļa.
                Es dzīvoju visumā vecās sliedēs. Nekādi notikumi mani neskar. Klusi un vientuļi – tikai dažreiz eju kino. Citur gan nekur, izņ. arī baznīcā, jo labi dzied koris. Gara stāvoklis – viduvējs. Veselība turās apmierinoši, jo kopš pavasara slimnīcā neesmu gulējis. Dzīvoju godīgi, nedzeru un nesmēķēju. Nejūtu nekādas vajadzības, beztam arī ārsts noliedzis. Ceru uz pavasara sauli, tad būs labāki.
                Lai Jums abām raženi klājas!
                Sveicinu Jūsu V.Z. 
                `
            ],
            audio: [
                'Ziemelis4-1.mp3',
                'Ziemelis4-1.mp3',
                'Ziemelis4-1.mp3'
            ]
        },
        {
            index: 40,
            type: 'linkgallery',
            text: '',
            links: [
                'Čikste, Ervīns',
                'Valentīns Silamiķelis'
            ],
            texts: [
                `Dzimis 1906. gadā Ščurovā, Rjazaņas apgabalā, Krievijas impērijā;
                Miris 1996. gadā Rīgā;
                Dienesta pakāpe: virsnieka vietnieks;
                No 1944. gada 21. augusta Latviešu leģiona 15. divīzijas 15. artilērijas pulka 3. vieglajā divizionā;
                1945. gada maijā internēts Zviedrijā, atradies Bekebergas, Ēkšes- Rennesletes un Rinkabijas nometnēs;
                1946. gada 26. janvārī izdots PSRS;
                Līdz arestam dzīvoja Rīgā, strādāja rūpnīcā “Stars”;
                Arestēts 1950. gada 4. oktobrī;
                Notiesāts 1951. gada 3. februārī
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu
                ar brīvības atņemšanu uz 10 gadiem, ar tiesību atņemšanu uz 5 gadiem un ar mantas konfiskāciju;
                Ieslodzījumā atradās Vorkutas labošanas darbu nometnē;
                Atbrīvots 1955. gada 14. oktobrī;
                Dzīvoja Rīgā
                `,
                `Dzimis 1924. gadā Rīgā;
                Miris 2005. gadā Rīgā;
                Dienesta pakāpe: kareivis;
                Vācu okupācijas laikā darbojies jaunatnes organizācijā “Nacionālā Sardze”; 
                1944. gada augustā mobilizēts Latviešu leģionā, dienējis kā sakarnieks 19. ieroču SS grenadieru divīzijas Izlūku bataljonā;
                1945. gada 10. maijā no Kurzemes ar motorlaivu nokļuvis Gotlandē Atradies internēto karavīru nometnēs Havdhemā un Ēkšē - Rennesletē; 
                1946. gada 25. janvārī izdots PSRS; 
                Pēc izdošanas nozīmēts darbā LPSR Iekšlietu ministrijas Šoseju un ceļu pārvaldē;
                1947. gadā iestājies Latvijas Lauksaimniecības akadēmijas Meža fakultātē, bet vēlāk pārgājis uz Latvijas Valsts Universitātes Arhitektūras fakultāti;
                Arestēts 1951. gada 10. augustā;
                Notiesāts 1951. gada 12. novembrī 
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu un 58. panta 10. punktu
                ar brīvības atņemšanu uz 25 gadiem, tiesību atņemšanu uz 5 gadiem, ar mantas konfiskāciju;
                1955. gada 24. jūnijā sods samazināts ar brīvības atņemšanu līdz 10 gadiem, ar tiesību atņemšanu uz 3 gadiem;
                Ieslodzījumā atradies Vorkutā, Komi APSR;
                Atbrīvots 1955. gada 8. oktobrī; 
                Dzīvojis Latvijā: strādājis par celtniecības darbu vadītāju un arhitektu Jelgavā, Dobeles rajonā un Rīgā`,
            ]
        },
        {
            index: 41,
            type: 'textgallery',
            text: `Valentīns Silamiķelis. Pašportrets. Rīga, 1947. gada 1. jūnijs`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '98.jpg',
            path: 'Content/images/'
        },
        {
            index: 42,
            type: 'textgallery',
            text: `Valentīns Silamiķelis Vorkutas labošanas darbu nometnē. 1955. gada marts`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '99.jpg',
            path: 'Content/images/'
        },
        {
            index: 43,
            type: 'textgallery',
            text: `Arhitekts Valentīns Silamiķelis. 20. gs. 60. gadi`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '100.jpg',
            path: 'Content/images/'
        },
        {
            index: 44,
            type: 'textgallery',
            text: `Valentīna Silamiķeļa Vorkutas labošanas darbu nometnes barakas kartīte. 
            Norādīts viņam piespriestā soda galējais termiņš – 1976. gada 10. augusts`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '101.jpg',
            path: 'Content/images/'
        },
        {
            index: 45,
            type: 'textgallery',
            text: `Valentīna Silamiķeļa akvarelis “Perspektīva pirmai projektētai un 1953. gadā izgatavotai pārvietojamai dzīvojamai mājiņai tresta “Pečorugļegeoloģija” ģeologiem – izdzīvojušiem “zekiem””. Vorkutas labošanas darbu nometne, 1954. gads`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '102.jpg',
            path: 'Content/images/'
        },
        {
            index: 46,
            type: 'linkgallery',
            text: '',
            links: [
                'Kaniņš, Jānis-Leons'
            ],
            texts: [
                `Dzimis 1912. gadā Daugavpilī;
                Miris – 1999. gadā Džūkstē;
                Dienesta pakāpe: dižkareivis;
                1945. gada maijā internēts Zviedrijā;
                1946. gada 25. janvārī izdots PSRS;
                Pirms aresta strādāja par sovhoza “Druvas” elektrostacijas motoristu;
                Arestēts 1952. gada 5. aprīlī;
                Notiesāts 1952. gada 23. maijā
                saskaņā ar KPSFR Kriminālkodeksa (1926. gada redakcijā) 58. panta, 1. punkta “a” daļu un 58. panta 11. punktu
                ar brīvības atņemšanu uz 25 gadiem, tiesību atņemšanu uz 5 gadiem, ar mantas konfiskāciju;
                Ieslodzījumā atradās Sahalīnas apgabalā un Habarovskā;
                No ieslodzījuma atbrīvots 1956. gadā;
                Dzīvoja Jēkabpilī
                `
            ]
        },
        {
            index: 47,
            type: 'text',
            text: `Trīs izdotos latviešu karavīrus – Pēteri Kalēju, Ēvaldu Liepiņu un Robertu Ozoliņu deportēja no Latvijas 1949. gada 25. martā. `
        },
    ],
    SECTION5: [
        {
            index: 0,
            type: 'title',
            text: 'Nobeigums',
            subText: 'Izlīgums un piedošana'
        },
        {
            index: 1,
            type: 'quote',
            quotes: [
                {
                    index: 0,
                    text: `“Tas bija briesmīgi!”
                    (Das war schreklich!)`,
                    author: 'Zviedrijas karalis Kārlis XVI Gustavs',
                },
                {
                    index: 1,
                    text: `“Mums, 40 veterāniem – 35 latviešiem, četriem igauņiem un vienam lietuvietim, kuri spējām vēl braukt, loks patiesi bija noslēdzies. Bet aiz katra no mums nāca vēl trīs – mirušo izdoto veļi.”`,
                    author: 'Valentīns Silamiķelis',
                }
            ],
        },
        {
            index: 2,
            type: 'textgallery',
            text: `Izdotie baltiešu karavīri pieņemšanā pie Zviedrijas karaļa. Stokholma, 1994. gada 20. jūnijs
            Priekšplānā no kreisās – Valentīns Silamiķelis un Zviedrijas karalis Kārlis XVI Gustavs`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '103.jpg',
            path: 'Content/images/'
        },
        {
            index: 3,
            type: 'text',
            text: `Trešā atmoda un Latvijas valstiskās neatkarības atjaunošanas process sabiedrībai atnesa atklātību un drosmi: dziedāt aizliegtas dziesmas, izdot un lasīt aizliegtas grāmatas, publiski runāt par neērtiem un sāpīgiem vēstures jautājumiem, kas piecdesmit padomju okupācijas gadus bija noklusēti un apieti. Nu drīkstēja runāt arī par Otrā pasaules kara laikā Vācijas bruņoto spēku rindās dienējušiem latviešu karavīriem, viņu ceļu karagūstekņu nometnēs un padomju labošanas darbu nometnēs. Bijušie karavīri savu stāstu varēja stāstīt skaļi, lai atbrīvotos no dziļi sirdī gruzdošās pagātnes un nodarījuma sajūtas`
        },
        {
            index: 4,
            type: 'textgallery',
            text: `Latviešu leģiona piemiņas dienas gājiens. Rīgas Brāļu kapi, 1991. gada 16. marts 

            Foto: Gunārs Birkmanis`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '104.jpg',
            path: 'Content/images/'
        },
        {
            index: 5,
            type: 'text',
            text: `1990. gada 25. janvārī Arhitektu namā Rīgā pirmo reizi kopš piespiedu atgriešanās okupētajā Latvijā kopā sanāca bijušie Zviedrijā internētie un Padomju Savienībai izdotie latviešu karavīri – nu jau kungi cienījamos gados. Šīs tikšanās kļuva ikgadējas, un turpmāk tās notika atjaunotā Latvijas Kara muzeja telpās. 
            1992. gada 8. septembrī Rīgā viesojās Zviedrijas karalis Kārlis XVI Gustavs un karaliene Silvija. No izdotajiem karavīriem tobrīd dzīvi bija 50. Viņi izteica pateicību Zviedrijas karaļnamam, baznīcai un tautai par atbalstu cīņā pret izdošanu 1945. gada rudenī`
        },
        {
            index: 6,
            type: 'textgallery',
            text: `Zviedrijā internēto un Padomju Savienībai izdoto karavīru piemiņas vakars Arhitektu namā. Rīga, 1990. gada 26. janvāris
            No kreisās: Valentīns Silamiķelis, Jānis Priednieks, Jēkabs Raiskums, Atvars Kiršteins, Elmārs Heics, Jānis Jēkabsons, Jānis Šteinbergs, Jēkabs Balodis`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '105.jpg',
            path: 'Content/images/'
        },
        {
            index: 7,
            type: 'textgallery',
            text: `Valentīns Silamiķelis Rīgas vēstures muzejā. 1990. gada 16. jūnijs`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '106.jpg',
            path: 'Content/images/'
        },
        {
            index: 8,
            type: 'textgallery',
            text: `Zviedrijas izdoto latviešu karavīru tikšanās. Latvijas Kara muzejs, 1992. gada 25. janvāris
            No kreisās: 
            1. rindā: Donāts Udrasols, Augusts Kaņeps, Atvars Kiršteins, Jānis Slaidiņš, Valdis Knoks, Jēkabs Balodis; 
            2. rindā: Valentīns Silamiķelis, Jānis Bergs, Arvīds Kaņeps, Rihards Siliņš, Jānis Šteinbergs, Osvalds Melecs, Sīmanis Gudļevskis; 
            3. rindā: Austrums Vēveris, Jānis Jēkabsons, Teofils Rēdiņš, Herberts Auniņš, Valdis Ruks; 
            4. rindā: Osvalds Bikmanis, Pēteris Legzdiņš, Antons Beļskis, Oļģerts Ābrams, Elmārs Heics, Ēriks Stumbris
            
            Foto: Aivars Reinholds`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '107.jpg',
            path: 'Content/images/'
        },
        {
            index: 9,
            type: 'text',
            highlights: [
                "Oļģerts Ābrams, Herberts Auniņš, Jēkabs Balodis, Antons Beļskis, Jānis Bergs, Jānis Bisenieks, Ojārs Caune, Ervīns Čikste, Kārlis Cimmermanis, Vilis Edulfs, Voldemārs Einis, Sīmanis Gudļevskis, Elmārs Heics, Jānis Jansons, Jānis Jēkabsons, Arvīds Kalniņš, Arvīds Kaņeps, Jānis Kaniņš, Kārlis Kļaviņš, Leonards Kočkers, Oskars Krastiņš, Pēteris Legzdiņš, Boriss Mieme, Antons Minics, Teofils Rēdiņš, Valdis Ruks, Valentīns Silamiķelis, Rihards Siliņš, Staņislavs Skromanis, Jānis Slaidiņš, Arnolds Šmits-Pētersons, Jānis Šteinbergs, Kārlis Stenders, Ēriks Stumbris, Donāts Udrasols; igauņi: Hanss Lindemans (Hans Lindemann),  Johans Pīrimē (Johan Piirimäe), Elmārs Sosi (Elmar Sosi), Elmārs Sūrpere (Elmar Suurpere);",
                "Stasis Dranseika (Stasys Dranseika)"
            ],
            text: `1994. gada 20. – 22. jūnijā 40 bijušie baltiešu karavīri (35 latvieši, četri igauņi un viens lietuvietis), kurus Zviedrijas toreizējā valdība 1946. gadā izdeva Padomju Savienībai, pēc 48 gadiem atgriezās Zviedrijā. 

            Oļģerts Ābrams, Herberts Auniņš, Jēkabs Balodis, Antons Beļskis, Jānis Bergs, Jānis Bisenieks, Ojārs Caune, Ervīns Čikste, Kārlis Cimmermanis, Vilis Edulfs, Voldemārs Einis, Sīmanis Gudļevskis, Elmārs Heics, Jānis Jansons, Jānis Jēkabsons, Arvīds Kalniņš, Arvīds Kaņeps, Jānis Kaniņš, Kārlis Kļaviņš, Leonards Kočkers, Oskars Krastiņš, Pēteris Legzdiņš, Boriss Mieme, Antons Minics, Teofils Rēdiņš, Valdis Ruks, Valentīns Silamiķelis, Rihards Siliņš, Staņislavs Skromanis, Jānis Slaidiņš, Arnolds Šmits-Pētersons, Jānis Šteinbergs, Kārlis Stenders, Ēriks Stumbris, Donāts Udrasols; igauņi: Hanss Lindemans (Hans Lindemann),  Johans Pīrimē (Johan Piirimäe), Elmārs Sosi (Elmar Sosi), Elmārs Sūrpere (Elmar Suurpere); lietuvietis: Stasis Dranseika (Stasys Dranseika) 
            trīs dienu apmeklējuma laikā ar audienci viesojās pie Zviedrijas karaļa Kārļa XVI Gustava, piedalījās pieņemšanā Zviedrijas Ārlietu ministrijā, kur tikās ar ārlietu ministri Margarētu af Uglasi (Margaretha af Ugglas), kura uzsvēra, ka izdošanas lēmums bijis pārsteidzīgs un nepareizs, un valdības vārdā nožēlojot šī lēmuma sekas, kā arī apmeklēja traģiski mirušo latviešu karavīru atdusas vietu pie Ādolfa Frederiha baznīcas Stokholmā un kādreizējās Ēkšes – Rennesletes nometnes vietu`
        },
        {
            index: 10,
            type: 'documentgallery',
            source: 'Avots: Latvijas Nacionālā arhīva Latvijas Valsts arhīvs',
            text: `Izdoto baltiešu karavīru vizītes Zviedrijā programma. Stokholma, 1994. gads
            Tās pirmajā lapaspusē uzskaitīti 40 baltiešu karavīri, kas viesojās Zviedrijā 1994. gada 20. – 22. jūnijā
            `,
            path: 'Content/images/',
            images: [
                '108-1.jpg',
                '108-2.jpg'
            ],
            transcripts: [
                ``
            ]
        },
        {
            index: 11,
            type: 'documentgallery',
            source: ``,
            path: 'Content/tables/',
            images: [
                '4-7.jpg',
                '4-8.jpg'
            ],
            transcripts: [
                ``
            ]
        },
        {
            index: 12,
            type: 'textgallery',
            text: `Izdotie baltiešu karavīri dodas uz pieņemšanu pie Zviedrijas karaļa. Stokholma, 1994. gada 20. jūnijs

            Foto: Aivars Liepiņš`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '109.jpg',
            path: 'Content/images/'
        },
        {
            index: 13,
            type: 'textgallery',
            text: `Izdotie baltiešu karavīri pieņemšanā pie Zviedrijas karaļa. Stokholma, 1994. gada 20. jūnijs
            No kreisās 1.- Jānis Slaidiņš, 2.- Piirimae Jaan, 3.- Valentīns Silamiķelis, 4. Elmar Sosi, 5.- Rihards Siliņš, 6.- Elmar Suurpere, 7.- Hans Lindeman, 9.- Boriss Mieme, 11.- Arvīds Kaņeps, 12.- Leonards Kočkers, 13.- Jānis Kaniņš, 14.- Ojārs Caune, 15.- Jānis Šteinbergs, 16.- Jēkabs Balodis, 21.- Zviedrijas karalis Kārlis XVI Gustavs, 22.- Jānis Bergs, 23.- Antons Beļskis, 25.- Valdis Ruks, 26.- Antons Miničs, 27.- Herberts Auniņš,  28.- Stasys Dranseika, 30.- Kārlis Stenders, 34.- Oļģerts Ābrams, 36.- Elmārs Heics`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '110.jpg',
            path: 'Content/images/'
        },
        {
            index: 14,
            type: 'textgallery',
            text: `Izdotie baltiešu karavīri pieņemšanā Zviedrijas Ārlietu ministrijā. Stokholma, 1994. gada 20. jūnijs

            Foto: Aivars Liepiņš`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '111.jpg',
            path: 'Content/images/'
        },
        {
            index: 15,
            type: 'textgallery',
            text: `Izdotie baltiešu karavīri Ēkšē, ieradušies pēc Zviedrijas valdības uzaicinājuma. 1994. gada 21. jūnijs
            Priekšplānā ar Baltijas karogu, kas ietver triju Baltijas valstu karogu krāsas – Valentīns Silamiķelis, aiz viņa 1. no labās – Oļģerts Ābrams
            
            Foto: Juris Reneslācis`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '112.jpg',
            path: 'Content/images/'
        },
        {
            index: 16,
            type: 'textgallery',
            text: `Igaunijas, Latvijas un Lietuvas karogi, uzvilkti mastā Ēkšes – Rennesletes nometnes vietā. Zviedrija, 1994. gada 21. jūnijs

            Baltajā ēkā atradās virtuve un mītne internētajiem virsniekiem. Šajā ēkā naktī uz 1945. gada 28. novembri pašnāvību izdarīja leitnants Oskars Lapa`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '113.jpg',
            path: 'Content/images/'
        },
        {
            index: 17,
            type: 'textgallery',
            text: `Piemineklis Ādolfa Frederika (Adolf Fredrik) kapos apbedītajiem latviešiem. Stokholma, 1994. gada 20. jūnijs

            Foto: Juris Reneslācis`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '114.jpg',
            path: 'Content/images/'
        },
        {
            index: 18,
            type: 'textgallery',
            text: `Piemiņas akmens Ēkšē, uzstādīts 1996. gada 27. janvārī

            1996. gada 2. janvārī izdotajiem baltiešu karavīriem tika atklāts piemiņas akmens Ēkšē.
            Uz piemiņas akmens rakstīts: “146 igauņu, latviešu un lietuviešu leģionāru piemiņai, kuri meklēja patvērumu Zviedrijā un tika internēti Rennesletes nometnē Otrā pasaules kara beigās, bet tika izdoti Padomju Savienībai 1946. gada janvārī.
            1994. gada vasarā, uz valdības ielūgumu, 40 no šiem vīriem atgriezās Zviedrijā un Ekšē.
            Šis piemiņas akmens radās uz izdoto baltiešu priekšlikumu, lai izteiktu pateicību Ekšē iedzīvotājiem un tiem zviedriem, kuri deva savu atbalstu internēšanas laikā.
            Piemiņas akmens atklāts 1996. gada 27. janvārī.”
            
            Foto: Juris Reneslācis`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '115.jpg',
            path: 'Content/images/'
        },
        {
            index: 19,
            type: 'textgallery',
            text: `Piemiņas akmens atklāšana internēto baltiešu karavīru piemiņai. Ēkše, 1996. gada 27. janvāris
            Aiz pieminekļa stāv grupa bijušo internēto karavīru. 1. rindā no kreisās: Valentīns Silamiķelis, Stasis Dranseika, Jānis Šteinbergs un Elmārs Heics
            
            Foto: Juris Reneslācis`,
            source: 'Avots: Latvijas Kara muzejs',
            image: '116.jpg',
            path: 'Content/images/'
        },
        {
            index: 20,
            type: 'text',
            text: `Otrais pasaules karš izmainīja daudzu valstu, tautu un cilvēku likteņus. Arī to vairāk kā 200 000 latviešu karavīru, kuri bija spiesti karot divu totalitāru lielvaru: Padomju Savienības un Vācijas pusē. Apmēram puse no viņiem palika kaujas laukos, daudzi desmiti tūkstošu nonāca karagūstekņu nometnēs. Daudzus tūkstošus Vācijas bruņotajos spēkos karojošo karavīru kara vētras bija aizrāvušas tālu prom no dzimtenes. Starp viņiem bija arī tie 130 latviešu karavīri, kuri kara beigās nonāca Zviedrijā un kurus 1946. gada 25. janvārī tā izdeva Padomju Savienībai.  
            Karš, kapitulācija, bēgšana, internēšana, cerības, brīvība, neziņa, gribasspēks, cīņasspars, atbalsts, vilšanās, izdošana, aresti, tiesas, sodi, cietumi, izdzīvošana, samierināšanās, klusēšana, atmoda, piedošana, atgriešanās, izlīgums – atslēgvārdi, kas raksturo bijušo Zviedrijā internēto un no tās Padomju Savienībai izdoto latviešu karavīru likteni gandrīz piecdesmit gadus ilgā laika posmā. 
            Latviešu karavīru izdošana no Zviedrijas Padomju Savienībai ir viens no traģiskākajiem stāstiem par Latvijas iedzīvotāju likteņiem pēc Otrā pasaules kara beigām. Karavīri uz Zviedriju bija devušies ar mērķi – nonākt tālāk pasaulē un nekad neatgriezties PSRS. Tomēr viņu izsapņotais sapnis būt brīviem lēnām sabruka: Zviedrijā viņus turēja internēto nometnēs aiz dzeloņdrātu žoga. Izdošana no Zviedrijas latviešu karavīrus atveda atpakaļ uz zemi, no kuras viņi bija bēguši.
            Viņu stāsts ir stāsts par salauztiem likteņiem un brūcēm, kas turpināja dzīt vēl daudzus gadu desmitus pēc notikušā`
        },
    ],
    SECTION6: [
        {
            index: 0,
            type: 'title',
            text: 'Par izstādi',
            subText: 'Latvijas Kara muzeja sadarbībā ar Latvijas Nacionālo arhīvu sagatavotā virtuālā izstāde “Zaudētais sapnis par brīvību. Zviedrijas valdības Padomju Savienībai izdoto latviešu karavīru stāsts” stāsta par 1946. gada 25. janvārī no Zviedrijas Padomju Savienībai izdotajiem latviešu karavīriem – bijušajiem leģionāriem: viņu gūstu Zviedrijā, izdošanu Padomju Savienībai un dzīvi pēc izdošanas'
        },
        {
            index: 1,
            type: 'creators',
            title: 'Izstādes veidotāji',
            items: [
                {
                    title: 'Dokumentu atlase, Teksti',
                    text: 'Ilze Jermacāne, Latvijas Kara muzejs'
                },
                {
                    title: 'Grafiku autors',
                    text: 'Jānis Tomaševskis, Latvijas Kara muzejs'
                },
                {
                    title: 'Kartes zīmējusi',
                    text: 'Ieva Lapiņa, Latvijas Kara muzejs'
                },
                {
                    title: 'Vēstules ierakstījis',
                    text: 'Klāvs Zariņš, Latvijas Kara muzejs'
                },
                {
                    title: 'Vēstules ierunājuši',
                    text: 'Dagnis Dedumietis, Roberts Līnis, Ainis Lociks, Mārtiņš Mitenbergs, Jānis Tomaševskis, Marjus Zaļeckis, Klāvs Zariņš, Latvijas Kara muzejs'
                },
                {
                    title: 'Izstrādātāji',
                    text: 'SIA Thinkitive Lab'
                },
                {
                    title: 'Izstādes rīkotāji',
                    text: 'Latvijas Kara muzejs sadarbībā ar Latvijas Nacionālo arhīvu'
                }
            ]
        },
        {
            index: 2,
            type: 'title',
            text: 'Pateicības',
            subText: `Liels paldies Latvijas Nacionālā arhīva, Latvijas Valsts arhīva un Latvijas Kinofotofonodokumentu arhīva vadībai un lasītavu darbiniekiem par atbalstu izstādes tapšanā, jo īpaši Mārai Sprūdžai, Gatim Karlsonam, Lindai Stūrītei, Ivitai Bērziņai, Valdim Ozolam, Dacei Bušantei, Anitai Trušelei. Vēlamies teikt paldies arī Latvijas Okupācijas muzejam, tā direktorei Sovitai Vībai un galvenajai krājuma glabātājai Taigai Koknevičai.
            Īpašs paldies visiem cilvēkiem, kuri ir glabājuši liecības par Zviedrijas izdotajiem latviešu karavīriem un kuri kopš Latvijas Kara muzeja atjaunošanas tās ir dāvinājuši muzejam. Īpašs paldies Mārai Trēdei (Zviedrija) par viņas devumu izdoto leģionāru piemiņas saglabāšanā un Latvijas kara muzeja krājuma papildināšanā`
        },
        {
            index: 3,
            type: 'title',
            text: 'Izmantotie avoti',
            subText: `Latvijas Nacionālā arhīva Latvijas Valsts arhīvs
            1986. fonds (Latvijas PSR Valsts drošības komitejas (VDK) par sevišķi bīstamiem pretvalstiskiem noziegumiem apsūdzēto personu krimināllietas): 
            1. apraksts, 630. lietas (Elmāra Eihfūsa Atvara krimināllieta) uzraudzības lieta, 99., 99. o. p. lapa;
            1. apraksts, 789. lietas (Kārļa Gailīša un Manfrēda Liepiņa krimināllieta) uzraudzības lieta, 104.–106. lapa;
            1. apraksts, 840. lietas (Ernesta Kessela (Ķeseļa) krimināllieta) uzraudzības lieta, 132., 133., 146. lapa;
            1. apraksts, 1132. lieta (Arvīda Kaņepa krimināllieta), 35., 35. o. p. lapa;
            1. apraksts, 1916. lietas (Pauļa Lielkāja krimināllieta) uzraudzības lieta, 29. lapa;
            1. apraksts, 1951. lietas (Arnolda Līča krimināllieta) 9. sējums, 132., 133. lapa;
            1. apraksts, 28635. lieta (Pētera Apkalna krimināllieta), uzraudzības lietas 3. sējums, 352., 353. lapa;
            1. apraksts, 29045. lietas (Gustava Vilka krimināllieta) uzraudzības lieta, 32., 32. o. .p. lapa:
            1. apraksts, 39308. lietas (Viļa Ziemeļa krimināllieta) uzraudzības lieta, 103. lapa;
            2. apraksts, P–11084. lieta (Atvara Kiršteina krimināllieta), 164. lapa.
            
            Latvijas Nacionālā arhīva Latvijas Valsts Kinofotofonodokumentu arhīvs
            203. fonds, 1. apraksts, 5331. lieta (Filma “Baltiešu izdošana” (“Baltutlämningen”). 1946. gads, Zviedrija
            
            Latvijas Kara muzeja krājums
            Fotogrāfijas:
            LKM 5-1048/47-FT, LKM 5-1049/48-FT, LKM 5-6850/568-FT, LKM 5-9343/677-FT, LKM 5-9346/680-FT (1), LKM 5-9364/698-FT, LKM 5-9366/700-FT, LKM 5-9368/702-FT, LKM 5-12104/1090-FT, LKM 5-23010/1784-FT, LKM 5-37470/1989-FT, LKM 5-37471/1990-FT, LKM 5-37475/1994-FT, LKM 5-59976-3026-FT, LKM 5-60657/3075-FT, LKM 5-60661/3079-FT, LKM 5-60689/3085-FT, LKM 4-57749/13052-FT, LKM 5-99-FT/p, LKM 5-101-FT/p, LKM 5-107-FT/p, LKM 5-108-FT/p (1), LKM 5-232-FT/p (1), LKM 5-235 FT/p, LKM 5-249-FT/p (1), LKM 5-730-FT/p, LKM 5-734-FT/p, LKM 5-737-FT/p, LKM 5-948-FTp, LKM 5-1284-FT/p,  LKM 5-1285-FT/p, LKM 5-1286-FT/p, LKM 5-1292-FT/p, LKM 5-1294-FT/p, LKM 5-1295-FT/p, LKM 5-1298-FT/p, LKM 5-1362-FT/p, LKM 5-1401-FTp, LKM 5-1431-FT/p, LKM 5-1436-FT/p, LKM 5-1741-FT/p, LKM 5-1746-FT/p, LKM 5-1752-FT/p, LKM 5-1761-FT/p, LKM 5-1783-FT/p, LKM 5-1786-FT/p, LKM 5-1787-FT/p, LKM 5-1877-FT/p, LKM 5-1880-FT/p, LKM 5-1892-FT/p, LKM 5-1895-FT/p, LKM 5-2052 FT/p, LKM 5-2119 FT/p, LKM 5-63315/4546-FN (3), LKM 5-63315/4546-FN (14), LKM 5-63316/4547-FN (8), LKM 5-63511/4548-FN (1), LKM 5-63511/4548-FN (5), LKM 5-63511/4548-FN (17), LKM 5-63511/4548-FN (18), LKM 5-63512/ 4549-FN (24), LKM 5-63512/4549-FN (33), LKM 5-63512/4549-FN (34), LKM 5-63512/4549-FN (38), LKM 6-61040/1251-VA
            Dokumenti:
            LKM 5-2801/219-DK, LKM 5-2804/222-DK, LKM 5-6713/469-DK, LKM 5-6737/471-DK, LKM 5-6739/473-DK, LKM 5-6813/484-DK, LKM 5-22988/1439-DK, LKM 5-22989/1440-DK, LKM 5-22991/1442-DK, LKM 5-22993-1444-DK, LKM 5-22994-1445-DK, LKM 5-22995-1446-DK, LKM 5-23005/1456-DK, LKM 5-23006/1457-DK, LKM 5-56751/2878-DK, LKM 5-59940-3032-DK, LKM 5-59941/3033-DK, LKM 5-59942/3034-DK, LKM 5-59943/3035-DK, LKM 5-59945/3037-DK, LKM 5-59955/3047-DK, LKM 5-59956/3048-DK, LKM 5-59960/3052-DK, LKM 5-59963-3055-DK, LKM 5-59970/3062-DK, LKM 5-59972/3064-DK, LKM 5-59973/3065-DK, LKM 5-59974/3066-DK, LKM 5-59979/3068-DK, LKM 5-60615/3093-DK, LKM 5-60623/3101-DK, LKM 5-173-DK/p, LKM 5-272-DK/p, LKM 5-945-DK/p, LKM 5-1272-DK/p
            Priekšmeti:
            LKM 5-4212-189-LP
            
            Latvijas Okupācijas muzeja krājums
            Fotogrāfijas: OMF 15087; OMF/p 2888
            Dokuments: OMF 5685/4
            
            Izstādē izmantotas Gunāra Birkmaņa, Aivara Liepiņa, Aivara Reinholda, Jura Reneslāča un arī nezināmu autoru fotogrāfijas`
        },
    ]
};

export default Config;