import React, { useState, useEffect, useCallback, useMemo } from "react";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import Colors from "../Colors";
import { AnimatePresence, motion } from 'framer-motion';
import Utilities from './../Utilities';

import LettersAudioPlayer from "./LettersAudioPlayer";

import leftArrow from '../../Assets/left_arrow.png';
import rightArrow from '../../Assets/right_arrow.png';
import letterIcon from '../../Assets/letterIcon.png';
import openLetterIcon from '../../Assets/openLetterIcon.png';
import openQuoteIcon from '../../Assets/chat-quote.png';
import closeIcon from '../../Assets/closeIcon.png';
import soundIcon from '../../Assets/soundwave.png';
import closeAudioIcon from '../../Assets/x.png';

const ActiveOverlay = {
    None: 0,
    Transcript: 1,
    Quote: 2,
    Audio: 3,
};

const LettersGallery = ({ item, activeIndex = 0, onClose, autoPlay }) => {

    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    const [containerHeight, setContainerHeight] = useState('100%');

    const [loadedImageCount, setLoadedImageCount] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const [images, setImages] = useState(item.images ? item.images : (item.letters ? item.letters : [item.image]));
    const [currentIndex, setCurrentIndex] = useState(activeIndex);

    const [activeTranscript, setActiveTranscript] = useState(null);
    const [activeQuote, setActiveQuote] = useState(null);
    const [activeAudio, setActiveAudio] = useState(null);

    const getAudioPath = (audioFileName) => {
        if (!audioFileName)
            return
        try {
            return require(`../../Content/audio/${audioFileName}`);
        } catch (e) {
            console.error("Error loading audio file:", e);
            return "";
        }
    };
    const audioSrc = useMemo(() => getAudioPath(activeAudio), [activeAudio]);

    const [activeOverlay, setActiveOverlay] = useState(ActiveOverlay.None);
    const activeLetterIcon = activeOverlay == ActiveOverlay.Transcript ? openLetterIcon : letterIcon
    const activeAudioIcon = activeOverlay == ActiveOverlay.Audio ? closeAudioIcon : soundIcon

    useEffect(() => {
        if (autoPlay) {
            setActiveOverlay(ActiveOverlay.Audio)
        }
    }, [autoPlay]);

    useEffect(() => {
        const activeQuote = item.quotes ? item.quotes[currentIndex] : null
        const activeTranscript = item.transcripts[currentIndex]
        const newActiveAudio = item.audio ? item.audio[currentIndex] : null

        if (activeQuote && activeQuote.length > 0) {
            setActiveQuote(activeQuote)
        } else {
            setActiveQuote(null)

            if (activeOverlay == ActiveOverlay.Quote) {
                setActiveOverlay(ActiveOverlay.None)
            }
        }

        if (activeTranscript && activeTranscript.length > 0) {
            setActiveTranscript(activeTranscript)
        } else {
            setActiveTranscript(null)

            if (activeOverlay == ActiveOverlay.Transcript) {
                setActiveOverlay(ActiveOverlay.None)
            }
        }

        if (activeAudio != newActiveAudio) {
            setActiveAudio(newActiveAudio)
        }

    }, [currentIndex]);

    useEffect(() => {
        const calculateHeight = () => {
            const safeAreaInsetBottom = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('safe-area-inset-bottom'), 10) || 0;
            const newHeight = window.innerHeight - safeAreaInsetBottom;
            setContainerHeight(`${newHeight}px`);
        };

        window.addEventListener('resize', calculateHeight);
        calculateHeight();

        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, []);

    useEffect(() => {
        if (loadedImageCount === images.length) {
            setImagesLoaded(true);
        }
    }, [loadedImageCount, images.length]);

    const handleImageLoad = () => {
        setLoadedImageCount(prevCount => prevCount + 1);
    };

    const handleNext = () => {
        const nextIndex = currentIndex < images.length - 1 ? currentIndex + 1 : 0;
        setCurrentIndex(nextIndex);
    };

    const handlePrevious = () => {
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
        setCurrentIndex(prevIndex);
    };

    const getImagePath = (imageName) => {
        try {
            if (item.letterIndex) {
                return require(`../../${item.path}${item.letterIndex}/${imageName}`);
            } else {
                return require(`../../${item.path}${imageName}`);
            }
        } catch (e) {
            console.error("Error loading image:", e);
            return "";
        }
    };

    const switchTranScript = () => {
        if (activeOverlay == ActiveOverlay.Transcript) {
            setActiveOverlay(ActiveOverlay.None)
        } else {
            setActiveOverlay(ActiveOverlay.Transcript)
        }
        const activeTranscript = item.transcripts[currentIndex]
        if (activeTranscript && activeTranscript.length > 0) {
            setActiveTranscript(activeTranscript);
        } else {
            setActiveTranscript(null);
        }
    }

    const switchQuote = () => {
        if (activeOverlay == ActiveOverlay.Quote) {
            setActiveOverlay(ActiveOverlay.None)
        } else {
            setActiveOverlay(ActiveOverlay.Quote)
        }

        const activeQuote = item.quotes[currentIndex]
        if (activeQuote && activeQuote.length > 0) {
            setActiveQuote(activeQuote);
        } else {
            setActiveQuote(null);
        }
    }

    const switchAudio = () => {
        if (activeOverlay != ActiveOverlay.Audio) {
            setActiveOverlay(ActiveOverlay.Audio)
        } else {
            setActiveOverlay(ActiveOverlay.None)
            return;
        }

        const activeAudio = item.audio[currentIndex]
        if (activeAudio) {
            setActiveAudio(activeAudio);
        } else {
            setActiveAudio(null);
        }
    }

    const getVariants = () => ({
        initial: { opacity: 0.95 },
        center: { opacity: 1 },
        exit: { opacity: 0.95 },
    });

    return (
        <div className={classes.container}
            style={{ height: containerHeight }}
            onClick={() => onClose()}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={classes.fullscreenContainer}>
                <div
                    className={classes.galleryContainer}
                    onClick={(e) => { e.stopPropagation() }}
                    onMouseEnter={() => setIsHovered(false)}
                    onMouseLeave={() => setIsHovered(true)}
                >
                    <SwipeableViews index={currentIndex} onChangeIndex={(index) => setCurrentIndex(index)}>
                        {images.map((img, idx) => (
                            <div key={idx} className={classes.slide}>
                                <img
                                    src={getImagePath(img)}
                                    alt={`Slide ${idx + 1}`}
                                    style={{ width: "100%", height: 'auto', maxHeight: '100%' }}
                                    onLoad={handleImageLoad}
                                />
                            </div>
                        ))}
                    </SwipeableViews>
                    {(activeOverlay != ActiveOverlay.None) && (
                        activeOverlay === ActiveOverlay.Audio && audioSrc != null ? (
                            <div className={classes.audioPlayer}>
                                <LettersAudioPlayer
                                    src={audioSrc}
                                />
                            </div>
                        ) : (
                            <div className={classes.overlay}>
                                <p className={classes.overlayText}>
                                    {Utilities.processQuotedText(activeOverlay === ActiveOverlay.Transcript ? activeTranscript : activeQuote)}
                                </p>
                            </div>
                        )
                    )}
                    <button className={`${classes.closeButton} ${(isHovered && activeOverlay == ActiveOverlay.None) ? classes.highlighted : ''}`}
                        onClick={(e) => { e.stopPropagation(); onClose(); }}
                    >
                        <img src={closeIcon} alt="Next" className={classes.closeIcon} />
                    </button>

                </div>
                {images.length > 1 && imagesLoaded && (
                    <>
                        <button className={`${classes.arrowButton} ${classes.leftArrow}`}
                            onClick={(e) => { e.stopPropagation(); handlePrevious(); }}
                            onMouseEnter={() => setIsHovered(false)}
                            onMouseLeave={() => setIsHovered(true)}
                        >
                            <img src={leftArrow} alt="Previous" className={classes.arrowImage} />
                        </button>
                        <button className={`${classes.arrowButton} ${classes.rightArrow}`}
                            onClick={(e) => { e.stopPropagation(); handleNext(); }}
                            onMouseEnter={() => setIsHovered(false)}
                            onMouseLeave={() => setIsHovered(true)}
                        >
                            <img src={rightArrow} alt="Next" className={classes.arrowImage} />
                        </button>
                    </>
                )}
            </div>
            <div className={classes.buttonWrapper}>
                {(activeTranscript && activeTranscript.length > 1) && (
                    <div className={classes.button}
                        onClick={(e) => { e.stopPropagation(); switchTranScript(); }}
                        onMouseEnter={() => setIsHovered(false)}
                        onMouseLeave={() => setIsHovered(true)}
                    >
                        <div className={`${classes.line} ${classes.topLine}`} />
                        <div className={classes.buttonContainer}>
                            <div>
                                <img src={activeLetterIcon} alt="letter icon" className={classes.icon} />
                                <span className={classes.buttonText}>{activeOverlay == ActiveOverlay.Transcript ? "Skatīt vēstuli" : "Skatīt tekstu"}</span>
                            </div>
                        </div>
                        <div className={`${classes.line} ${classes.bottomLine}`} />
                    </div>
                )}
                {(activeQuote && activeQuote.length > 1) && (
                    <div className={classes.button}
                        onClick={(e) => { e.stopPropagation(); switchQuote(); }}
                        onMouseEnter={() => setIsHovered(false)}
                        onMouseLeave={() => setIsHovered(true)}
                    >
                        <div className={`${classes.line} ${classes.topLine}`} />
                        <div className={classes.buttonContainer}>
                            <div>
                                <img src={openQuoteIcon} alt="letter icon" className={classes.icon} />
                                <span className={classes.buttonText}>{activeOverlay == ActiveOverlay.Quote ? "Skatīt vēstuli" : "Skatīt citātu"}</span>
                            </div>
                        </div>
                        <div className={`${classes.line} ${classes.bottomLine}`} />
                    </div>
                )}
                {(activeAudio) && (
                    <div className={classes.button}
                        onClick={(e) => { e.stopPropagation(); switchAudio(); }}
                        onMouseEnter={() => setIsHovered(false)}
                        onMouseLeave={() => setIsHovered(true)}
                    >
                        <div className={`${classes.line} ${classes.topLine}`} />
                        <div className={classes.buttonContainer}>
                            <img src={activeAudioIcon} alt="sound icon" className={classes.soundIcon} />
                            <span className={classes.buttonText}>{activeOverlay == ActiveOverlay.Audio ? "Aizvērt audio" : "Atskaņot"}</span>
                        </div>
                        <div className={`${classes.line} ${classes.bottomLine}`} />
                    </div>
                )}
            </div>
        </div >
    );
};

const useStyles = makeStyles({
    container: {
        position: 'relative',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
    },
    fullscreenContainer: {
        position: 'relative',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
    },
    galleryContainer: {
        width: '50%',
        height: 'auto',
        maxHeight: '90%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-between',
        flexDirection: 'column',

        '@media (max-width: 600px)': {
            width: '90%',
        }
    },
    slideContainer: {
        width: '100%',
        height: '100%',
    },
    slide: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrowButton: {
        position: 'absolute',
        top: '50%',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    leftArrow: {
        left: 20,
    },
    rightArrow: {
        right: 20,
    },
    arrowImage: {
        width: '60px',
        height: '60px',
        '@media (max-width: 600px)': {
            width: '40px',
            height: '40px',
        }
    },
    button: {
        background: 'none',
        color: Colors.textColor,
        padding: 10,
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        height: '30px',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    highlighted: {
        transform: 'scale(0.9)',
        opacity: 0.7
    },
    buttonText: {
        color: Colors.textColor,
        fontSize: 16,
        fontWeight: 400,
        fontFamily: 'RedHatText',
    },
    icon: {
        marginRight: 15,
        width: 24,
        height: 18,
    },
    quoteIcon: {
        marginRight: 15,
        width: 24,
        height: 24
    },
    soundIcon: {
        marginRight: 5,
        width: 24,
        height: 24
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        zIndex: 10,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
    },
    overlayText: {
        padding: 20,
        lineHeight: 1.5,
        color: Colors.textColor,
        fontSize: 18,
        fontWeight: 500,
        fontFamily: 'RedHatText',
        whiteSpace: 'pre-line',
        '@media (max-width: 400px), (max-height: 400px)': {
            fontSize: 12,
        },
        '@media (max-width: 600px), (max-height: 500px)': {
            fontSize: 14,
        },
        '@media (min-width: 601px) and (max-width: 1200px), (min-height: 501px) and (max-height: 900px)': {
            fontSize: 16,
        },
        '@media (min-width: 1201px), (min-height: 901px)': {
            fontSize: 22,
        },
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: -50,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        },
        '@media (max-width: 600px)': {
            backgroundColor: 'black',
            top: 0,
            right: 0,
            borderRadius: 20
        }
    },
    closeIcon: {
        width: 40,
        height: 40
    },
    quoteButton: {
        marginLeft: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: '0 20px',
        boxSizing: 'border-box'
    },
    audioPlayer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }
});

export default LettersGallery;
