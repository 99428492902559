import React from 'react';

function Sidebar({ setActiveSection }) {
    return (
        <div className="sidebar">
            <button onClick={() => setActiveSection('home')}>Home</button>
            <button onClick={() => setActiveSection('section1')}>Section 1</button>
        </div>
    );
}

export default Sidebar;