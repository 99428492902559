import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import left from '../Assets/left.png'
import right from '../Assets/right.png'
import Colors from './Colors';
import Config from '../Config';

const ExhibitionContainer = ({ children, onLeftClick, onRightClick }) => {
    const classes = useStyles();
    const [leftButtonHighlighted, setLeftButtonHighlighted] = useState(false);
    const [rightButtonHighlighted, setRightButtonHighlighted] = useState(false);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'ArrowLeft') {
                onLeftClick();
            } else if (event.key === 'ArrowRight') {
                onRightClick();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [onLeftClick, onRightClick]);

    return (
        <div className={classes.container}>
            <div className={`${classes.line} ${classes.leftLine}`}></div>
            <div className={classes.content}>
                {children}
            </div>
            <div className={`${classes.line} ${classes.rightLine}`}></div>
            <div className={classes.touchAreaLeft}
                onClick={onLeftClick}
                onMouseEnter={() => setLeftButtonHighlighted(true)}
                onMouseLeave={() => setLeftButtonHighlighted(false)}
            >
                    <button className={`${classes.button} ${classes.leftButton} ${leftButtonHighlighted ? classes.highlighted : ''}`}>
                    <img src={left} alt="Left" className={classes.buttonImage} />
                    <span className={classes.buttonText}>{Config.BACK_TITLE}</span>
                </button>
            </div>
            <div className={classes.touchAreaRight}
                onClick={onRightClick}
                onMouseEnter={() => setRightButtonHighlighted(true)}
                onMouseLeave={() => setRightButtonHighlighted(false)}
            >
                    <button className={`${classes.button} ${classes.rightButton} ${rightButtonHighlighted ? classes.highlighted : ''}`}>
                    <img src={right} alt="Right" className={classes.buttonImage} />
                    <span className={classes.buttonText}>{Config.FORWARD_TITLE}</span>
                </button>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '65%',
        '@media (max-width: 600px)': {
            width: '95%',
        },
    },
    content: {
        zIndex: 1,
        marginLeft: 20,
        marginRight: 20,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: (props) => props.contentAlignment,
        alignItems: 'center',
        width: '100%',
        minHeight: '100px',
        maxHeight: 'calc(100% - 110px)',
        overflow: 'scroll',
        marginBottom: '80px',
        marginTop: '30px',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    },
    line: {
        position: 'absolute',
        top: 0,
        bottom: '50px',
        width: '1px',
        background: `linear-gradient(transparent, ${Colors.lineColor}, transparent)`,
    },
    leftLine: {
        left: 0,
    },
    rightLine: {
        right: 0,
    },
    buttonContainer: {
        position: 'absolute',
        bottom: '20px',
    },
    touchAreaLeft: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '50%', // Adjust the width as needed
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        cursor: 'pointer',
    },
    touchAreaRight: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '50%', // Adjust the width as needed
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        cursor: 'pointer',
    },
    button: {
        position: 'absolute',
        bottom: '0px',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
        padding: 0,
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    highlighted: {
        transform: 'scale(0.9)',
        opacity: 0.7
    },
    leftButton: {
        right: '10px',
    },
    rightButton: {
        left: '10px',
    },
    buttonText: {
        marginTop: '5px',
        color: Colors.textColor,

        // Media query for small screens/devices
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },

        // Media query for medium screens/devices
        [theme.breakpoints.up('md')]: {
            fontSize: '18px',
        },

        // Media query for large screens/devices
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px',
        },
        fontFamily: 'RedHatText',
        fontWeight: 400
    },
    buttonImage: {
        width: '30px',
        height: '18px',
    },
}));

export default ExhibitionContainer;
