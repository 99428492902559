import React, { useState, useEffect, useRef } from "react";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';

import leftArrow from '../Assets/left_arrow.png';
import rightArrow from '../Assets/right_arrow.png';
import closeIcon from '../Assets/closeIcon.png';

const FullScreenGallery = ({ item, activeIndex, onClose }) => {

    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    const [loadedImageCount, setLoadedImageCount] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const [images, setImages] = useState(item.images ? item.images : [item.image]);
    const [text, setText] = useState(item.texts ? item.texts[activeIndex] : null);
    const [currentIndex, setCurrentIndex] = useState(activeIndex);

    useEffect(() => {
        if (loadedImageCount === images.length) {
            setImagesLoaded(true);
        }
    }, [loadedImageCount, images.length]);

    const handleImageLoad = () => {
        setLoadedImageCount(prevCount => prevCount + 1);
    };

    const handleNext = () => {
        const nextIndex = currentIndex < images.length - 1 ? currentIndex + 1 : 0;
        setCurrentIndex(nextIndex);
    };

    const handlePrevious = () => {
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
        setCurrentIndex(prevIndex);
    };

    const getImagePath = (imageName) => {
        try {
            return require(`../${item.path}${imageName}`);
        } catch (e) {
            console.error("Error loading image:", e);
            return "";
        }
    };

    return (
        <div className={classes.fullscreenContainer}
            onClick={onClose}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className={classes.galleryContainer} >
                {text ? (
                    <div className={classes.textSlide}>
                        <div className={classes.text}
                            onClick={(e) => { e.stopPropagation(); }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            {text}
                        </div>
                    </div>
                ) : (
                    <SwipeableViews index={currentIndex} onChangeIndex={(index) => setCurrentIndex(index)}>
                        {images.map((img, idx) => (
                            <div key={idx} className={classes.slide}>
                                <img
                                    src={getImagePath(img)}
                                    alt={`Slide ${idx + 1}`}
                                    style={{ width: "100%", height: 'auto', maxHeight: '100vh', objectFit: 'contain' }}
                                    onLoad={handleImageLoad}
                                />
                            </div>
                        ))}
                    </SwipeableViews>
                )}
                <button
                    className={`${classes.closeButton} ${isHovered ? classes.highlighted : ''}`}
                    onClick={onClose}
                >
                    <img src={closeIcon} alt="Close" className={classes.closeIcon} />
                </button>
                {images.length > 1 && imagesLoaded && (
                    <>
                        <button
                            className={`${classes.arrowButton} ${classes.leftArrow}`}
                            onClick={(e) => { e.stopPropagation(); handlePrevious(); }}
                            onMouseEnter={() => setIsHovered(false)}
                            onMouseLeave={() => setIsHovered(true)}
                        >
                            <img src={leftArrow} alt="Previous" className={classes.arrowImage} />
                        </button>
                        <button className={`${classes.arrowButton} ${classes.rightArrow}`}
                            onClick={(e) => { e.stopPropagation(); handleNext(); }}
                            onMouseEnter={() => setIsHovered(false)}
                            onMouseLeave={() => setIsHovered(true)}
                        >
                            <img src={rightArrow} alt="Next" className={classes.arrowImage} />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    fullscreenContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
    },
    galleryContainer: {
        width: '85vw',
        height: '90vh',
        position: 'relative',
    },
    slideContainer: {
        width: '100%',
        height: '100%',
    },
    slide: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textSlide: {
        margin: 50,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrowButton: {
        position: 'absolute',
        top: '50%',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    leftArrow: {
        left: '-40px',
    },
    rightArrow: {
        right: '-40px',
    },
    arrowImage: {
        width: '60px',
        height: '60px',
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: -50,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        },

        '@media (max-width: 600px)': {
            backgroundColor: 'black',
            top: 0,
            right: 0,
            borderRadius: 20
        }
    },
    highlighted: {
        transform: 'scale(0.9)',
        opacity: 0.7
    },
    closeIcon: {
        width: 40,
        height: 40
    },
    text: {
        fontSize: 22,
        fontWeight: 500,
        fontFamily: 'RedHatText',
    }
});

export default FullScreenGallery;
