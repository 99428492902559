const Colors = {
    textColor: 'white',
    backgroundColor: '#00000080',
    subTitleColor: '#cfcfcf',
    link: '#569CD6'
}

Colors.sideBarBackgroundColor = hexToRgba('black', 0.7);
Colors.videoOverlayColor = hexToRgba('black', 0.8);
Colors.homeSeparatorColor = hexToRgba('white', 0.5);
Colors.lineColor = hexToRgba('white', 0.3);

function hexToRgba(color, opacity) {
    let r, g, b;

    if (/^[a-zA-Z]+$/.test(color)) {
        switch(color.toLowerCase()) {
            case 'white':
                r = 255;
                g = 255;
                b = 255;
                break;
            case 'black':
                r = 0;
                g = 0;
                b = 0;
                break;
            default:
                console.error('Unknown color name');
                return '';
        }
    } 
    else if (/^#[a-fA-F0-9]{6}$/.test(color)) {
        r = parseInt(color.slice(1, 3), 16);
        g = parseInt(color.slice(3, 5), 16);
        b = parseInt(color.slice(5, 7), 16);
    } 
    else {
        console.error('Unhandled color format');
        return '';
    }

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export default Colors;