import React, { useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import './audioPlayer.css';

const LettersAudioPlayer = React.memo(({ src }, ) => {
    const playerRef = useRef(null);

    return (
        <AudioPlayer
            autoPlay
            ref={playerRef}
            src={src}
        />
    );
});

export default LettersAudioPlayer;
