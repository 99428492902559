import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';

const useStyles = makeStyles({
    textContainer: {
        fontFamily: 'RedHatText',
        color: Colors.textColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    textStyle: {
        fontSize: 50,
        fontWeight: 'bold',
        textAlign: 'center',
        whiteSpace: 'pre-line'
    },
    subTextStyle: {
        marginTop: 10,
        fontSize: 25,
        fontWeight: 600,
        fontStyle: 'regular',
        textAlign: 'center',
        whiteSpace: 'pre-line',
        '@media (max-width: 600px)': {
            fontSize: 20,
        }
    }
});

const Title = ({ text, subText }) => {
    const classes = useStyles();
    return (
        <div className={classes.textContainer}>
            <div className={classes.textStyle}>{text}</div>
            <div className={classes.subTextStyle}>{subText}</div>
        </div>
    );
};

export default Title;
