import React, { useState, useEffect, useRef } from "react";
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';

import leftArrow from '../Assets/left_arrow.png';
import rightArrow from '../Assets/right_arrow.png';
import closeIcon from '../Assets/closeIcon.png';

const FullScreenTextGallery = ({ item, activeIndex, onClose }) => {

    const classes = useStyles();
    const [texts, setTexts] = useState(item.links);

    const [currentIndex, setCurrentIndex] = useState(activeIndex);

    const handleNext = () => {
        const nextIndex = currentIndex < texts.length - 1 ? currentIndex + 1 : 0;
        setCurrentIndex(nextIndex);
    };

    const handlePrevious = () => {
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : texts.length - 1;
        setCurrentIndex(prevIndex);
    };

    return (
        <div className={classes.fullscreenContainer} onClick={onClose}>
            <div className={classes.galleryContainer}>
                <SwipeableViews index={currentIndex} onChangeIndex={(index) => setCurrentIndex(index)}>
                    {texts.map((text, idx) => (
                        <div key={idx} className={classes.textSlide}>
                            <div className={classes.text}>
                                {text}
                            </div>
                        </div>
                    ))}
                </SwipeableViews>
                <button className={classes.closeButton} onClick={onClose}>
                    <img src={closeIcon} alt="Next" className={classes.closeIcon} />
                </button>
                {texts.length > 1 && (
                    <>
                        <button className={`${classes.arrowButton} ${classes.leftArrow}`} onClick={handlePrevious}>
                            <img src={leftArrow} alt="Previous" className={classes.arrowImage} />
                        </button>
                        <button className={`${classes.arrowButton} ${classes.rightArrow}`} onClick={handleNext}>
                            <img src={rightArrow} alt="Next" className={classes.arrowImage} />
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    fullscreenContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto'
    },
    galleryContainer: {
        width: '85vw',
        height: '90vh',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    slideContainer: {
        width: '100%',
        height: '100%',
    },
    textSlide: {
        margin: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    arrowButton: {
        position: 'absolute',
        top: '50%',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    leftArrow: {
        left: '-40px',
    },
    rightArrow: {
        right: '-40px',
    },
    arrowImage: {
        width: '60px',
        height: '60px',
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: -50,
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:active': {
            transform: 'scale(0.9)'
        },
        '&:hover': {
            opacity: 0.7
        }
    },
    closeIcon: {
        width: 40,
        height: 40
    },
    text: {
        fontSize: 22,
        fontWeight: 500,
        fontFamily: 'RedHatText',
    }
});

export default FullScreenTextGallery;
