import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AnimatePresence, motion } from 'framer-motion';
import ExhibitionContainer from '../Design/ExhibitionContainer';
import useWindowSize from '../Design/useWindowSize'

import Quote from '../Design/Quote';
import Text from '../Design/Text';
import Title from '../Design/Title';
import TextGallery from '../Design/TextGallery';
import FullScreenGallery from '../Design/FullScreenGallery';
import LettersGallery from '../Design/Letters/LettersGallery';
import Gallery from '../Design/Gallery';
import Letters from '../Design/Letters/Letters';
import DocumentGallery from '../Design/DocumentGallery';
import LinkGallery from '../Design/LinkGallery';
import FullScreenTextGallery from '../Design/FullScreenTextGallery';
import Creators from '../Design/Creators';

const DirectionEnum = {
    Left: 0,
    Right: 1
};

function Section({ onPreviousSectionClick, onNextSectionClick, content, initialIndex }) {
    const { width, height } = useWindowSize();
    const classes = useStyles({ width, height });

    const [animationDirection, setAnimationDirection] = useState(DirectionEnum.Right);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [containerHeight, setContainerHeight] = useState('100%');

    const [currentItem, setCurrentItem] = useState(content[initialIndex]);

    const [currentFullScreenIndex, setCurrentFullScreenIndex] = useState(0);
    const [fullScreenOpen, setFullScreenOpen] = useState(false);

    const [fullScreenLetterOpen, setFullscreenLetterOpen] = useState(false);
    const [fullScreenLinkOpen, setFullScreenLinkOpen] = useState(false);
    const [fullScreenAutoPlay, setFullscreenAutoPlay] = useState(false);

    useEffect(() => {
        setCurrentItem(content[initialIndex]);
    }, [initialIndex, content]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const calculateHeight = () => {
            const safeAreaInsetBottom = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('safe-area-inset-bottom'), 10) || 0;
            const newHeight = window.innerHeight - safeAreaInsetBottom;
            setContainerHeight(`${newHeight}px`);
        };

        window.addEventListener('resize', calculateHeight);
        calculateHeight();

        return () => {
            window.removeEventListener('resize', calculateHeight);
        };
    }, []);

    const onFullScreenClick = (index) => {
        setCurrentFullScreenIndex(index);
        setFullScreenOpen(true);
    }

    const onLetterFullScreenClick = (index, autoPlay = false) => {
        setFullscreenAutoPlay(autoPlay);
        setCurrentFullScreenIndex(index);
        setFullscreenLetterOpen(true);
    }
    const onFullScreenClose = () => {
        setCurrentFullScreenIndex(0)
        setFullScreenOpen(false)
        setFullscreenLetterOpen(false)
        setFullScreenLinkOpen(false)
    }

    const onLinkClick = () => {
        setFullScreenLinkOpen(true)
    }

    const handleLeftClick = () => {
        setFullscreenAutoPlay(false);
        let newIndex = currentItem.index - 1;
        if (newIndex < 0) {
            onPreviousSectionClick()
            return
        }

        const newCurrentItem = content[newIndex]
        setAnimationDirection(DirectionEnum.Left)

        setTimeout(() => {
            setCurrentItem(newCurrentItem);
        }, 10);
    };

    const handleRightClick = () => {
        setFullscreenAutoPlay(false);
        const newIndex = currentItem.index + 1
        if (newIndex + 1 > content.length) {
            onNextSectionClick();
            return
        }
        const newCurrentItem = content[newIndex]
        setAnimationDirection(DirectionEnum.Right)

        setTimeout(() => {
            setCurrentItem(newCurrentItem);
        }, 100);
    };

    const getVariants = () => ({
        initial: { x: animationDirection === DirectionEnum.Left ? -screenWidth : screenWidth, opacity: 0 },
        center: { zIndex: 1, x: 0, opacity: 1 },
        exit: { zIndex: 0, x: animationDirection === DirectionEnum.Left ? screenWidth : -screenWidth, opacity: 0 },
    });

    const renderItem = (item) => {
        switch (item.type) {
            case 'quote':
                return <Quote quotes={item.quotes} />;
            case 'gallery':
                return <Gallery
                    item={item}
                    onFullScreenClick={onFullScreenClick}
                    onLinkClick={onLinkClick}
                />;
            case 'text':
                return <Text item={item} />;
            case 'title':
                return <Title text={item.text} subText={item.subText} />;
            case 'textgallery':
                return <TextGallery
                    item={item}
                    onFullScreenClick={onFullScreenClick}
                />;
            case 'letter':
                return <Letters
                    item={item}
                    onFullScreenClick={onLetterFullScreenClick}
                />
            case 'documentgallery':
                return <DocumentGallery
                    item={item}
                    onFullScreenClick={onLetterFullScreenClick}
                />
            case 'linkgallery':
                return <LinkGallery
                    item={item}
                    onFullScreenClick={onFullScreenClick}
                />
            case 'creators':
                return <Creators item={item} />

            default:
                return null;
        }
    };

    return (
        <div className={classes.section} style={{ height: containerHeight }}>
            <ExhibitionContainer
                onLeftClick={handleLeftClick}
                onRightClick={handleRightClick}
            >
                <AnimatePresence mode="popLayout" initial={false}>
                    <motion.div
                        key={currentItem.index}
                        custom={currentItem.index}
                        variants={getVariants()}
                        initial="initial"
                        animate="center"
                        exit="exit"
                        transition={{
                            duration: 0.2
                        }}
                    >
                        <div className={classes.animationContainer}>
                            {renderItem(currentItem)}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </ExhibitionContainer>
            {fullScreenOpen ?
                <div className={classes.fullscreenOverlay}>
                    <FullScreenGallery
                        item={currentItem}
                        onClose={() => onFullScreenClose()}
                        activeIndex={currentFullScreenIndex}
                    />
                </div> : null}
            {fullScreenLetterOpen ?
                <div className={classes.fullscreenOverlay}>
                    <LettersGallery
                        item={currentItem}
                        onClose={() => onFullScreenClose()}
                        activeIndex={currentFullScreenIndex}
                        autoPlay={fullScreenAutoPlay}
                    />
                </div> : null}
            {fullScreenLinkOpen ?
                <div className={classes.fullscreenOverlay}>
                    <FullScreenTextGallery
                        item={currentItem}
                        onClose={() => onFullScreenClose()}
                        activeIndex={currentFullScreenIndex}
                    />
                </div> : null}
            <div className={classes.vignette}></div>
        </div>
    );
}

const useStyles = makeStyles({
    section: {
        backgroundColor: 'transparent',
        color: 'white',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    fullscreenOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 2000,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    vignette: {
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle, transparent, rgba(0, 0, 0, 0.6) 100%)',
            pointerEvents: 'none',
            zIndex: 1
        },
    },
    fullscreenContainer: ({ width, height }) => ({
        width: width,
        height: height
    })
});

export default Section;
