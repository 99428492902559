import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import backgroundVideo from '../Content/video/video.mp4';
import Config from '../Config';

import BlurredButton from '../Design/BlurredButton';
import Colors from '../Design/Colors';

function Home({ onStartClick }) {
    const classes = useStyles();

    return (
        <div className={classes.homePage}>
            <video autoPlay loop muted className={classes.backgroundVideo}>
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className={classes.videoOverlay} />
            <div className={classes.centeredText}>
                <p className={classes.companyName}>{Config.HOME_COMPANY_NAME}</p>
                <div className={classes.separator}></div>
                <p className={classes.title}>{Config.HOME_EXHIBITION_NAME}</p>
                <p className={classes.subTitle}>{Config.HOME_EXHIBITION_SUBTITLE}</p>
                <BlurredButton title={Config.HOME_START_BUTTON_TITLE} onClick={onStartClick} />
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    companyName: {
        color: Colors.textColor,
        fontSize: 30,
        fontFamily: 'RedHatText',
        fontWeight: 700,
        margin: 20
    },
    title: {
        color: Colors.textColor,
        fontSize: 50,
        fontFamily: 'RedHatText',
        fontWeight: 700,
        marginTop: 20,
        marginBottom: 0
    },
    subTitle: {
        marginTop: 0,
        color: Colors.textColor,
        fontSize: 25,
        fontFamily: 'RedHatText',
        fontWeight: 700,
        marginBottom: 50
    },
    separator: {
        width: '20%',
        height: '1px',
        backgroundColor: Colors.homeSeparatorColor,
        margin: 'auto',
        display: 'block',
    },
    homePage: {
        position: 'relative',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    backgroundVideo: {
        position: 'absolute',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        top: 0,
        left: 0,
    },
    centeredText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        textAlign: 'center',
        zIndex: 2,
    },
    videoOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1
    },
});

export default Home;
