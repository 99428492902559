import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from './Colors';
import Utilities from './Utilities';

const useStyles = makeStyles({
    textContainer: {
        color: Colors.textColor,
        fontSize: 22,
        fontWeight: 500,
        fontFamily: 'RedHatText',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-line'
    },
    authorText: {
        alignSelf: 'flex-end',
        marginTop: 10,
        fontFamily: 'RedHatText',
        fontSize: 18,
        fontWeight: 500,
        color: Colors.textColor,
    },
    imageStyle: {
        cursor: 'pointer',
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '60vh',
        marginBottom: 20,
    },
    location: {
        marginTop: 10,
        alignSelf: 'flex-start',
    },
    date: {
        alignSelf: 'flex-start',
    },
    description: {
        marginTop: 20
    }
});

const TextGallery = ({ item, onFullScreenClick }) => {
    const classes = useStyles();

    const getImagePath = () => {

        try {
            return require(`../${item.path}${item.image}`);
        } catch (e) {
            console.error("Error loading image:", e);
            return "";
        }
    };

    return (
        <div className={classes.textContainer}>
            <img
                src={getImagePath()}
                alt="Gallery"
                className={classes.imageStyle}
                onClick={onFullScreenClick}
            />
            <div>{Utilities.processQuotedText(item.text)}</div>
            <div className={classes.description}>{item.description}</div>
            <div className={classes.location}>{item.location}</div>
            <div className={classes.date}>{item.date}</div>
            <div className={classes.authorText}>
                {item.source}
            </div>
        </div>
    );
};

export default TextGallery;
